import React, {useEffect, useState} from 'react'
import Select from '@mui/material/Select'
import styles from '../stylesheets/GameDropdown.module.css'
import {FormControl, InputLabel, MenuItem} from "@mui/material"
import {fetchFeedTypes} from "../actions/feeds"
import {FormHelperText} from "@material-ui/core"

export const feedTypeToOption = feedType => ({
    value: feedType,
    label: feedType?.name
})
export default function FeedTypeDropDown({feedType, onChange, sportId, error}) {
    const [selectedFeedType, setSelectedFeedType] = useState(null)
    const [feedTypes, setFeedTypes] = useState([])
    useEffect(() => {
        if (!sportId) {
            setFeedTypes([])
            return
        }
        const fetchOptions = async () => {
            const feedTypesReq = await fetchFeedTypes(sportId)
            setFeedTypes(feedTypesReq?.map(feedTypeToOption))
        }
        fetchOptions()
    }, [sportId])

    useEffect(() => {
        if (feedType) {
            setSelectedFeedType(feedTypes.find(o => o.value.code === feedType.code && o.value.sportId === feedType.sportId))
        } else {
            const selected = feedTypes[0] || null
            setSelectedFeedType(selected)
            if (selected !== null)
                onChange({ target: selected })
        }
    }, [feedType, feedTypes, onChange])

    return (
        <div className={styles.root}>
            <FormControl variant="standard" fullWidth error={error}>
                <InputLabel id="feedType-dropdown">Feed Type</InputLabel>
                <Select
                    id="feedType"
                    labelId="feedType-label"
                    value={selectedFeedType ? selectedFeedType?.value : ''}
                    label="FeedType"
                    onChange={onChange}
                    variant="standard"
                >
                    {feedTypes && feedTypes.map(option => <MenuItem key={option.value.sportId + option.value.code}
                                                                    value={option?.value}>{option.label}</MenuItem>)}
                    {(feedTypes == null || feedTypes.length === 0) && <MenuItem
                        value=''
                    >No Available Feed Types</MenuItem>}
                </Select>
                {error && <FormHelperText><span style={{float: "right", color: "red"}}>Required</span></FormHelperText>}
            </FormControl>
        </div>
    )
}

import React from 'react'
import LabeledInput from './LabeledInput'
import styles from '../../stylesheets/inputs/EditableInput.module.css'

const EditableInput = ({
    key, name, label, description, placeholder, defaultValue, pattern,
    invalid, minLength, maxLength, required, disabled, onChange
  }) => {
  const inputClass = disabled
    ? styles.inputDisabled
    : invalid
      ? `${styles.invalid} ${styles.input}`
      : styles.input

  return (
    <LabeledInput
      key={key}
      name={name}
      label={label}
      description={description}
      placeholder={placeholder}
      defaultValue={defaultValue}
      pattern={pattern}
      minLength={minLength}
      maxLength={maxLength}
      altText={maxLength && `${maxLength} character limit`}
      required={required}
      disabled={disabled}
      labelClass={styles.label}
      descriptionClass={styles.description}
      altTextClass={styles.altText}
      inputClass={inputClass}
      requiredClass={styles.required}
      onChange={onChange}
    />
  )
}

export default EditableInput

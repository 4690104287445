import React from 'react'
import Slider from '@material-ui/core/Slider'
import { makeStyles } from '@material-ui/core/styles'

export const LOWEST_GAIN_LEVEL = -60
export const HIGHEST_GAIN_LEVEL = 60

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: theme.label,
  gainControl: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '15px'
  },
  sliderRoot: {
    width: '95%',
  },
}))

const marks = [
  {
    value: LOWEST_GAIN_LEVEL,
    label: LOWEST_GAIN_LEVEL,
  },
  {
    value: 0,
    label: '0',
  },
  {
    value: HIGHEST_GAIN_LEVEL,
    label: HIGHEST_GAIN_LEVEL,
  },
];

export default function AudioPanel({disabled, value, handleChange, handleCommittedChange}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div id="gain-slider" className={classes.label}>Gain</div>
      <div className={classes.gainControl}>
        <Slider
          value={value}
          aria-labelledby="gain-slider"
          className={classes.sliderRoot}
          color='secondary'
          onChange={handleChange}
          onChangeCommitted={handleCommittedChange}
          step={1}
          valueLabelDisplay="auto"
          min={LOWEST_GAIN_LEVEL}
          max={HIGHEST_GAIN_LEVEL}
          marks={marks}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

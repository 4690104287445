import React from 'react'
import './App.css'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import {ThemeProvider} from '@material-ui/core/styles'
import CreateEventForm from './components/CreateEventForm'
import Events from './components/Events'
import EventDetail from './components/EventDetail'
import DataProvider from './components/DataProvider'
import {Helmet} from 'react-helmet'
import {LoginCallback, Security} from '@okta/okta-react'
import {authService} from './actions/auth'
import theme from './styles'
import CreateGameForm from "./components/CreateGameForm"
import AssetDetail from "./components/AssetDetail/AssetDetail"
import useCookies from "./actions/cookies"
import Pipelines from './components/Pipelines/Pipelines'
import { CerebroRoute } from './components/commons/routing/CerebroRoute'

const App = () => {
    useCookies()

    const title = window.env.name === 'prod'
        ? 'Cerebro'
        : `${window.env.name.toUpperCase()} Cerebro`

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <Router>
                    <Security authService={authService}>
                        <Switch>
                            <CerebroRoute path="/" exact component={Events}/>
                            <CerebroRoute path={["/create", "/create/:eventId"]} exact component={CreateEventForm}/>
                            <CerebroRoute path={["/create-game"]} exact component={CreateGameForm}/>
                            <CerebroRoute path="/event-detail/:eventId" component={EventDetail}/>
                            <CerebroRoute path="/asset-detail/:gamePk" component={AssetDetail}/>
                            <CerebroRoute path="/pipelines" component={Pipelines}/>
                            <Route path="/implicit/callback" component={LoginCallback}/>
                        </Switch>
                        <DataProvider/>
                    </Security>
                </Router>
            </div>
        </ThemeProvider>
    )
}

export default App

import React from "react"
import HlsPlayer from "../HlsPlayer"
import styles from "../../stylesheets/AssetDetail.module.css"

export function VideoPlayer({
	started,
	playing,
	url,
	retryLimit = 45,
	label,
	reference,
	accessToken,
	handleOnStart,
	forceLive,
}) {

	return (
		<div>
			<div className={styles.playerContainer}>
				{started && url ? (
					<HlsPlayer
						url={url}
						playing={playing}
						retryLimit={retryLimit}
						reference={reference}
						accessToken={accessToken}
						handleOnStart={handleOnStart}
						forceLive={forceLive}
					/>
				) : (
					"Streaming has not started."
				)}
			</div>
			<div className={styles.feedLabel}>
				<label>{label}</label>
			</div>
		</div>
	)
}

import { KeyboardArrowDown, KeyboardArrowUp, Launch } from "@material-ui/icons"
import { Chip } from "@mui/material"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import { Link, useRouteMatch } from "react-router-dom"
import { deriveProcessorUri, processorTypeToLabel, gameStatusToMuiColor } from "../utils"

export default function PipelinesTable({ pipelines }) {
	pipelines.sort((a, b) => a.id - b.id)

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell width={"0.5rem"} />
						<TableCell>ID</TableCell>
						<TableCell>Name</TableCell>
						<TableCell align="center">Current Status</TableCell>
						<TableCell align="center">Computed Status</TableCell>
						<TableCell align="right">Last Used</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{pipelines.map(pipeline => (
						<PipelineRow key={pipeline.id} pipeline={pipeline} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

function PipelineRow(props) {
	const { pipeline } = props
	const [open, setOpen] = useState(false)
	const { path } = useRouteMatch()
	const computedStatusColor = gameStatusToMuiColor(pipeline?.computedStatus)

	const processors = pipeline.processors.sort((a, b) => a.id - b.id)

	return (
		<>
			<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
				<TableCell>
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					{pipeline.id}
				</TableCell>
				<TableCell>
					<Link to={`${path}/${pipeline.id}`}>{pipeline.name}</Link>
				</TableCell>
				<TableCell align="center">
					<Chip label={pipeline.status} color={pipeline.status === "ACTIVE" ? "success" : "default"} />
				</TableCell>
				<TableCell align="center">
					<Chip label={pipeline.computedStatus} color={`${computedStatusColor}`} />
				</TableCell>
				<TableCell align="right">{pipeline?.lastUsedTime || "Never"}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Typography variant="h6" gutterBottom component="div">
								Processors for {pipeline.name}
							</Typography>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>ID</TableCell>
										<TableCell>Processor</TableCell>
										<TableCell align="center">Current Status</TableCell>
										<TableCell align="right">Last Updated</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{processors?.map(processor => (
										<ProcessorRow key={processor.id} processor={processor} />
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	)
}

function ProcessorRow(props) {
	const { processor } = props
	const statusColor = gameStatusToMuiColor(processor?.status)
	const uri = deriveProcessorUri(processor.resourceId, processor.type)

	return (
		<TableRow key={processor.id}>
			<TableCell component="th" scope="row">
				{processor.id}
			</TableCell>
			<TableCell>
				<a href={uri} target="_blank" rel="noreferrer">
					{processorTypeToLabel(processor.type, processor?.team?.abbreviation)}
					{uri && <Launch fontSize="small" style={{ verticalAlign: "middle", marginLeft: "0.125rem" }} />}
				</a>
			</TableCell>
			<TableCell align="center">
				<Chip label={processor.status} color={statusColor} />
			</TableCell>
			<TableCell align="right">{processor.modifiedDateTime}</TableCell>
		</TableRow>
	)
}

/*
 * action types
 */

import {ALERT_TIMEOUT, ALERT_TYPE} from '../utils'
import settings from '../settings'
import {withAuthHeader} from './auth'
import {CerebroError} from '../errors'
import hash from 'object-hash'

export const SELECTED_PROFILE_ID = 'SELECTED_PROFILE_ID'

/*
    Status Filter
 */
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER'

export const setStatusFilter = statusFilter => {
    return {
        type: SET_STATUS_FILTER,
        statusFilter
    }
}

/*
    Ad-hoc Profiles
*/

export const FETCH_ADHOC_PROFILES = 'FETCH_ADHOC_PROFILES'
export const fetchedProfiles = profiles => (
    {
        type: FETCH_ADHOC_PROFILES,
        profiles
    }
)

export const fetchAdhocProfiles = () => async dispatch => {
    console.log(`Fetching ad-hoc profiles from backend.`)

    try {
        const response = await fetch(settings.API_ROOT + '/api/v1/profiles?type=adhoc', await withAuthHeader())
        const profiles = await response.json()
        const sorted = profiles.sort((a, b) => {
            const nameA = a.name.toUpperCase()
            const nameB = b.name.toUpperCase()

            if (nameA > nameB)
                return 1
            else if (nameA === nameB)
                return 0
            else return -1
        })

        dispatch(fetchedProfiles(sorted))
    } catch (err) {
        const errorMsg = `There's a problem fetching the ad-hoc profiles from the backend.`
        console.log(`${errorMsg}: ${err}`)
        addErrorAlertWithAutoRemoval(errorMsg)(dispatch)
    }
}

export const setSelectedProfileId = selectedProfileId => {
  return {type: SELECTED_PROFILE_ID, selectedProfileId}
}

/*
  Common error alert action creator
 */

export const ADD_ALERT = 'ADD_ALERT'

export const addAlert = (id, alertType, message) => (
    {
        type: ADD_ALERT,
        id,
        alertType,
        message
    }
)

const addAlertWithAutoRemoval = (type, message) => dispatch => {
  const alertId = hash(message)
  dispatch(addAlert(alertId, type, message))
  autoRemoveAlert(alertId, dispatch)
}

export const addErrorAlertWithAutoRemoval = message => dispatch => addAlertWithAutoRemoval(ALERT_TYPE.ERROR, message)(dispatch)
export const addInfoAlertWithAutoRemoval = message => dispatch => addAlertWithAutoRemoval(ALERT_TYPE.INFO, message)(dispatch)
export const addWarnAlertWithAutoRemoval = message => dispatch => addAlertWithAutoRemoval(ALERT_TYPE.WARN, message)(dispatch)

export const REMOVE_ALERT = 'REMOVE_ALERT'

export const removeAlert = id => (
    {
        type: REMOVE_ALERT,
        id
    }
)

export const autoRemoveAlert = (id, dispatch) => {
    setTimeout(() => dispatch(removeAlert(id)), ALERT_TIMEOUT)
}

export const uploadImage = image => async dispatch => {
  const imgPayload = new FormData()
  imgPayload.append('file', image)

  try {
    const response = await fetch(settings.API_ROOT + '/api/v1/images', await withAuthHeader({
      method: 'POST',
      body: imgPayload
    }))

    if (!response.ok) {
      throw new Error(`Image upload failed: status ${response.status}`)
    }

    const json = await response.json()
    return json.url
  } catch (err) {
    console.error(`Failed to upload image ${err}`)
    throw new CerebroError("There was a problem uploading image. Please try again.")
  }
}

export const SET_EVENT_STATUSES = 'SET_EVENT_STATUSES'

export const setEventStatusesAction = eventStatuses => ({
  type: SET_EVENT_STATUSES,
  eventStatuses
})

export const fetchEventStatuses = () => async dispatch => {
  console.log(`Fetching event statuses from backend.`)

  try {
    const response = await fetch(settings.API_ROOT + '/api/v1/events/statuses', await withAuthHeader())
    const statuses = await response.json()
    dispatch(setEventStatusesAction(statuses))
  } catch (err) {
    const errorMsg = `There's a problem fetching the event statuses from the backend.`
    console.log(`${errorMsg}: ${err}`)
    addErrorAlertWithAutoRemoval(errorMsg)(dispatch)
  }
}

export const SET_START_TIME = `SET_START_TIME`

export const setStartTimeAction = startTime => ({
  type: SET_START_TIME,
  startTime
})

export const setStartTime = startTime => dispatch => {
  dispatch({
    type: SET_START_TIME,
    startTime
  })
}

export const SET_END_TIME = `SET_END_TIME`

export const setEndTimeAction = endTime => ({
  type: SET_END_TIME,
  endTime
})

export const setEndTime = endTime => dispatch => {
  dispatch({
    type: SET_END_TIME,
    endTime
  })
}

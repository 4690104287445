import React from 'react'
import Popup from 'reactjs-popup'
import styles from '../stylesheets/CerebroPopup.module.css'

const CerebroPopup = ({trigger, title, body, img,
  handleContinue, continueStyle, continueContent,
  closeOnDocumentClick, onClose}) => {
  return (
    <Popup trigger={trigger} modal onClose={onClose}
      closeOnDocumentClick={closeOnDocumentClick ? closeOnDocumentClick : true}>
      {close => (
        <div className={styles.popupContainer}>
          <h1 className={styles.popupTitle} align="left">{title}</h1>
          <p className={styles.popupBody} align="left">{body}</p>
          {img && <img src={img} alt="popup" className={styles.popupImg} align="center" />}
          <div className={styles.popupActions}>
            <div className={styles.popupBtnContainer}>
              <button
                onClick={close}
                className={styles.popupCancelBtn} >
                Cancel
              </button>
            </div>
            <div className={styles.popupBtnContainer}>
              <button
                onClick={() => {
                  handleContinue && handleContinue()
                  close()
                }}
                className={continueStyle ? continueStyle : styles.popupContinueBtn} >
                {continueContent ? continueContent : "Continue"}
              </button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  )
}

export default CerebroPopup

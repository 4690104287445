import { Box, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { fetchPipelines } from "../../../actions/pipelines"
import PipelinesTable from "./PipelinesTable"
import {fetchTeam} from "../../../actions/stats"

export const fetchHydratedPipelines = async () => {
	const resp = await fetchPipelines()
	return await Promise.all(resp.map(async res => {
		const processors = await Promise.all(res.processors.map(async pr => {
			const team = pr.metadata.teamId !== undefined ? await fetchTeam(pr.metadata.teamId) : null
			return {...pr, team}
		}))
		return {...res, processors}
	}))
}

export default function AllPipelines() {
	const [pipelines, setPipelines] = useState([])

	const updatePipelines = useCallback(async () => {
		const resp = await fetchHydratedPipelines()
		setPipelines(resp)
	}, [])

	useEffect(() => {
		updatePipelines()

		const interval = setInterval(updatePipelines, 5000)

		return () => {
		    clearInterval(interval)
		}
	}, [updatePipelines])

	return (
		<div>
			<Box display="flex" justifyContent="center" alignItems="center">
				<Typography fontSize="large" color="text.primary">
					All Pipelines
				</Typography>
			</Box>
			<br />

			{pipelines && <PipelinesTable pipelines={pipelines} />}
		</div>
	)
}

import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3F3F3F',
    },
    secondary: {
      main: '#041E42',
    }
  },
  label: {
    position: 'relative',
    textAlign: 'left',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#3F3F3F',
  },
})

export default theme

import React, {useEffect, useState} from 'react'
import styles from '../stylesheets/NextGenGameDropdown.module.css'
import {Autocomplete, MenuItem, TextField} from "@mui/material"
import {fetchGamesByDate} from "../actions/stats"
import {FormHelperText} from "@material-ui/core"

const DEFAULT_TEAM_LOGO_ID = "000"

const getTeamLogo = teamId => {
    const team = teamId ? teamId : DEFAULT_TEAM_LOGO_ID
    return `https://www.mlbstatic.com/team-logos/${team}.svg`
}


const TeamIconImg = ({src, alt = "", ...props}) => {
    return <img referrerPolicy="no-referrer" src={src} alt={""} onError={() => getTeamLogo(DEFAULT_TEAM_LOGO_ID)} {...props} />
}
const isTbd = (game) => {
    return game?.status?.startTimeTBD
}

// compare by: (1) sport id -> (2) game date (TBDs last) -> (3) game pk
const compareGames = (a, b) => {
    if (a.sport.id === b.sport.id) {
        const isATbd = isTbd(a.game);
        const isBTbd = isTbd(b.game);
        if (isATbd !== isBTbd) {
            return isATbd ? 1 : -1
        } else if (a.game.gameDate === b.game.gameDate)
            return a.value > b.value ? 1 : -1
        return a.game.gameDate > b.game.gameDate ? 1 : -1
    } else {
        return a.sport.id > b.sport.id ? 1 : -1
    }
}

const formatOptionLabel = (props, option) => {
    const date = new Date(option?.game?.gameDate)
    const formattedDate =
        isTbd(option?.game) ? "TBD" :
            new Intl.DateTimeFormat([], {
                hour: 'numeric', minute: 'numeric',
                timeZone: 'America/New_York',
                timeZoneName: 'short'
            })
                .format(date)

    const homeTeam = option?.game?.teams?.home?.team
    const awayTeam = option?.game?.teams?.away?.team
    return (
        <MenuItem  {...props} key={`${option?.value}`} className={styles.optionContainer}>
            <span style={{fontSize: '12px', justifyContent: 'flex-start'}}>({option.value})</span>
            <div className={styles.teamsContainer} style={{justifyContent: 'flex-end'}}>
                <span>{awayTeam?.shortName}</span>
                <TeamIconImg src={getTeamLogo(awayTeam?.id)}
                             alt="away team logo"
                             className={styles.logoIcon}
                             style={{margin: 'auto 0 auto 0.5em'}}
                />
            </div>
            <span className={styles.atSymbol}>@</span>
            <div className={styles.teamsContainer}>
                <TeamIconImg src={getTeamLogo(homeTeam?.id)}
                             alt="home team logo"
                             className={styles.logoIcon}
                             style={{margin: 'auto 0.5em auto 0'}}
                />
                <span> {homeTeam?.shortName}</span>
            </div>
            <div className={styles.gameDate}>{formattedDate}</div>
        </MenuItem>
    )
}
export const gameToOption = gameOption => ({
    value: gameOption?.gamePk,
    label: `${gameOption?.teams?.away?.team?.name} @ ${gameOption?.teams?.home?.team?.name} (${gameOption?.gamePk})`,
    game: gameOption,
    sport: gameOption?.teams?.away?.team?.sport? gameOption?.teams?.away?.team?.sport:gameOption?.teams?.home?.team?.sport
})

function NextGenGameDropdown({gamePk, startDate, sportIds, onChange, disabled, error}) {
    const [games, setGames] = useState([])
    const [selectedGame, setSelectedGame] = useState('')

    // If our date changes, reset the options with the games from the new date
    useEffect(() => {
        let isSubscribed = true
        if (sportIds && sportIds.length > 0 && startDate) {
            const fetchOptions = async () => {
                const fetchedGames = await fetchGamesByDate(startDate, sportIds)
                if (isSubscribed) {
                    setGames(fetchedGames?.map(gameToOption).map(game=>
                        game.sport.id === 17 ?
                        {...game, sport:{...game.sport, name: "Liga de Beisbol Dominicano"}}:game))
                }
            }
            fetchOptions()
        }
        return () => {
            isSubscribed = false
        }
    }, [sportIds, startDate])

    useEffect(() => {
        const selected = games.find(o => o.game.gamePk === gamePk)
        setSelectedGame(selected ? selected : null)
    }, [games, gamePk, sportIds])

    return (
        <div>
            <Autocomplete
                options={games?.sort((a, b) => compareGames(a, b))}
                id="game"
                value={selectedGame}
                onChange={onChange}
                disabled={disabled}
                groupBy={(option) => option.sport.name}
                variant="standard"
                renderOption={(props, option) => formatOptionLabel(props, option)}
                renderInput={(params) => <TextField {...params}
                                                    error={error}
                                                    variant="standard"
                                                    fullWidth
                                                    label="Game"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: {
                                                            paddingBottom: 12
                                                        }
                                                    }}
                />}
                isOptionEqualToValue={(option, value) => option.value === value?.value
                }
                noOptionsText={"No Available Games"}
            />
            {error && <FormHelperText><span style={{float: "right", color: "red"}}>Required</span></FormHelperText>}
        </div>


    )
}

export default React.memo(NextGenGameDropdown, (oldProps, newProps) =>
    oldProps.gamePk === newProps.gamePk
    && oldProps.startDate === newProps.startDate
    && oldProps.disabled === newProps.disabled
    && oldProps.error === newProps.error
    && oldProps.sportIds.sort().toString() === newProps.sportIds.sort().toString()
)
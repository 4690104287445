import { FormGroup, TextField } from "@material-ui/core"
import { TablePagination } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Cookies from "universal-cookie"
import { fetchGamesAndPipelines } from "../../actions/games"
import styles from "../../stylesheets/Events.module.css"
import { gameStatusOrder, GAME_STATUS } from "../../utils"
import LeagueDropdown from "../LeagueDropdown"
import GameCard from "./GameCard"
import settings from "../../settings";

const cookie = new Cookies()
const milbSupportedSportIds = settings.SUPPORTED_SPORT_IDS.filter(l => l.type === 'milb').map(league => league.id)
console.log(`milb sportIds: ${milbSupportedSportIds}`)
const isDateMatch = (gameDate, startDate, endDate) => {
    return (!startDate || startDate.isSameOrBefore(new Date(gameDate), 'day'))
        && (!endDate || endDate.isSameOrAfter(new Date(gameDate), 'day'))
}

const isStatusMatch = (status, statusFilter) => {
    return !statusFilter
        || statusFilter === GAME_STATUS.ALL
        || status === statusFilter
}

const selectGames = (state, startDate, endDate, sportIds, query) => {
    return findSearchMatches(query, state.games.filter(game =>
        isDateMatch(game.gameDate, startDate, endDate)
        && sportIds.includes(game?.pipelines[0]?.feedType?.sportId)
    )).sort(({gameDate: s1, gameStatus: status1}, {gameDate: s2, gameStatus: status2}) => {
        if (status1 === status2) {
            return new Date(s1).getTime() - new Date(s2).getTime()
        } else {
            return gameStatusOrder.indexOf(status1) - gameStatusOrder.indexOf(status2)
        }
    })
}

const findSearchMatches = (query, games) => {
    if (!query || query.length < 3) return games
    return games.filter(game => isSearchMatch(query.toLowerCase(), game))
}

const isSearchMatch = (query, game) => {
    return game.gameStatus.toLowerCase().includes(query) ||
        game.gamePk.toString().includes(query) ||
        game.teams.away.team.name.toLowerCase().includes(query) || game.teams.home.team.name.toLowerCase().includes(query) ||
        game.pipelines?.filter(pipeline => pipeline.pipeline.name.toLowerCase().includes(query)).length > 0
}

export default function GameCards({startDate, endDate, statusFilter}) {
    const dispatch = useDispatch()
    const leagues = useSelector(state => state.leagues)
    const [sportIds, setSportIds] = useState(cookie.get("sportIds")?.filter(l =>
        milbSupportedSportIds.includes(l.id)) || leagues.filter(l => milbSupportedSportIds.includes(l.id)).map(l => l.id))
    const [query, setQuery] = useState("")

    const games = useSelector(state => selectGames(state, startDate, endDate, sportIds, query),
        (before, after) => before.length === after.length)
        .filter(game => isStatusMatch(game.gameStatus, statusFilter))

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(parseInt(cookie.get("rowsPerPage"), 10) || 10)

    useEffect(() => {
        if (!startDate || !endDate) {
            // Only fire off a request if there is a complete range
            return
        }

        dispatch(fetchGamesAndPipelines(startDate, endDate, [sportIds]))
    }, [dispatch, startDate, endDate, sportIds])

    useEffect(() => {
        setPage(0)
    }, [statusFilter, sportIds])

    useEffect(() => {
        setSportIds(cookie.get("sportIds") || leagues.map(l => l.id))
    }, [leagues])

    const handleLeagueChange = (e) => {
        const checked = e.target.value
        let newSportIds
        if (checked.includes('Select All')) {
            newSportIds = milbSupportedSportIds
        } else if (checked.includes('Deselect All')) {
            newSportIds = []
        } else {
            newSportIds = checked
        }
        cookie.set("sportIds", newSportIds)
        setSportIds(newSportIds)
    }

    const handleChangePage = (_, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(newRowsPerPage)
        cookie.set("rowsPerPage", newRowsPerPage)
        setPage(0)
    }

    const gamePagination = rowsPerPage < 0 ? games : games
        .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)

    const gameCards = gamePagination.map(game => <GameCard key={game.gamePk + ":" + game.gameDate} game={game}/>)

    return (
		<>
			<FormGroup row style={{ justifyContent: "center", marginTop: "20px" }}>
				<FormGroup style={{ width: "12.5%", margin: "0 2.5rem" }}>
					<TextField
						value={query}
						onChange={e => setQuery(e.target.value)}
						variant="standard"
						label="Search"
					/>
				</FormGroup>
				<FormGroup style={{ width: "12.5%", margin: "0 2.5rem" }}>
					<LeagueDropdown sportIdsSelected={sportIds} supportedSportIds={milbSupportedSportIds} onChange={handleLeagueChange} style={{ width: "50%" }} />
				</FormGroup>
			</FormGroup>
			<div className={styles.cardContainer}>
				{gameCards?.length > 0 ? (
					<>
						{gameCards}
						<TablePagination
							style={{ float: "right", marginRight: "10%" }}
							component="div"
							page={page}
							rowsPerPageOptions={[5, 10, 25, 50, { value: -1, label: "All" }]}
							count={games.length}
							onPageChange={handleChangePage}
							rowsPerPage={rowsPerPage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</>
				) : (
					<p>{"There are no scheduled games during this date range :("}</p>
				)}
			</div>
		</>
	)
}
import appReducer from './reducers'
import thunk from 'redux-thunk'
import {createStore, applyMiddleware} from 'redux'

const consoleMessages = store => next => action => {

  let result

  console.groupCollapsed(`Dispatching action => ${action.type}`)
  result = next(action)

  let state = store.getState()

  console.log(state)

  console.groupEnd()

  return result

}

const storeFactory = (initialState = {}) => {
  return applyMiddleware(thunk, consoleMessages)(createStore)(appReducer, initialState)
}

export default storeFactory

import {connect} from 'react-redux'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import {setStatusFilter} from '../actions'
import {GAME_STATUS} from '../utils'
import {updateCookie} from "../actions/cookies"

const options = [
  GAME_STATUS.ALL,
  GAME_STATUS.PROVISIONED,
  GAME_STATUS.RUNNING,
  GAME_STATUS.ENDED,
  GAME_STATUS.ERROR
]

const mapStateToProps = state => ({
  options,
  value: state.statusFilter,
  placeholder: "Select an option"
})

const mapDispatchToProps = dispatch => ({
  onChange: e => handleStatusFilterChange(e, dispatch)
})

const handleStatusFilterChange = (e, dispatch) => {
  console.log(e.value)
  updateCookie("status", e.value)
  dispatch(setStatusFilter(e.value))
}

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown)

import settings from '../settings'
import {withAuthHeader} from './auth'

export const FETCH_SLATES = 'FETCH_SLATES'
export const FETCH_YODA_SLATES = 'FETCH_YODA_SLATES'

export const FETCH_SLATE_CATEGORIES = 'FETCH_SLATE_CATEGORIES'

export const CategoryCodes = {
  DEFAULT: 'DEFAULT',
  STARTING: 'STARTING',
  ENDING: 'ENDING'
}

export const fetchSlates = () => async dispatch => {
  console.log("Fetching slates")
  try {
    const response = await fetch(settings.API_ROOT + '/api/v1/slates', await withAuthHeader())
    const slates = await response.json()

    dispatch({
      type: FETCH_SLATES,
      slates: slates
    })
  } catch (err) {
    console.log(`Could not fetch available slates ${err}`)
  }
}

export const fetchSlateCategories = () => async dispatch => {
  try {
    const response = await fetch(settings.API_ROOT + '/api/v1/slates/categories', await withAuthHeader())
    const categories = await response.json()

    dispatch({
      type: FETCH_SLATE_CATEGORIES,
      slateCategories: categories
    })
  } catch (err) {
    console.log(`Could not fetch available slate categories ${err}`)
  }
}

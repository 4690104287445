import {CerebroError} from '../errors'
import settings from '../settings'
import {withAuthHeader} from './auth'
import {addErrorAlertWithAutoRemoval, addWarnAlertWithAutoRemoval} from '../actions'
import {gameEquals, gameStatusOrder, PROVISION_TYPE} from '../utils'
import {fetchGameData, fetchLatestGameData} from './stats'
import moment from "moment/moment";
import {convertToEastCoastDate, dateTimeEquals} from "../utils/date-utils"
import { SCTE_35_CUE_IN } from "../components/AdBreakButtons"

// Convention: NOUN_VERB actions, verbNoun action creators
export const GAME_UPDATE = 'GAME_UPDATE'
export const GAME_REMOVE = 'GAME_REMOVE'

export const GAME_ASSETS_LOAD = 'GAME_ASSETS_LOAD'
export const GAME_ASSET_UPDATE = 'GAME_ASSET_UPDATE'
export const GAME_ASSET_UPDATE_PRIMARY = 'GAME_ASSET_UPDATE_PRIMARY'

export const PROVISION_EVENTS_LOAD = 'PROVISION_EVENTS_LOAD'
export const PROVISION_EVENT_UPDATE = 'PROVISION_EVENT_UPDATE'
export const VENUES_LOAD = 'VENUES_LOAD'
export const LEAGUES_LOAD = 'LEAGUES_LOAD'

export const updateGame = game => ({
    type: GAME_UPDATE,
    game
})

export const removeGame = game => ({
    type: GAME_REMOVE,
    game
})

export const loadGameAssets = gameAssets => ({
    type: GAME_ASSETS_LOAD,
    gameAssets
})

export const updateGameAsset = gameAsset => ({
    type: GAME_ASSET_UPDATE,
    gameAsset
})

export const updatePrimaryPlayback = (gamePk, feedTypeCode, assetTypeCode) => ({
    type: GAME_ASSET_UPDATE_PRIMARY,
    gamePk, feedTypeCode, assetTypeCode
})

export const updateProvisionEvent = provisionEvent => ({
    type: PROVISION_EVENT_UPDATE,
    provisionEvent
})

export const loadVenues = venues => ({
    type: VENUES_LOAD,
    venues
})

export const loadLeagues = leagues => ({
    type: LEAGUES_LOAD,
    leagues
})

export const GameStreamAction = {
    START: 'start',
    STOP: 'stop'
}

const addPipelineToGame = (pipeline, game) => {
    if (!game.pipelines) {
        game.pipelines = []
    }

    game.pipelines = game.pipelines.find(p => p.id === pipeline.id)
        ? game.pipelines.map(p => p.id === pipeline.id ? pipeline : p)
        : [...game.pipelines, pipeline]

    const mostRecentPipeline = game.pipelines.reduce((a, b) => {
        return a.id > b.id ? a : b;
    })

    game.gameStatus = mostRecentPipeline.status

    game.pipelines.sort(({startTime: s1, status: status1}, {startTime: s2, status: status2}) => {
        if (status1 === status2) {
            return new Date(s2).getTime() - new Date(s1).getTime()
        } else {
            return gameStatusOrder.indexOf(status1) - gameStatusOrder.indexOf(status2)
        }
    })
}

const removePipelineFromGame = (pipeline, game) => {
    game.pipelines = game?.pipelines.filter(p => p.id !== pipeline.id)

    if (!game.pipelines) {
        game.pipelines = []
        return
    }

    const mostRecentPipeline = game.pipelines.reduce((a, b) => {
        return a.id > b.id ? a : b;
    })

    game.gameStatus = mostRecentPipeline.status
}

export const updateGamePipelineFromProvisionedEvent = provisionEvent =>
    async dispatch => {
        if (provisionEvent?.request?.type === PROVISION_TYPE.CANCEL) {
            dispatch(removeGamePipeline(provisionEvent?.gameEventPipeline))
        } else {
            dispatch(updateGamePipeline(provisionEvent?.gameEventPipeline))
        }
    }
export const updateGamePipeline = gamePipeline => async (dispatch, getState) => {
    const {gamePk} = gamePipeline
    const gameDate = convertToEastCoastDate(gamePipeline?.gameDateTime).toISOString()

    let game = getState().games?.find(g => gameEquals(g, {gamePk, gameDate}))

    if (!game) {
        game = await fetchGameData(gamePipeline?.gamePk, gamePipeline?.gameDateTime)
    }

    if (game) {
        addPipelineToGame(gamePipeline, game)
        dispatch(updateGame(game))
    }

    return game

}

export const removeGamePipeline = gamePipeline => async (dispatch, getState) => {
    const {gamePk} = gamePipeline
    const gameDate = convertToEastCoastDate(gamePipeline?.gameDateTime).toISOString()

    let game = getState().games?.find(g => gameEquals(g, {gamePk, gameDate}))

    if (game && game.pipelines.length <= 1) {
        dispatch(removeGame(game))
    } else if (game) {
        removePipelineFromGame(gamePipeline, game)
        dispatch(updateGame(game))
    }

}

export const fetchGamesAndPipelines = (startDate, endDate, sportIds) => async dispatch => {
    let gamePipelines
    try {
        gamePipelines = await fetchGamePipelines(startDate, endDate, sportIds)
    } catch (err) {
        const errorMsg = `There was a problem fetching the game pipelines from the server.`
        console.error(`${errorMsg}: ${err}`)
        addErrorAlertWithAutoRemoval(errorMsg)(dispatch)
    }

    for (const gamePipeline of gamePipelines) {
        await dispatch(updateGamePipeline(gamePipeline))
    }
}

// startDate and endDate are moments
const fetchGamePipelines = async (startDate, endDate, sportIds) => {
    console.log(`Fetching games from server ${startDate} - ${endDate} for sports: ${sportIds}.`)
    if (!startDate || !endDate) {
        throw new Error(`startDate and endDate must be provided`)
    }

    const urlString = `${settings.YODA_ROOT}/api/v1/games`
    let url = new URL(urlString)
    const params = {
        startDate: moment(startDate).toISOString(),
        endDate: moment(endDate).toISOString()
    }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    sportIds.forEach(id => url.searchParams.append("sportId", id))

    const response = await fetch(url, await withAuthHeader())
    const json = await response.json()
    if (response.ok) {
        return json
    } else {
        console.error(`Could not fetch game pipelines from backend ${url} : ${json.message}`)
        throw new CerebroError(json.message)
    }
}

export const fetchGamePipeline = (gamePk, feedType, gamePipelineId) => async dispatch => {
    console.log(`Fetching game event ${gamePk} with feed type ${feedType} from server.`)
    let url = new URL(`${settings.YODA_ROOT}/api/v1/games/${gamePk}/feeds?feedTypeCode=${feedType}`)
    try {
        const response = await fetch(url, await withAuthHeader())
        const res = await response.json()

        if (!response.ok) {
            const errorMsg = `There was a problem fetching game pipeline with gamepk ${gamePk} and feedType ${feedType} from the server.`
            throw new CerebroError(errorMsg)
        } else if (res.length === 0) {
            addWarnAlertWithAutoRemoval(`Whoops! This game was not found.`)(dispatch)
        } else {
            const gamePipelines = res ? res : null
            const gamePipeline = findGamePipeline(gamePipelineId, gamePipelines)
            if (gamePipeline) {
                const gep = await dispatch(updateGamePipeline(gamePipeline))
                //If stats data has change we still want to hydrate the page and inform the operator to cancel and reprovision
                if (!gep) {
                    addWarnAlertWithAutoRemoval(`GamePk ${gamePk} not found for date ${new Date(gamePipeline.gameDateTime)?.toLocaleDateString()}! Please Cancel and Reprovision`)(dispatch)
                    const game = await fetchLatestGameData(gamePipeline?.gamePk)
                    addPipelineToGame(gamePipeline, game)
                    dispatch(updateGame(game))
                }
            } else {
                //If we give the wrong pipeline id we should give a not found
                addWarnAlertWithAutoRemoval(`Whoops! This game was not found.`)(dispatch)
            }

            return gamePipeline
        }
    } catch
        (err) {
        console.error(`${err} : ${url}`)
        addErrorAlertWithAutoRemoval(err)(dispatch)
    }
}

export function findGamePipeline(gamePipelineId, gameEventPipelines) {
    return gamePipelineId ?
        gameEventPipelines?.find(gp => gp.id === gamePipelineId)
        : findMostRecentGame()

    function findMostRecentGame() {
        return gameEventPipelines.length > 0 ?
            gameEventPipelines?.reduce((a, b) => {
                if (dateTimeEquals(new Date(a?.gameDate), new Date(b?.gameDate))) {
                    return a.id > b.id ? a : b
                }
                return a.gameDate > b.gameDate ? a : b
            }) : null
    }
}

const saveGame = async (method, game, feedTypeCode) => {
    let url = `${settings.YODA_ROOT}/api/v1/games/${game.gamePk}/feeds/${feedTypeCode}`

    try {
        const response = await fetch(url,
            await withAuthHeader(
                {
                    // mode:'no-cors',
                    method: method,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(game)
                }))
        if (!response.ok) {
            const errorRes = await response.json()
            console.error(errorRes.message)
            throw new CerebroError(errorRes.message)
        }
        return await response.json()
    } catch (err) {
        const errorMsg = `${err}`
        throw new CerebroError(errorMsg)
    }
}

async function patchGame(game, feedTypeCode, patchEvent) {
    let url = `${settings.YODA_ROOT}/api/v1/games/${game.gamePk}/feeds/${feedTypeCode}`

    try {
        const response = await fetch(url,
            await withAuthHeader(
                {
                    // mode:'no-cors',
                    method: "PATCH",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(patchEvent)
                }))
        if (!response.ok) {
            const errorRes = await response.json()
            console.error(errorRes.message)
            throw new CerebroError(errorRes.message)
        }
        return await response.json()
    } catch (err) {
        const errorMsg = `${err}`
        throw new CerebroError(errorMsg)
    }
}

export const createGamePipeline = async (game, feedTypeCode) => {
    console.log("Trying to create game")
    const resp = await saveGame('POST', game, feedTypeCode)
    console.log("Created game with UUID ", resp)
}

export const patchGamePipeline = async (game, feedTypeCode, patchEvent) => {
    console.log("Trying to patch game")
    const resp = await patchGame(game, feedTypeCode, patchEvent)
    console.log("Patched game with UUID ", resp)
}

export const postGamePipelineStreamAction = async (game, action) => {
    const {gamePk, feedType} = game

    const url = `${settings.YODA_ROOT}/api/v1/games/${gamePk}/feeds/${feedType?.code}/activated`
    let method

    if (action === GameStreamAction.START) {
        method = 'POST'
    } else if (GameStreamAction.STOP) {
        method = 'DELETE'
    }
    try {
        const response = await fetch(url,
            await withAuthHeader(
                {
                    // mode: 'no-cors',
                    method: method,
                    headers: {
                        'Content-Type': 'application/json'
                    }

                }))

        if (!response.ok) {
            const errorPayload = await response.json()
            throw new Error(errorPayload.message)
        }

        return await response.json()
    } catch (err) {
        const errorMsg = `Could not ${action} game ${gamePk}, feed type ${feedType?.code} url ${url} : ${err}`
        console.error(errorMsg)
        throw new CerebroError(errorMsg)
    }
}

export const fetchAssets = (gamepk, feedType) => async dispatch => {
    console.log(`Fetching game assets for ${gamepk} with feed type ${feedType} from server.`)
    let url = new URL(`${settings.YODA_ROOT}/api/v1/games/${gamepk}/assets?feedTypeCode=${feedType}`)
    try {
        const response = await fetch(url
            , await withAuthHeader())

        if (response.status === 404) {
            addWarnAlertWithAutoRemoval(`Whoops! This game was not found.`)(dispatch)
        } else if (!response.ok) {
            const errorMsg = `There was a problem fetching game assets with gamepk ${gamepk} and feedType ${feedType} from the server.`
            throw new CerebroError(errorMsg)
        } else {
            return await response.json()
        }
    } catch (err) {
        console.error(`${err} : ${url}`)
        addErrorAlertWithAutoRemoval(err)(dispatch)
    }
}

export const setPrimaryPlayback = (gamePk, feedTypeCode, assetTypeCode) => async dispatch => {
    console.log(`Setting primary playback for ${gamePk} with feed type ${feedTypeCode} to ${assetTypeCode}`)
    let url = new URL(`${settings.YODA_ROOT}/api/v1/games/${gamePk}/feeds/${feedTypeCode}/assets/primary/${assetTypeCode}`)
    try {
        const response = await fetch(url, await withAuthHeader({
            method: 'PUT',
            headers: {'Content-Type': 'application/json'}
        }))

        if (!response.ok) {
            addWarnAlertWithAutoRemoval(`Unable to update the primary playback.`)(dispatch)
        } else {
            dispatch(updatePrimaryPlayback(gamePk, feedTypeCode, assetTypeCode))
        }
    } catch (err) {
        console.error(`${err} : ${url}`)
        addErrorAlertWithAutoRemoval(err)(dispatch)
    }
}
export const updateAdBreakAdhoc = (event, duration, type) => async dispatch => {
    console.log(event)
    const {id} = event
    console.log(`Begin ${duration} second ad break for event ${id}`)
    let url = new URL(`${settings.API_ROOT}/api/v1/events/${id}/ad-break`)
    try {
        const response = await fetch(url, await withAuthHeader({
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                'type': type,
                'duration': duration
            })

        }))

        if (!response.ok) {
            addWarnAlertWithAutoRemoval(`Unable to begin ad break.`)(dispatch)
        }

    } catch (err) {
        console.error(`${err} : ${url}`)
        addErrorAlertWithAutoRemoval(err)(dispatch)
    }
}

export const updateAdBreak = (gameEventPipeline, duration, type) => async dispatch => {
    const {gamePk} = gameEventPipeline
    const feedTypeCode = gameEventPipeline.feedType.code
    console.log(`Begin ${duration} second ad break for ${gamePk} with feed type ${feedTypeCode}`)
    let url = new URL(`${settings.YODA_ROOT}/api/v1/games/${gamePk}/feeds/${feedTypeCode}/ad-break`)
    try {
        const response = await fetch(url, await withAuthHeader({
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                'type': type,
                'duration': duration
            })

        }))

        if (!response.ok) {
            addWarnAlertWithAutoRemoval(`Unable to ${type === SCTE_35_CUE_IN ? "end" : "start"} ad break.`)(dispatch)
        }

    } catch (err) {
        console.error(`${err} : ${url}`)
        addErrorAlertWithAutoRemoval(err)(dispatch)
    }
}

export const fetchPlaybackUrl = (gameAsset) => async dispatch => {
    console.log(`Fetching playback Url for gameAsset ${gameAsset?.id} from server.`)
    const assetMediaLocation = gameAsset?.assetMediaLocations?.find(aml => aml?.mediaLocation?.code === 'CDN_URL')
    let url = new URL(`${settings.YODA_ROOT}/api/v1/playback-url/${assetMediaLocation?.id}`)
    try {
        const response = await fetch(url
            , await withAuthHeader())

        if (response.status === 404) {
            addWarnAlertWithAutoRemoval(`Whoops! This playback Url was not found.`)(dispatch)
        } else if (!response.ok) {
            const errorMsg = `There was a problem fetching playback Url for gameAsset ${gameAsset?.id} from the server.`
            throw new CerebroError(errorMsg)
        } else {
            const uri = await response.text()
            gameAsset.cdnUri = uri
            dispatch(updateGameAsset(gameAsset))
        }
    } catch (err) {
        console.error(`${err} : ${url}`)
        addErrorAlertWithAutoRemoval(err)(dispatch)
    }
}

export const applyYodaSlate = (slateUri, gameEventPipeline) => async dispatch => {
    const url = `${settings.YODA_ROOT}/api/v1/games/${gameEventPipeline?.gamePk}/feeds/${gameEventPipeline?.feedType?.code}/slates`

    try {
        const body = {
            uri: slateUri
        }
        const response = await fetch(url, await withAuthHeader({
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        }))

        if (!response.ok) {
            throw new CerebroError(`Cound not apply slate to game with uri '${slateUri}'`)
        }

    } catch (err) {
        console.error(`Error when applying slate to game ${gameEventPipeline?.id} ${err}`)
        throw err
    }
}

export const liftYodaSlate = (gameEventPipeline) => async dispatch => {
    const url = `${settings.YODA_ROOT}/api/v1/games/${gameEventPipeline?.gamePk}/feeds/${gameEventPipeline?.feedType?.code}/slates`

    try {

        const response = await fetch(url, await withAuthHeader({
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }))

        if (!response.ok) {
            throw new CerebroError(`Cound not lift slate to game '${gameEventPipeline?.gamePk}' with feed type code ${gameEventPipeline?.feedType?.code}`)
        }

    } catch (err) {
        console.error(`Error when lifting slate to game ${gameEventPipeline?.id} ${err}`)
        throw err
    }
}

export const cancelGame = (gameEventPipeline) => async dispatch => {
    const url = `${settings.YODA_ROOT}/api/v1/games/${gameEventPipeline?.gamePk}/feeds/${gameEventPipeline?.feedType?.code}`
    try {

        const response = await fetch(url, await withAuthHeader({
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }))

        if (!response.ok) {
            const errorRes = await response.json()
            throw new CerebroError(errorRes.message)
        }
    } catch (err) {
        console.error(`Error when canceling game ${gameEventPipeline?.id} ${err}`)
        throw err
    }
}

export const fetchAssetsAndPlaybackUrl = (gamePk, feedType) => async dispatch => {
    let gameAssets
    try {
        gameAssets = await fetchAssets(gamePk, feedType)(dispatch)
    } catch (err) {
        const errorMsg = `There was a problem fetching the game assets from the server.`
        console.error(`${errorMsg}: ${err}`)
        addErrorAlertWithAutoRemoval(errorMsg)(dispatch)
    }

    for (const gameAsset of gameAssets) {
        await fetchPlaybackUrl(gameAsset)(dispatch)
    }
}
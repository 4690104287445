import React from 'react'
import LabeledInput from './LabeledInput'
import styles from '../../stylesheets/inputs/EntryInput.module.css'

const EntryInput = ({
    name, label, description, placeholder, defaultValue, pattern, invalid,
    minLength, maxLength, required, disabled, textarea, onChange, labelClass
  }) => {
  const inputClass = invalid ? `${styles.invalid} ${styles.input}` : styles.input
  const textareaClass = invalid ? `${styles.invalid} ${styles.textarea}` : styles.textarea
  return (
    <LabeledInput
      name={name}
      label={label}
      description={description}
      placeholder={placeholder}
      defaultValue={defaultValue}
      pattern={pattern}
      minLength={minLength}
      maxLength={maxLength}
      altText={maxLength && `${maxLength} character limit`}
      required={required}
      disabled={disabled}
      textarea={textarea}
      labelClass={labelClass ? labelClass : styles.label}
      descriptionClass={styles.description}
      altTextClass={styles.altText}
      inputClass={inputClass}
      textareaClass={textareaClass}
      requiredClass={styles.required}
      onChange={onChange}
    />
  )
}

export default EntryInput

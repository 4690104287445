import React, {Component} from 'react'
import {ALERT_TYPE} from '../utils'
import {CSSTransitionGroup} from 'react-transition-group'
import styles from '../stylesheets/Alerts.module.css'

const getAlertStyle = type => {
  switch (type) {
    case ALERT_TYPE.ERROR:
      return styles.error
    case ALERT_TYPE.WARN:
      return styles.warn
    default:
      return styles.info
  }
}

class Alerts extends Component {
  render = () => {
    const {alerts, closeAlert} = this.props
      return (
      <div style={{background: 'blue'}}>
        <CSSTransitionGroup
          transitionName="cerebro-alert"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}>
          {
            alerts.map(({id, type, message}) =>
              <AlertItem key={id}
                         id={id}
                         type={type}
                         message={message}
                         onClick={closeAlert}/>)
          }
        </CSSTransitionGroup>
      </div>
    )
  }
}

const AlertItem = ({id, type, message, onClick}) => {
  return (
    <div className={getAlertStyle(type)}>
      <div className={styles.empty} />
      <div className={styles.message}>{message}</div>
      <div className={styles.closeContainer}>
        <span className={styles.close} onClick={() => onClick(id)}>&times;</span>
      </div>
    </div>
  )
}

export default Alerts

import React, {useEffect, useState, useRef, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import moment from 'moment'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import NavBar from '../components/NavBar'
import DateTimePicker from 'react-datetime-picker'
import Slate from './Slate'
import Collapsible from './Collapsible'
import ImageUploader from 'react-images-upload'
import BeatLoader from 'react-spinners/BeatLoader'
import EntryInput from './inputs/EntryInput'
import {
  ChannelParamEntry,
  getKey, generateFromTemplate,
  skipAutoDisplay,
  CHANNEL_PARAM_NAMES,
  HIDDEN_CHANNEL_PARAMS, CHANNEL_PARAM_DEFAULTS,
  COLLAPSIBLE_CHANNEL_PARAMS
} from './ChannelParam'
import {
  DEFAULT_THUMBNAIL,
  EVENT_STATUS, objectToArrayOfKeyValuePair
} from '../utils'
import {
  uploadImage, addInfoAlertWithAutoRemoval,
  addErrorAlertWithAutoRemoval, addWarnAlertWithAutoRemoval,
  fetchAdhocProfiles, setSelectedProfileId, fetchEventStatuses,
} from '../actions'
import {
  validate, resetValidation,
  setEventTypeNotSelected, setNodeNotSelected, setProfileNotSelected
} from '../actions/validation'
import {fetchEvent, replaceEvent, createEvent} from '../actions/events'
import {
  fetchNodes, setSelectedNodeId,
} from '../actions/CreateEvent'
import {fetchEventTypes, setSelectedEventTypeId} from '../actions/event-types'
import {fetchSlates, CategoryCodes} from '../actions/slates'
import {CerebroError} from '../errors'
import styles from '../stylesheets/CreateEventForm.module.css'
import RemoveSymbol from '../assets/remove-symbol.svg'
import LeagueDropdown from "./LeagueDropdown"
import settings from "../settings"
import NextGenGameDropdown from "./NextGenGameDropdown"
import {FormGroup} from "@mui/material"
import {fetchGameData} from "../actions/stats"
import Cookies from "universal-cookie"

const cookie = new Cookies()
const shouldUseDefaultParamValue = (form, name) =>
  CHANNEL_PARAM_DEFAULTS.has(name) && form[name] && !form[name].value

const partitionParams = channelParams => {
  //partition into collapsible vs the rest
  return [
    //keep it simple.  easier than to use fold().  does not see a partition function.
    channelParams.filter(({name}) => COLLAPSIBLE_CHANNEL_PARAMS.has(name)),
    channelParams.filter(({name}) => !COLLAPSIBLE_CHANNEL_PARAMS.has(name))
  ]
}
const adhocSupportedSportIds = settings.SUPPORTED_SPORT_IDS.filter(l => l.type === 'adhoc').map(league => league.id)

export default function CreateEventForm() {
  // Default event when accessing 'Pending' or cloned events
  const now = new Date()
  const later = new Date(now.getTime() + 3600000)
  const [event, setEvent] = useState(null)

  const [channelName, setChannelName] = useState(null)
  const [startTime, setStartTime] = useState(now)
  const [endTime, setEndTime] = useState(later)
  const [programStartTime, setProgramStartTime] = useState(now)
  const [programEndTime, setProgramEndTime] = useState(later)
  const [autoSchedule, setAutoSchedule] = useState(false)
  const [gamePk, setGamePk] = useState(null)

  const [channelParams, setChannelParams] = useState([])
  const [thumbnail, setThumbnail] = useState(null)
  const [displayThumbnail, setDisplayThumbnail] = useState(false)
  const [thumbnailChanged, setThumbnailChanged] = useState(false)
  const [hasSlateParam, setHasSlateParam] = useState(false)
  const [startSlate, setStartSlate] = useState(null)
  const [isScheduling, setIsScheduling] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [formFieldsChanged, setFormFieldsChanged] = useState(false)
  const [streamState, setStreamState] = useState(null)
  const [version, setVersion] = useState(null)
  const leagues = useSelector(state => state.leagues)
  const [sportIds, setSportIds] = useState(cookie.get("createEventSportIds")?.filter(l =>
      adhocSupportedSportIds.includes(l.id)) || leagues.filter(l => adhocSupportedSportIds.includes(l.id)).map(l => l.id))

  const eventTypes = useSelector(state => state.eventTypes)
  const nodes = useSelector(state => state.nodes)
  const profiles = useSelector(state => state.profiles)
  const slates = useSelector(state => state.slates)
  const selectedEventTypeId = useSelector(state => state.selectedEventTypeId)
  const selectedProfileId = useSelector(state => state.selectedProfileId)
  const selectedNodeId = useSelector(state => state.selectedNodeId)
  const eventStatuses = useSelector(state => state.eventStatuses)
  const formValidationError = useSelector(state => state.formValidationError)

  let form = useRef()
  const dispatch = useDispatch()
  const {eventId} = useParams()
  let history = useHistory()

  const extractChannelParams = (form) => {
    return channelParams.reduce((extractedParams, {name}) => {
      if (name === CHANNEL_PARAM_NAMES.slateUrl) {
        extractedParams[name] = startSlate?.uri
      } else if (shouldUseDefaultParamValue(form, name)) {
        extractedParams[name] = CHANNEL_PARAM_DEFAULTS.get(name)
      } else {
        extractedParams[name] = form[name]?.value?.trim() ?? ''
      }

      return extractedParams
    }, Object.fromEntries(HIDDEN_CHANNEL_PARAMS))
  }

  const handleThumbnailRm = () => {
    setThumbnail(null)
    setDisplayThumbnail(false)
    setThumbnailChanged(true)
  }

  const handleTitleChange = e => {
    e.preventDefault()
    setChannelName(e.target.value)
    setFormFieldsChanged(true)
  }

  const handleStartTimeChange = newStartTime => {
    // If the start date changed, reset the game selection
    if (moment(startTime).diff(moment(newStartTime), 'days')) {
      setGamePk(null)
    }
    setStartTime(newStartTime)
    setFormFieldsChanged(true)
  }

  const handleTypeChange = option => {
    dispatch(setSelectedEventTypeId(option.value))
    dispatch(setEventTypeNotSelected(false))
    setFormFieldsChanged(true)
  }

  const handleNodeChange = option => {
    dispatch(setSelectedNodeId(option.value))
    dispatch(setNodeNotSelected(false))
  }

  const handleProfileChange = option => {
    dispatch(setSelectedProfileId(option.value))
    dispatch(setProfileNotSelected(false))
    setFormFieldsChanged(true)
  }

  const handleGameChange = async (_, event) => {
    if (event !== null) {
      setGamePk(event.value)
    }
    else {
      setGamePk(null)
    }
  }

  const handleSubmit = async (evtStatus, clickEvt) => {
    clickEvt.preventDefault()
    const curForm = form.current
    const blurb = curForm.blurb.value
    const title = curForm.title.value
    const description = curForm.description.value

    if (evtStatus === EVENT_STATUS.PENDING) {
      setIsSaving(true)
    } else {
      setIsScheduling(true)
    }

    const type = eventTypes.find(e => e.id === selectedEventTypeId)
    const channelParams = extractChannelParams(curForm)
    const status = evtStatus === EVENT_STATUS.PENDING
      ? eventStatuses.find(s => s.name === EVENT_STATUS.PENDING)
      : eventStatuses.find(s => s.name === EVENT_STATUS.SCHEDULED)
    const id = parseInt(eventId)
    let reqEvent = {
      id,
      blurb: blurb,
      title: title,
      description: description,
      startTime,
      endTime,
      programStartTime,
      programEndTime,
      autoScheduled: autoSchedule,
      gamePk,
      nodeId: selectedNodeId,
      profile: profiles.find(profile => profile.id === selectedProfileId),
      type,
      channelParams,
      tags: type?.defaultTags ?? [],
      status,
      streamState,
      version
    }
    const valid = validate(reqEvent, evtStatus)(dispatch)

    if (!valid) {
      setIsSaving(false)
      setIsScheduling(false)
      return
    }

    let thumbnailUrl = DEFAULT_THUMBNAIL
    try {
      if (event && !thumbnailChanged) {
        thumbnailUrl = event.thumbnailUrl
      } else if (thumbnail) {
        thumbnailUrl = await uploadImage(thumbnail)(dispatch)
      }

      reqEvent = {...reqEvent, thumbnailUrl}
      const savedEvent = reqEvent.id
        ? await replaceEvent(reqEvent)(dispatch)
        : await createEvent(reqEvent)(dispatch)

      if (reqEvent?.status?.id !== savedEvent?.status?.id) {
        addWarnAlertWithAutoRemoval(`The event was created successfully, \
          however, some resources could not be provisioned. Please try again.`)(dispatch)

        // Turn off saving states since we're navigating to this component
        setIsSaving(false)
        setIsScheduling(false)
        history.push(`/create/${savedEvent.id}`)
      } else {
        addInfoAlertWithAutoRemoval("The event was created successfully.")(dispatch)
        history.push('/')
      }
    } catch (err) {
      console.error(`Create event failed with error: ${err}`)

      if (err instanceof CerebroError) {
        addErrorAlertWithAutoRemoval(err.message)(dispatch)
      } else {
        addErrorAlertWithAutoRemoval("Whoops! There was a problem creating the event.")(dispatch)
      }
      setIsSaving(false)
      setIsScheduling(false)
    }
  }

  const populateFromEvent = useCallback(event => {
    if (!event)
      return

    setEvent(event)
    setChannelName(event.title)
    setStartTime(new Date(event.startTime))
    setEndTime(new Date(event.endTime))
    setProgramStartTime(new Date(event.programStartTime))
    setProgramEndTime(new Date(event.programEndTime))
    setGamePk(event.gamePk)
    setAutoSchedule(event.autoScheduled)
    setDisplayThumbnail(true)
    setStartSlate(event.activeSlate)
    setStreamState(event.streamState)
    setVersion(event.version)
    dispatch(setSelectedEventTypeId(event?.type?.id))
    dispatch(setSelectedNodeId(event.nodeId))
    dispatch(setSelectedProfileId(event?.profile?.id))
  }, [dispatch])

  useEffect(() => {
    if (isSaving || isScheduling) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }
  }, [isSaving, isScheduling])

  useEffect(() => {
    const profile = profiles.find(profile => profile.id === selectedProfileId)

    if (profile) {
      setChannelParams(objectToArrayOfKeyValuePair(profile.params)
        .sort((a, b) => (a.name > b.name) ? 1 : -1))
      setHasSlateParam(profile.params.hasOwnProperty(CHANNEL_PARAM_NAMES.slateUrl))
    } else {
      setChannelParams([])
      setHasSlateParam(false)
    }
  }, [profiles, selectedProfileId])


  useEffect(() => {
    if (!event)
      return

    let fetchSportIdFromGame = async event => {
      if(event.gamePk){
        let gameData = await fetchGameData(event.gamePk, event.startTime)
        setSportIds([gameData?.teams?.away?.team?.sport?.id])
      } else {
        setSportIds(cookie.get("createEventSportIds") ||
            leagues?.map(l => l.id).filter(l => adhocSupportedSportIds.includes(l.id)))
      }
    }
    fetchSportIdFromGame(event)
  }, [event, leagues])

  // If the sportsId is not populated by fetchSportIdFromGame with a gamePk (when you're cloning an event, for example)
  // tries to get data from cookies and set sportsId based on leagues' selector (Common case when you are scheduling)
  useEffect(() => {
    if(!leagues)
      return

      setSportIds(cookie.get("createEventSportIds") ||
          leagues?.map(l => l.id).filter(l => adhocSupportedSportIds.includes(l.id)))
  }, [leagues])

  // Attempt to populate form from an event passed through
  // history or passed in as a URI path parameter.
  useEffect(() => {
    const fetchSavedEvent = async eventId => {
      const event = await fetchEvent(eventId)(dispatch)
      populateFromEvent(event)
    }

    const id = parseInt(eventId)
    if (id) {
      fetchSavedEvent(id)
    } else {
      populateFromEvent(history.location?.state?.event)
    }
  }, [populateFromEvent, dispatch, history, eventId])

  useEffect(() => {
    if (!autoSchedule) {
      setProgramStartTime(startTime)
      setProgramEndTime(endTime)
    }
  }, [startTime, endTime, autoSchedule])

  useEffect(() => {
    fetchEventTypes()(dispatch)
    fetchAdhocProfiles()(dispatch)
    fetchNodes()(dispatch)
    fetchSlates()(dispatch)
    fetchEventStatuses()(dispatch)
    resetValidation()

    return function cleanup() {
      resetValidation()(dispatch)
      dispatch(setSelectedProfileId(null))
      dispatch(setSelectedNodeId(null))
      dispatch(setSelectedEventTypeId(null))
    }
  }, [dispatch])

  let defaultBlurb, defaultTitle, defaultDescription
  let defaultStartTime = startTime, defaultEndTime = endTime
  let defaultPgmStartTime = programStartTime, defaultPgmEndTime = programEndTime
  let defaultChannelParams = channelParams

  if (event) {
    defaultBlurb = event.blurb
    defaultTitle = event.title
    defaultDescription = event.description

    if (!formFieldsChanged) {
      defaultChannelParams = objectToArrayOfKeyValuePair(event.channelParams)
        .sort((a, b) => (a.name > b.name) ? 1 : -1)
      defaultStartTime = new Date(event.startTime)
      defaultEndTime = new Date(event.endTime)
      defaultPgmStartTime = new Date(event.programStartTime)
      defaultPgmEndTime = new Date(event.programEndTime)
    }
  }

  let thumbnailContent
  if (displayThumbnail) {
    thumbnailContent = (
      <div className={styles.thumbnailContainer}>
        <img src={event && event.thumbnailUrl} alt="event thumbnail"
             className={styles.thumbnail}/>
        <img src={RemoveSymbol} alt="remove thumbnail"
             className={styles.thumbnailRemoveIcon}
             onClick={handleThumbnailRm}/>
      </div>
    )
  } else {
    thumbnailContent = (
      <ImageUploader
        withIcon={true}
        buttonText='Choose image'
        onChange={thumbnails => setThumbnail(thumbnails[0])}
        imgExtension={['.jpg', '.png']}
        label={'Max file size: 5mb, accepted: jpg|png'}
        maxFileSize={5242880}
        withPreview={true}
        singleImage={true}
      />
    )
  }
  const handleLeagueChange = (e) => {
    const checked = e.target.value
    let newSportIds
    if (checked.includes('Select All')) {
      newSportIds = adhocSupportedSportIds
    } else if (checked.includes('Deselect All')) {
      newSportIds = []
    } else {
      newSportIds = checked
    }
    cookie.set("createEventSportIds", newSportIds)
    setSportIds(newSportIds)
  }
  const {
    invalidMetadata, invalidStreamTime, invalidProgramTime,
    profileNotSelected, nodeNotSelected, eventTypeNotSelected,
    invalidChannelParams
  } = formValidationError
  const [
    collapsibleChannelParams, restOfChannelParams
  ] = partitionParams(defaultChannelParams)

  const streamTimeClassName = invalidStreamTime
    ? `${styles.invalidDatetime} ${styles.datetimeSelector}`
    : styles.datetimeSelector
  const pgmTimeClassName = invalidProgramTime
    ? `${styles.invalidDatetime} ${styles.datetimeSelector}`
    : styles.datetimeSelector
  const scheduleContent = (
    <div className={styles.scheduleContainer}>
      <div className={styles.inputGroup}>
        <label>Stream Start & End Time</label>
        <div className={styles.datetimeGroup}>
          <DateTimePicker className={streamTimeClassName}
                          calendarClassName={styles.datetimeCalendar}
                          disableClock={true}
                          onChange={handleStartTimeChange}
                          value={defaultStartTime}/>
          <DateTimePicker className={streamTimeClassName}
                          calendarClassName={styles.datetimeCalendar}
                          disableClock={true}
                          onChange={setEndTime}
                          value={defaultEndTime}/>
        </div>
      </div>
      <div className={styles.inputGroup}>
        <label style={!autoSchedule ? {color: '#AAAAAA'} : {}}>
          Program Start & End Time
        </label>
        <div className={styles.datetimeGroup}>
          <DateTimePicker className={pgmTimeClassName}
                          calendarClassName={styles.datetimeCalendar}
                          disableClock={true}
                          disabled={!autoSchedule}
                          onChange={setProgramStartTime}
                          value={defaultPgmStartTime}/>
          <DateTimePicker className={pgmTimeClassName}
                          calendarClassName={styles.datetimeCalendar}
                          disableClock={true}
                          disabled={!autoSchedule}
                          onChange={setProgramEndTime}
                          value={defaultPgmEndTime}/>
        </div>
      </div>
      <div className={styles.inputGroupCheckbox}>
        <input id="autoScheduled" type="checkbox" checked={autoSchedule}
          onChange={() => setAutoSchedule(!autoSchedule)}/>
        <label htmlFor="autoScheduled">Auto Schedule</label>
      </div>
    </div>
  )
  const curEventType = eventTypes?.find(e => e.id === selectedEventTypeId)

  return (
    <div>
      <NavBar/>
      <form ref={form}>
        <div className={styles.createEvent}>
          <div className={styles.row}>
            <div className={styles.metaColumn}>
              <EntryInput label="Blurb" name="blurb" minLength={3} maxLength={53} required
                          defaultValue={defaultBlurb}
                          invalid={!!invalidMetadata['blurb']} description={invalidMetadata['blurb']}/>
              <EntryInput label="Title" name="title" maxLength={53} onChange={handleTitleChange} required
                          defaultValue={defaultTitle}
                          invalid={!!invalidMetadata['title']} description={invalidMetadata['title']}/>
              <EntryInput label="Description" name="description" maxLength={140} required textarea
                          defaultValue={defaultDescription}
                          invalid={!!invalidMetadata['description']} description={invalidMetadata['description']}/>
              {scheduleContent}
              <FormGroup className={styles.inputGroupGame}>

              <LeagueDropdown
                  sportIdsSelected={sportIds}
                  supportedSportIds={adhocSupportedSportIds}
                  onChange={handleLeagueChange}
              />
              </FormGroup>
              <FormGroup className={styles.inputGroupGame}>
                <NextGenGameDropdown
                    onChange={handleGameChange}
                    gamePk={gamePk}
                    sportIds={sportIds}
                    startDate={startTime}
                />
              </FormGroup>
              <div className={styles.labelContainer}>
                {thumbnailContent}
              </div>
            </div>
            <div className={styles.elementalColumn}>
              <div className={styles.conductorField}>
                <div className={styles.conductorLabel}>
                  Type
                </div>
                <Dropdown
                  placeholder="Select a type"
                  options={eventTypes.map(e => ({"value": e.id, "label": e.name}))}
                  value={eventTypes.find(e => e.id === selectedEventTypeId)?.name}
                  onChange={handleTypeChange}
                  className={eventTypeNotSelected ? `${styles.invalidDropdown} ${styles.dropdown}` : styles.dropdown}
                />
              </div>
              <div className={styles.conductorField}>
                <div className={styles.conductorLabel}>
                  Node
                </div>
                <Dropdown
                  placeholder="Select a node"
                  options={nodes.map(n => ({"value": n.id, "label": n.hostname}))}
                  value={nodes.find(n => n.id === selectedNodeId)?.hostname}
                  onChange={handleNodeChange}
                  className={nodeNotSelected ? `${styles.invalidDropdown} ${styles.dropdown}` : styles.dropdown}
                />
              </div>
              <div className={styles.conductorField}>
                <div className={styles.conductorLabel}>
                  Profile
                </div>
                <Dropdown
                  placeholder="Select a profile"
                  options={profiles.map(p => ({"value": p.id, "label": p.displayName}))}
                  value={profiles.find(p => p.id === selectedProfileId)?.displayName}
                  onChange={handleProfileChange}
                  className={profileNotSelected ? `${styles.invalidDropdown} ${styles.dropdown}` : styles.dropdown}
                />
              </div>
              <ChannelParams params={restOfChannelParams}
                             event={({type: curEventType, title: channelName, startTime})}
                             hydratingForm={!!event} formFieldsChanged={formFieldsChanged}
                             invalidChannelParams={invalidChannelParams}/>
              <div style={collapsibleChannelParams.length === 0 ? {display: 'none'} : {}}>
                <Collapsible label="Audio Feed Options">
                  <ChannelParams params={collapsibleChannelParams}
                                 event={({type: curEventType, title: channelName, startTime})}
                                 hydratingForm={!!event} formFieldsChanged={formFieldsChanged}
                                 invalidChannelParams={invalidChannelParams}/>
                </Collapsible>
              </div>
              {
                hasSlateParam &&
                <div className={styles.conductorField}>
                  <div
                    className={invalidChannelParams[CHANNEL_PARAM_NAMES.slateUrl] ? `${styles.invalidInputAlt} ${styles.conductorLabel}` : styles.conductorLabel}>Starting
                    Slate
                  </div>
                  <div className={styles.slateContainer} style={{paddingBottom: '15px'}}>
                    {slates
                      .filter(slate => slate?.category?.code === CategoryCodes.STARTING)
                      .map(slate =>
                        <Slate key={slate.id} slate={slate} active={startSlate && startSlate.id === slate.id}
                               handleClick={() => setStartSlate(slate)}/>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
          <div className={styles.row}>
            <button onClick={(e) => handleSubmit(EVENT_STATUS.PENDING, e)}
                    className={styles.saveForLaterButton} disabled={disableSubmit}>
              {
                isSaving
                  ? <BeatLoader size={8} color={"#FFFFFF"} loading={isSaving}/>
                  : 'Save for Later'
              }
            </button>
            <button onClick={(e) => handleSubmit(EVENT_STATUS.SCHEDULED, e)}
                    className={styles.submitButton} disabled={disableSubmit}>
              {
                isScheduling
                  ? <BeatLoader size={8} color={"#FFFFFF"} loading={isScheduling}/>
                  : 'Schedule It'
              }
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

const ChannelParams = ({
                         params, event, hydratingForm,
                         formFieldsChanged, invalidChannelParams
                       }) => {
  const templates = event?.type?.channelParamTemplates ?? {}
  return (
    params.filter(({name}) => !skipAutoDisplay(name))
      .map(({name, value}) =>
        <ChannelParamEntry key={getKey(name, event)}
          name={name} value={value} invalidReason={invalidChannelParams[name]}
          defaultValue={hydratingForm && !formFieldsChanged ? value :
            generateFromTemplate(event, templates[name])}/>)
  )
}

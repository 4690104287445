const StyleConstants = {
  colors: {
    blue: '#0074D9',
    aqua: '#7FDBFF',
    green: '#2ECC40',
    lime: '#01FF70',
    yellow: '#FFDC00',
    orange: '#FF851B',
    red: '#FF4136',
    purple: '#B10DC9',
    black: '#111111',
    gray: '#757575',
    silver: '#DDDDDD',
    white: '#FFFFFF',
    gunmetal: '#3F3F3F'
  }

}

export default StyleConstants

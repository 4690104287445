import {useDispatch} from "react-redux";
import {setStatusFilter} from "./index"
import Cookies from 'universal-cookie'
const cookie = new Cookies()
const cookiesConfig = [{id: 'status', handler: setStatusFilter}]

export default function useCookies() {
    const dispatch = useDispatch()
    cookiesConfig.forEach(c => {
        let cookieValue = cookie.get(c.id)
        if (cookieValue) {
            dispatch(c.handler(cookieValue))
        }
    })
}

export function updateCookie(id, value){
    cookie.set(id, value)
}

export function getCookie(id) {
    return cookie.get(id)
}
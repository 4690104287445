import React, {useState, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {createSelector} from 'reselect'
import {useHistory, useParams} from 'react-router-dom'
import moment from 'moment'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import TagsDropdown from './TagsDropdown'
import Select from 'react-select'
import NavBar from './NavBar'
import CerebroPopup from './CerebroPopup'
import HlsPlayer from './HlsPlayer'
import RemoveSymbol from '../assets/remove-symbol.svg'
import InfoSymbol from '../assets/info.svg'
import Slate from './Slate'
import ImageUploader from 'react-images-upload'
import BeatLoader from 'react-spinners/BeatLoader'
import EditableInput from './inputs/EditableInput'
import Collapsible from './Collapsible'
import AudioPanel, {LOWEST_GAIN_LEVEL} from './AudioPanel'
import {Status} from './EventCards'
import DateTimePicker from 'react-datetime-picker'
import {
  ChannelParamEditable, getKey, CHANNEL_PARAM_NAMES,
  generateFromTemplate, skipAutoDisplay
} from './ChannelParam'
import {CerebroError} from '../errors'
import {
  addInfoAlertWithAutoRemoval,
  addWarnAlertWithAutoRemoval,
  addErrorAlertWithAutoRemoval,
  uploadImage,
  fetchAdhocProfiles,
  setSelectedProfileId, fetchEventStatuses,
} from '../actions'
import {
  validate, resetValidation,
  setEventTypeNotSelected, setNodeNotSelected, setProfileNotSelected
} from '../actions/validation'
import {
  fetchEvent, replaceEvent, archiveEvent, deactivateEventSchedule,
  applySlate, liftSlate, adjustGain, postEventStreamAction, EventStreamAction,
  fetchPlaybackUrl, updateStatus, republishToForge
} from '../actions/events'
import {fetchEventTypes, setSelectedEventTypeId} from '../actions/event-types'
import {fetchSlates, fetchSlateCategories, CategoryCodes} from '../actions/slates'
import {updateChannel} from '../actions/channels'
import {fetchTags} from '../actions/tags'
import {
  fetchNodes,
  setSelectedNodeId,
} from '../actions/CreateEvent'
import { EVENT_STATUS } from '../utils'
import styles from '../stylesheets/EventDetail.module.css'
import '../stylesheets/ReactTags.css'
import {fetchToken} from '../actions/auth'
import AdBreakButtons, {SCTE_35_CUE_IN} from "./AdBreakButtons"
import {updateAdBreakAdhoc} from "../actions/games"
import {getCookie} from "../actions/cookies"
import LeagueDropdown from "./LeagueDropdown"
import NextGenGameDropdown from "./NextGenGameDropdown"
import {FormGroup} from "@mui/material"
import {fetchGameData} from "../actions/stats"
import settings from "../settings"

const ButtonWithPopup = (props) => {
  const {btnText, handleClick, className, popupTitle, popupMsg, previewImg} = props
  const trigger = (<button type='button' className={className}>{btnText}</button>)

  return (
    <CerebroPopup
      trigger={trigger}
      title={popupTitle ? popupTitle : btnText}
      body={popupMsg}
      img={previewImg}
      handleContinue={handleClick}
    />
  )
}

// Change the tags data to conform to react-select's expected format
const selectAvailableTags = createSelector(
  state => state.availableTags,
  availableTags => availableTags.map(tag => {
    const { id, text, ...rest } = tag
    return { value: id, label: text, ...rest }
  })
)

function adBreakisOver(adBreakDuration, adBreakStartTime) {
  return adBreakDuration !== null && adBreakDuration < (secondsAdRunningFor(adBreakStartTime))
}

function secondsAdRunningFor(adBreakStartTime) {
  const currentTime = new Date().getTime() / 1000
  const startOfAd = new Date(adBreakStartTime).getTime() / 1000
  return currentTime - startOfAd
}

function adBreakIsActive(event) {
  return event.adBreakStartTime !== null && !adBreakisOver(event.adBreakDurationInSeconds, event.adBreakStartTime)
}

function adBreakInactive(event) {
  return event.adBreakStartTime === null || adBreakisOver(event.adBreakDurationInSeconds, event.adBreakStartTime)
}


export default function EventDetail() {
  const categories = useSelector(state => state.slateCategories)

  const [accessToken, setAccessToken] = useState(null)
  const [event, setEvent] = useState(null)
  const [channelName, setChannelName] = useState(null)
  const [startTime, setStartTime] = useState(new Date())
  const [endTime, setEndTime] = useState(new Date())
  const [programStartTime, setProgramStartTime] = useState(new Date())
  const [programEndTime, setProgramEndTime] = useState(new Date())
  const [autoSchedule, setAutoSchedule] = useState(false)
  const [gamePk, setGamePk] = useState(null)
  const [thumbnail, setThumbnail] = useState(null)
  const [thumbnailUrl, setThumbnailUrl] = useState(null)
  const [displayThumbnail, setDisplayThumbnail] = useState(false)
  const [tags, setTags] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [activeSlate, setActiveSlate] = useState(null)
  const [gain, setGain] = useState(null)
  const [eventType, setEventType] = useState(null)
  const [channelParams, setChannelParams] = useState({})
  const [playbackUrl, setPlaybackUrl] = useState(null)
  const [isUpdatingInfo, setIsUpdatingInfo] = useState(false)
  const [isEventActionUpdating, setIsEventActionUpdating] = useState(false)
  const [isStatusUpdating, setIsStatusUpdating] = useState(false)
  const [isRepublishToForgeUpdating, setIsRepublishToForgeUpdating] = useState(false)
  const [isTogglingSlate, setIsTogglingSlate] = useState(false)
  const [isDeactivatingSched, setIsDeactivatingSched] = useState(false)
  const [isScheduled, setIsScheduled] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLive, setIsLive] = useState(false)
  const [isOver, setIsOver] = useState(false)
  const [isArchived, setIsArchived] = useState(false)

  const [startAdClicked, setStartAdClicked] = useState(false)
  const [stopAdClicked, setStopAdClicked] = useState(false)
  const [adBreakActive, setAdBreakActive] = useState(false)
  //convert to a Number with +
  const [adBreakDurationInSeconds, setAdBreakDurationInSeconds] = useState(+getCookie("adBreakDuration")
      || +settings.DEFAULT_ADHOC_AD_BREAK_DURATION_IN_SECONDS)

  // Local validation state
  const [invalidTags, setInvalidTags] = useState(false)
  const [sportIds, setSportIds] = useState([])

  const events = useSelector(state => state.events)
  const availableTags = useSelector(selectAvailableTags)
  const eventTypes = useSelector(state => state.eventTypes)
  const nodes = useSelector(state => state.nodes)
  const profiles = useSelector(state => state.profiles)
  const slates = useSelector(state => state.slates)
  const selectedEventTypeId = useSelector(state => state.selectedEventTypeId)
  const selectedNodeId = useSelector(state => state.selectedNodeId)
  const selectedProfileId = useSelector(state => state.selectedProfileId)
  const formValidationError = useSelector(state => state.formValidationError)
  const eventStatuses = useSelector(state => state.eventStatuses)
  const timeoutRef = useRef()

  const dispatch = useDispatch()
  const {eventId} = useParams()
  let history = useHistory()
  const playbackRef = useRef(null)
  const [timeLeftAdInMillis, setTimeLeftAdInMillis] = useState(0)
  const [timeLapsedInSeconds, setTimeLapsedInSeconds] = useState(0)
  const [hideTimer, setHideTimer] = useState(false)

  const handleAdBreak = async (e, duration, type) => {
    e.preventDefault()

    console.log(duration, type)

    if (type === SCTE_35_CUE_IN) {
      setStartAdClicked(false)
      setStopAdClicked(true)
      setTimeLeftAdInMillis(0)
      setTimeLapsedInSeconds(0)
      setHideTimer(true)
    } else {
      setStopAdClicked(false)
      setStartAdClicked(true)
      setTimeLeftAdInMillis((duration !== -1 ? (duration * 1000) : 0))
      setTimeLapsedInSeconds(0)
      setHideTimer(false)
    }
    try {
      await updateAdBreakAdhoc(event, duration, type)(dispatch)
    } catch (err) {
      if (type === SCTE_35_CUE_IN) {
        setStopAdClicked(true)
      } else {
        setStartAdClicked(true)
      }
      if (err instanceof CerebroError) {
        addErrorAlertWithAutoRemoval(`Failed to stop stream ${err.message}`)(dispatch)
      } else {
        addErrorAlertWithAutoRemoval('Something went wrong, could not stop the stream.')(dispatch)
      }
    }
  }

  const getTagOptions = type => {
    return availableTags.filter(tag => tag.type === type)
  }

  const validateTags = () => {
    const isValid = eventType?.minTagsRequired <= tags.length
      && tags.length <= eventType?.maxTagsAllowed

    if (!isValid) {
      addErrorAlertWithAutoRemoval("Please fix invalid tag selections")(dispatch)
      setInvalidTags(true)
    } else {
      setInvalidTags(false)
    }

    return isValid
  }

  const getSlateCategoryOptions = () => {
    if (isLive) {
      return categories.filter(c => c.code !== CategoryCodes.STARTING)
    } else if (!isOver) {
      return categories.filter(c => c.code === CategoryCodes.STARTING)
    }
    return categories
  }

  const updateSlate = async (slate, isActive) => {
    setIsTogglingSlate(true)

    try {
      if (isActive) {
        await applySlate(event, slate)(dispatch)
      } else {
        await liftSlate(event, slate)(dispatch)
      }
    } catch (err) {
      if (err instanceof CerebroError) {
        addErrorAlertWithAutoRemoval(err.message)(dispatch)
      } else {
        addErrorAlertWithAutoRemoval('Something went wrong,'
          + ' could not operate on event slate.')(dispatch)
      }
    } finally {
      setIsTogglingSlate(false)
    }
  }

  const handleAdjustGain = async (e, value) => {
    try {
      await adjustGain(event, value)(dispatch)
    } catch (err) {
      console.log(`Could not adjust audio gain ${err}`)
      addErrorAlertWithAutoRemoval(`Could not adjust gain to ${value}`)(dispatch)
    }
  }

  const handleThumbnailRm = () => {
    setThumbnail(null)
    setDisplayThumbnail(false)
  }

  const handleStartStream = async () => {
    setIsEventActionUpdating(true)

    try {
      await postEventStreamAction(event, EventStreamAction.START)
    } catch (err) {
      console.log(`Could not start the stream ${err}`)

      if (err instanceof CerebroError) {
        addErrorAlertWithAutoRemoval(`Failed to start stream; ${err.message}`)(dispatch)
      } else {
        addErrorAlertWithAutoRemoval('Something went wrong, could not start the stream.')(dispatch)
      }
    } finally {
      setIsEventActionUpdating(false)
    }
  }

  const handleStopStream = async () => {
    setIsEventActionUpdating(true)

    try {
      await postEventStreamAction(event, EventStreamAction.STOP)
    } catch (err) {
      console.log(`Could not stop the stream ${err}`)

      if (err instanceof CerebroError) {
        addErrorAlertWithAutoRemoval(`Failed to stop stream; ${err.message}`)(dispatch)
      } else {
        addErrorAlertWithAutoRemoval('Something went wrong, could not stop the stream.')(dispatch)
      }
    } finally {
      setIsEventActionUpdating(false)
    }
  }

  const handleUpdateStatus = async () => {
    setIsStatusUpdating(true)

    let eventStatus = eventStatuses.find(s => s.name === EVENT_STATUS.LIVE)
    try {
      await updateStatus(event, eventStatus)(dispatch)
    } catch (err) {
      console.log(`Could not stop update status to ${eventStatus?.name} ${err}`)

      if (err instanceof CerebroError) {
        addErrorAlertWithAutoRemoval(`Failed to stop stream; ${err.message}`)(dispatch)
      } else {
        addErrorAlertWithAutoRemoval('Something went wrong, could not stop the stream.')(dispatch)
      }
    } finally {
      setIsStatusUpdating(false)
    }
  }

  const handleRepublishToForge = async () => {
    setIsRepublishToForgeUpdating(true)

    try {
      await republishToForge(event)
      addInfoAlertWithAutoRemoval("Successfully published event to Forge.")(dispatch)
    } catch (err) {
      console.log(`Could not republish to forge ${err}`)

      if (err instanceof CerebroError) {
        addErrorAlertWithAutoRemoval(`Failed to republish to forge; ${err.message}`)(dispatch)
      } else {
        addErrorAlertWithAutoRemoval('Something went wrong, could not republish to forge.')(dispatch)
      }
    } finally {
      setIsRepublishToForgeUpdating(false)
    }
  }


  const handleArchiveEvent = async () => {
    setIsEventActionUpdating(true)

    try {
      await archiveEvent(event)(dispatch)
    } catch (err) {
      addErrorAlertWithAutoRemoval(err.message)(dispatch)
    } finally {
      setIsEventActionUpdating(false)
    }
  }

  const handleDeactivateSchedule = async () => {
    setIsDeactivatingSched(true)

    try {
      await deactivateEventSchedule(event.id)(dispatch)
    } catch (err) {
      addErrorAlertWithAutoRemoval(err.message)(dispatch)
    } finally {
      setIsDeactivatingSched(false)
    }
  }

  const handleStartTimeChange = newStartTime => {
    // If the start date changed, reset the game selection
    if (moment(startTime).diff(moment(newStartTime), 'days')) {
      setGamePk(null)
    }
    setStartTime(newStartTime)
  }

  const handleGameChange = (object, actionType) => {
    if (actionType.action === 'select-option') {
      setGamePk(object?.game?.gamePk)
    } else if (actionType.action === 'clear') {
      setGamePk(null)
    }
  }

  const handleTypeChange = option => {
    dispatch(setSelectedEventTypeId(option.value))
    dispatch(setEventTypeNotSelected(false))
  }

  const handleNodeChange = option => {
    dispatch(setSelectedNodeId(option.value))
    dispatch(setNodeNotSelected(false))
  }

  const handleProfileChange = option => {
    dispatch(setSelectedProfileId(option.value))
    dispatch(setProfileNotSelected(false))
  }

  const handleCloneEvent = e => {
    e.preventDefault()
    const tempEvent = {...event, startTime: new Date(event.startTime)}
    const templates = event.type.channelParamTemplates
    const channelParams = Object.entries(event.channelParams)
      .reduce((params, [k, v]) => {
        const defaultValue = generateFromTemplate(tempEvent, templates[k])
        params[k] = defaultValue ? defaultValue : v

        return params
      }, {})
    const cloneEvent = {
      ...event,
      blurb: `${event.blurb} [CLONE]`,
      title: `${event.title} [CLONE]`,
      channelParams
    }

    history.push('/create', {event: cloneEvent})
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (isLive || isUpdatingInfo || isEventActionUpdating) {
      return
    }

    setIsUpdatingInfo(true)
    const blurb = e.target.blurb?.value
    const title = e.target.title?.value
    const description = e.target.description?.value

    const type = eventTypes.find(type => type.id === selectedEventTypeId)
    const profile = profiles.find(profile => profile.id === selectedProfileId)
    const params = Object.keys(profile.params)
      .reduce((extractedParams, key) => {
        if (key === CHANNEL_PARAM_NAMES.slateUrl) {
          extractedParams[key] = activeSlate?.uri
        } else if (e.target[key]) {
          extractedParams[key] = e.target[key].value.trim()
        }
        return extractedParams
      }, channelParams)

    let newEvent = {
      ...event,
      blurb,
      title,
      description,
      startTime, endTime,
      programStartTime, programEndTime,
      autoScheduled: autoSchedule,
      gamePk,
      tags: tags.map(tag => tag.value),
      type,
      nodeId: selectedNodeId,
      profile,
      channelParams: params
    }
    // A 'SCHEDULED' status will cause all validation to run
    const valid = validate(newEvent, EVENT_STATUS.SCHEDULED)(dispatch)
      && validateTags()

    if (!valid) {
      setIsUpdatingInfo(false)
      return
    }

    let thumbnailUrl = event?.thumbnailUrl
    if (thumbnail) {
      try {
        thumbnailUrl = await uploadImage(thumbnail)(dispatch)
      } catch (err) {
        addWarnAlertWithAutoRemoval(err.message)(dispatch)
      }
    }
    newEvent = {...newEvent, thumbnailUrl}

    try {
      await updateChannel(newEvent)
      await replaceEvent(newEvent)(dispatch)
      addInfoAlertWithAutoRemoval(`Event '${title}' was updated successfully.`)(dispatch)
    } catch (err) {
      console.log(`Could not update event information ${err}`)
      if (err instanceof CerebroError) {
        addErrorAlertWithAutoRemoval(err.message)(dispatch)
      } else {
        addErrorAlertWithAutoRemoval("There was a problem updating the event information. Please try again.")(dispatch)
      }
    } finally {
      setIsUpdatingInfo(false)
    }
  }

  useEffect(() => {
    fetchSlates()(dispatch)
    fetchSlateCategories()(dispatch)
    fetchEventTypes()(dispatch)
    fetchAdhocProfiles()(dispatch)
    fetchNodes()(dispatch)
    fetchTags()(dispatch)
    fetchEventStatuses()(dispatch)
    resetValidation()(dispatch)

    return function cleanup() {
      resetValidation()(dispatch)
      dispatch(setSelectedEventTypeId(null))
      dispatch(setSelectedProfileId(null))
      dispatch(setSelectedNodeId(null))
    }
  }, [dispatch])

  useEffect(() => {
    const setToken = async () => setAccessToken(await fetchToken())
    setToken()
  }, [accessToken])

  useEffect(() => {
    const fetchSavedEvent = async eventId => {
      // Attempt to get the event from state, otherwise fallback to fetch
      const event = events.find(event => event.id === id)
        ?? await fetchEvent(eventId)(dispatch)
      setEvent(event)
    }

    const id = parseInt(eventId)
    if (id) {
      fetchSavedEvent(id)
    }
  }, [dispatch, events, eventId])

  useEffect(() => {
    if (!event)
      return

    const updatePlaybackUrl = async () => {
      // update playback url whenever the event changes
      setPlaybackUrl(await fetchPlaybackUrl(event))
    }

    setChannelName(event.title)
    setStartTime(new Date(event.startTime))
    setEndTime(new Date(event.endTime))
    setProgramStartTime(new Date(event.programStartTime))
    setProgramEndTime(new Date(event.programEndTime))
    setAutoSchedule(event.autoScheduled)
    setGamePk(event.gamePk)
    setThumbnailUrl(event.thumbnailUrl)
    setDisplayThumbnail(true)
    setActiveSlate(event.activeSlate)
    setGain(event.gain == null ? LOWEST_GAIN_LEVEL : event.gain)
    setChannelParams(event.channelParams)
    dispatch(setSelectedEventTypeId(event?.type?.id))
    dispatch(setSelectedNodeId(event.nodeId))
    dispatch(setSelectedProfileId(event?.profile?.id))
    setIsScheduled(event.status?.name === EVENT_STATUS.SCHEDULED)
    setIsError(event.status?.name === EVENT_STATUS.ERROR)
    setIsLive(event.status?.name === EVENT_STATUS.LIVE)
    setIsOver(event.status?.name === EVENT_STATUS.ENDED
              || event.status?.name === EVENT_STATUS.ARCHIVED)
    setIsArchived(event.status?.name === EVENT_STATUS.ARCHIVED)
    updatePlaybackUrl()
  }, [dispatch, event])

  useEffect(() => {
    if (event && availableTags) {
      setTags(availableTags.filter(tag =>
        event.tags.includes(tag.value)))
    }
  }, [event, availableTags])

  useEffect(() => {
    const eventType = eventTypes?.find(e => e.id === selectedEventTypeId)
    setEventType(eventType)
  }, [eventTypes, selectedEventTypeId])

  useEffect(() => {
    if (!event || !availableTags || !eventType)
      return

    // If the event type changes, clear the tags and assign the defaults.
    // If the event type changes back to the original (in an unsaved state),
    // restore the original event tags.
    if (event.type.id !== eventType.id) {
      setTags(availableTags.filter(tag =>
        eventType.defaultTags.includes(tag.value)))
    } else {
      setTags(availableTags.filter(tag =>
        event.tags.includes(tag.value)))
    }
  }, [event, availableTags, eventType])

  useEffect(() => {
    const profile = profiles.find(profile => profile.id === selectedProfileId)

    if (profile) {
      const channelParams = Object.keys(profile.params)
        .reduce((accumulator, key) => {
          accumulator[key] = event?.channelParams?.[key] ?? 'None'
          return accumulator
        }, {})
      setChannelParams(channelParams)
    } else {
      setChannelParams(event?.channelParams ?? {})
    }
  }, [event, profiles, selectedProfileId])

  useEffect(() => {
    if (!autoSchedule && !programStartTime) {
      setProgramStartTime(startTime)
    }
  }, [startTime, programStartTime, autoSchedule])

  useEffect(() => {
    if (!event)
      return
    let fetchSportIdFromGame = async event => {
      let gameData = await fetchGameData(event.gamePk, event.startTime)
      setSportIds([gameData?.teams?.away?.team?.sport?.id])
    }
    if (event?.gamePk) {
      fetchSportIdFromGame(event)
    }
  }, [event])

  useEffect(() => {
    if (!autoSchedule && !programEndTime) {
      setProgramEndTime(endTime)
    }
  }, [programEndTime, endTime, autoSchedule])

  useEffect(() => {
    // Update the default dropdown when categories or event state changes
    if (!isLive && !isOver)
      setSelectedCategory(categories.find(c => c.code === CategoryCodes.STARTING))
    else
      setSelectedCategory(categories.find(c => c.code === CategoryCodes.DEFAULT))
    //eventually we will have a status field publishedInForge and conductorIsLive but until then we can refresh
    // the page like this
  }, [categories, isLive, isOver, isRepublishToForgeUpdating])


  useEffect(() => {
    if (!event)
      return

    if (adBreakInactive(event)) {
      setAdBreakActive(false)
    } else if (adBreakIsActive(event)) {
      setAdBreakActive(true)
      const timeLapsed = secondsAdRunningFor(event.adBreakStartTime)
      setTimeLapsedInSeconds(timeLapsed)
      if (event.adBreakDurationInSeconds !== null) {
        const timeLeftInAd = event.adBreakDurationInSeconds - timeLapsed
        setTimeLeftAdInMillis(timeLeftInAd * 1000)
        timeoutRef.current = setTimeout(() => {
          setAdBreakActive(false)
        }, timeLeftInAd * 1000)
      } else {
        setTimeLeftAdInMillis(0)
      }
    }

    if (startAdClicked && adBreakIsActive(event)) {
      setStartAdClicked(false)
    }
    if (stopAdClicked && adBreakInactive(event)) {
      setStopAdClicked(false)
    }
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [event, startAdClicked, stopAdClicked])

  const {
    invalidMetadata, invalidStreamTime,
    invalidProgramTime, invalidChannelParams
  } = formValidationError
  let defaultTitle, defaultBlurb, defaultDescription
  let publicUrl, forgeUrl, status
  if (event) {
    defaultTitle = event.title
    defaultBlurb = event.blurb
    defaultDescription = event.description
    publicUrl = event.publicUrl
    forgeUrl = event.forgeUrl
    status = event.status
  }

  let streamTimeClassName = invalidStreamTime
    ? `${styles.invalidDatetime} ${styles.datetimeSelector}`
    : styles.datetimeSelector
  let pgmTimeClassName = invalidProgramTime
    ? `${styles.invalidDatetime} ${styles.datetimeSelector}`
    : styles.datetimeSelector
  if (isLive || isOver) {
    streamTimeClassName = styles.datetimeSelectorDisabled
    pgmTimeClassName = styles.datetimeSelectorDisabled
  }
  const scheduleContent = (
    <div className={styles.scheduleContainer}>
      <div className={styles.datetimeContainer}>
        <label className={styles.label}>Stream Start & End Time</label>
        <div className={styles.datetimeGroup}>
          <DateTimePicker className={streamTimeClassName}
                          calendarClassName={styles.datetimeCalendar}
                          disableClock={true}
                          disabled={isLive || isOver}
                          onChange={handleStartTimeChange}
                          value={startTime}/>
          <DateTimePicker className={streamTimeClassName}
                          calendarClassName={styles.datetimeCalendar}
                          disableClock={true}
                          disabled={isLive || isOver}
                          onChange={setEndTime}
                          value={endTime}/>
        </div>
      </div>
      <div className={styles.datetimeContainer}>
        <label className={styles.label}
          style={!autoSchedule ? {color: '#AAAAAA'} : {}}>
          Program Start & End Time
        </label>
        <div className={styles.datetimeGroup}>
          <DateTimePicker className={pgmTimeClassName}
                          calendarClassName={styles.datetimeCalendar}
                          disableClock={true}
                          disabled={isLive || isOver || !autoSchedule}
                          onChange={setProgramStartTime}
                          value={programStartTime}/>
          <DateTimePicker className={pgmTimeClassName}
                          calendarClassName={styles.datetimeCalendar}
                          disableClock={true}
                          disabled={isLive || isOver || !autoSchedule}
                          onChange={setProgramEndTime}
                          value={programEndTime}/>
        </div>
      </div>
      <div className={isLive ? styles.hidden : styles.inputGroupCheckbox}>
        <input id="autoScheduled" type="checkbox" checked={autoSchedule}
          onChange={() => setAutoSchedule(!autoSchedule)} disabled={isOver}/>
        <label htmlFor="autoScheduled">Auto Schedule</label>
      </div>
    </div>
  )
  const UpdateStatus = () => {
    return <ButtonWithPopup
        className={styles.updateStatusButton}
        handleClick={() => handleUpdateStatus()}
        btnText={
          isStatusUpdating
              ? <BeatLoader size={8} color={"#FFFFFF"} loading={isStatusUpdating} />
              : 'Override Event to Live'
        }
        popupMsg="You are about to override the event status to LIVE. Would you like to proceed?"
    />
  }

  const RepublishToForgeButton = () => {
    return <ButtonWithPopup
        className={styles.updateStatusButton}
        handleClick={() => handleRepublishToForge()}
        btnText={
          isStatusUpdating
              ? <BeatLoader size={8} color={"#FFFFFF"} loading={isRepublishToForgeUpdating} />
              : 'Republish to Forge'
        }
        popupMsg="You are about to republish the event to Forge. Would you like to proceed?"
    />
  }

  const stopBtn = (
    <ButtonWithPopup
      className={styles.stopStreamButton}
      handleClick={handleStopStream}
      btnText={
        isEventActionUpdating
          ? <BeatLoader size={8} color={"#FFFFFF"} loading={isEventActionUpdating} />
          : 'Stop Stream'
      }
      popupMsg="You are about to stop the stream for this event. Would you like to proceed?"
    />
  )
  const startBtn = (
    <ButtonWithPopup
      className={styles.startStreamButton}
      handleClick={handleStartStream}
      btnText={
        isEventActionUpdating
          ? <BeatLoader size={8} color={"#FFFFFF"} loading={isEventActionUpdating} />
          : 'Start Stream'
      }
      popupMsg="You are about to start the stream for this event. Would you like to proceed?"/>
  )
  const archiveBtn = (
    <ButtonWithPopup
      className={styles.archiveButton}
      handleClick={handleArchiveEvent}
      btnText={
        isEventActionUpdating
          ? <BeatLoader size={8} color={"#FFFFFF"} loading={isEventActionUpdating} />
          : 'Archive'
      }
      popupMsg={
        <span>
          You are about to archive this event. The resources associated with
          the event will be removed, however, the event will still be
          accessible through the event summary screen. <strong>This action
          cannot be reversed</strong>.
        </span>
      }/>
  )
  const deactivateScheduleBtn = (
    <ButtonWithPopup
      className={styles.deactivateScheduleButton}
      handleClick={handleDeactivateSchedule}
      btnText={
        isDeactivatingSched
          ? <BeatLoader size={8} color={"#FFFFFF"} loading={isDeactivatingSched} />
          : 'Manual Mode'
      }
      popupMsg={
        <span>
          You are about to deactivate Cerebro's automated event management system.
          This will prevent Cerebro from automatically slating at the beginning
          and end of the program. Additionally, Cerebro will not automatically
          stop the Conductor channel at the end of the specified "Stream Time."
          <br/><br/>
          <strong>
            This action will require manual intervention for the rest of the
            event and cannot be reversed.
          </strong>
        </span>
      }/>
  )

  let updateInfoBtn, liftSlateBtn, thumbnailContent
  let playerContent, autoScheduleBanner
  if (isLive) {
    if (activeSlate) {
      liftSlateBtn = (
        <ButtonWithPopup
          className={styles.liftSlateButton}
          handleClick={() => updateSlate(activeSlate, false)}
          btnText={
            isTogglingSlate
              ? <BeatLoader size={8} color={"#FFFFFF"} loading={isTogglingSlate} />
              : 'Lift Slate'
          }
          popupTitle='Deactivate Slate'
          popupMsg='Are you sure you want to remove the following slate from this event?'
          previewImg={activeSlate.thumbnail}
        />
      )
    }

    if (displayThumbnail) {
      thumbnailContent = (
        <div className={styles.thumbnailContainer}>
          <img src={thumbnailUrl} alt="event thumbnail"
               className={styles.thumbnail} />
        </div>
      )
    }

    if (autoSchedule) {
      autoScheduleBanner = (
        <div className={styles.autoScheduleBanner}>
          <div className={styles.autoScheduleBannerIcon}>
            <img src={InfoSymbol} alt="info" width="18px"/>
          </div>
          <p>This event is being managed automatically by Cerebro</p>
        </div>
      )
    }
  } else {
    if (!isOver) {
      playerContent = 'Streaming has not started.'
    }
    updateInfoBtn = (
      <button className={styles.submitButton}>
        {
          isUpdatingInfo
            ? <BeatLoader size={8} color={"#FFFFFF"} loading={isUpdatingInfo} />
            : 'Update Information'
        }
      </button>
    )
    if (displayThumbnail) {
      thumbnailContent = (
        <div className={styles.thumbnailContainer}>
          <div>
            <img src={thumbnailUrl} alt="event thumbnail"
                 className={styles.thumbnail} />
          </div>
          <div>
            <img src={RemoveSymbol} alt="remove thumbnail"
                 className={styles.thumbnailRemoveIcon}
                 onClick={handleThumbnailRm} />
          </div>
        </div>
      )
    } else {
      thumbnailContent = (
        <ImageUploader
          withIcon={true}
          buttonText='Choose image'
          onChange={thumbnails => setThumbnail(thumbnails[0])}
          imgExtension={['.jpg', '.png']}
          label={'Max file size: 5mb, accepted: jpg | png'}
          maxFileSize={5242880}
          withPreview={true}
          singleImage={true}
        />
      )
    }
  }

  const generateStatusContent = status => {
    if (!status)
      return

    let streamActionBtn
    if (isLive) {
      streamActionBtn = stopBtn
    } else if (isOver) {
      streamActionBtn = !isArchived && archiveBtn
    } else if(isScheduled) {
      streamActionBtn = startBtn
    }

    const autoScheduleNote = `This event is being managed automatically \
      by Cerebro.`

    return (
      <div className={`${styles.statusContainer} ${styles.labelContainer}`}>
        Status
        <div className={styles.status}>
          Current status: <Status className={styles.statusText} status={status}/>
        </div>
        {isLive && autoSchedule &&
          <div className={styles.autoScheduleNote}>{autoScheduleNote}</div>
        }
        <div className={styles.statusActionsContainer}>
          {streamActionBtn}
          {(isScheduled || isError) && <UpdateStatus/>}
          {event?.profile?.forgeEvent && (isScheduled || isLive) && <RepublishToForgeButton/>}

          {isLive && autoSchedule && deactivateScheduleBtn}
        </div>
      </div>
    )
  }
  const paramTemplates = eventType?.channelParamTemplates ?? {}

  if (isLive || isOver) {
    playerContent = <HlsPlayer url={playbackUrl} playing={!isOver} retryLimit={45} accessToken={accessToken} forceLive={false} reference={playbackRef}/>
  }

  const handleSyncFeeds = e => {
    e.preventDefault()
    if (playbackRef.current) {
      playbackRef.current.seekTo(playbackRef.current.getDuration() - 1)
    }
  }

  return (
    <div>
      <NavBar center={liftSlateBtn}/>
      <div className={styles.playerContainer}>
        {playerContent}
      </div>
      {autoScheduleBanner}
      {isLive && (
          <button
              type='button'
              className={`${styles.syncFeedsButton}`}
              onClick={handleSyncFeeds}
          >
            Sync Feed
          </button>
      )}

      <form onSubmit={handleSubmit}>
        <div className={styles.contentContainer}>
          <div className={styles.contentColumnLeft}>
            <EditableInput label="Blurb" name="blurb" defaultValue={defaultBlurb}
              maxLength={53} disabled={isLive} required
              invalid={!!invalidMetadata['blurb']} description={invalidMetadata['blurb']}/>
            <EditableInput label="Title" name="title" defaultValue={defaultTitle}
              minLength={3} maxLength={33} disabled={isLive} required
              invalid={!!invalidMetadata['title']} description={invalidMetadata['title']}
              onChange={e => setChannelName(e.target.value)}/>
            <EditableInput label="Description" name="description" defaultValue={defaultDescription}
              maxLength={140} disabled={isLive} required
              invalid={!!invalidMetadata['description']} description={invalidMetadata['description']}/>
            {scheduleContent}
            <FormGroup className={styles.inputGroup}>
              <LeagueDropdown
                  sportIdsSelected={sportIds}
                  supportedSportIds={[1, 17]}
                  disabled={true}
              />
            </FormGroup>
            <FormGroup className={styles.inputGroup}>
              <NextGenGameDropdown
                  onChange={handleGameChange}
                  gamePk={gamePk}
                  sportIds={sportIds}
                  startDate={startTime}
                  disabled={true}
              />
            </FormGroup>

            <div className={styles.labelContainer} style={!publicUrl ? {display: "none"} : {}}>
              External Links
              {publicUrl && <a className={styles.url} href={publicUrl} target="_blank" rel="noopener noreferrer">MLB</a>}
              {forgeUrl && <a className={styles.url} href={forgeUrl} target="_blank" rel="noopener noreferrer">Forge</a>}
            </div>
            <div className={styles.labelContainer}>
              Thumbnail
              {thumbnailContent}
            </div>
            <TagsDropdown options={getTagOptions(eventType?.tagType)}
              placeholder="Add new tags"
              value={tags} isMulti={true}
              minTagsRequired={eventType?.minTagsRequired}
              maxTagsAllowed={eventType?.maxTagsAllowed}
              onChange={options => setTags(options || [])}
              showError={invalidTags}
              isDisabled={isLive}
            />
            {generateStatusContent(status)}
          </div>
          <div className={styles.contentColumnRight}>
            {isLive &&
                <Collapsible defaultOpen={true} label="Ad Break Controls">
                  <div className={styles.contentColumnRightItem}>
                    <AdBreakButtons
                        handleAdBreak={handleAdBreak}
                        adBreakActive={adBreakActive}
                        startAdClicked={startAdClicked}
                        adBreakDurationInSeconds={adBreakDurationInSeconds}
                        setAdBreakDurationInSeconds={setAdBreakDurationInSeconds}
                        stopAdClicked={stopAdClicked}
                        timeLeftAdInMillis={timeLeftAdInMillis}
                        timeLapsedInSeconds={timeLapsedInSeconds}
                        setHideTimer={setHideTimer}
                        hideTimer={hideTimer}
                    />
                  </div>
                </Collapsible>
            }
            <Collapsible defaultOpen={true} label={"Available Slates"}>
              <div className={styles.contentColumnRightItem}>
                <Select
                    options={getSlateCategoryOptions()} value={selectedCategory}
                    getOptionValue={o => o.id} getOptionLabel={o => o.name}
                    placeholder="Select a Slate Category" isClearable={false}
                    onChange={option => setSelectedCategory(option || null)}
                />
                <div className={styles.slateContainer} style={{paddingBottom: '15px'}}>
                  {
                    slates
                        .filter(slate => slate?.category?.code === selectedCategory?.code)
                        .map(slate => {
                          return (
                              <Slate
                                  key={slate.id}
                                  slate={slate}
                                  active={activeSlate?.id === slate.id}
                                  disabled={isOver}
                                  popup={isLive}
                                  handleClick={(slate, active) => {
                                    if (isLive) {
                                      // Flip the slate active status to apply a new slate
                                      // or lift the currently active slate
                                      updateSlate(slate, !active)
                                    } else {
                                      setActiveSlate(slate)
                                    }
                                  }}/>
                          )
                        })
                  }
                </div>
              </div>
            </Collapsible>

            <Collapsible label="Audio Controls">
              <AudioPanel disabled={!isLive} value={gain}
                          handleChange={(e, val) => setGain(val)}
                          handleCommittedChange={handleAdjustGain}/>
            </Collapsible>
            <Collapsible label="Elemental Parameters">
              <div className={styles.labelContainer}>
                Type
                <Dropdown
                    placeholder="Select a type"
                    options={eventTypes.map(e => ({"value": e.id, "label": e.name}))}
                    value={eventTypes.find(e => e.id === selectedEventTypeId)?.name}
                    onChange={handleTypeChange}
                    className={styles.dropdown}
                    disabled={isLive || isOver}
                />
              </div>
              <div className={styles.labelContainer}>
                Node
                <Dropdown
                    placeholder="Select a node"
                    options={nodes.map(n => ({"value": n.id, "label": n.hostname}))}
                    value={nodes.find(n => n.id === selectedNodeId)?.hostname}
                    onChange={handleNodeChange}
                    className={styles.dropdown}
                    disabled={isLive || isOver}
                />
              </div>
              <div className={styles.labelContainer}>
                Profile
                <Dropdown
                    placeholder="Select a profile"
                    options={profiles.map(p => ({"value": p.id, "label": p.displayName}))}
                    value={profiles.find(p => p.id === selectedProfileId)?.displayName}
                    onChange={handleProfileChange}
                    className={styles.dropdown}
                    disabled={isLive || isOver}
                />
              </div>
              {
                Object.entries(channelParams)
                    .filter(([key]) => !skipAutoDisplay(key))
                    .map(([key, value]) => {
                      const tempEvent = {
                        type: eventType, title: channelName, startTime: new Date(startTime)
                      }
                      const defaultParamValue = generateFromTemplate(tempEvent, paramTemplates[key])
                      return (
                          <ChannelParamEditable key={getKey(key, tempEvent)}
                                                name={key} disabled={isLive || isOver}
                                                invalidReason={invalidChannelParams[key]}
                                                value={isScheduled && defaultParamValue ? defaultParamValue : value}
                          />
                      )
                    })
              }
            </Collapsible>
          </div>
        </div>
        <div className={styles.bottomActionContainer}>
          <button type='button' className={styles.cloneEventButton}
                  onClick={handleCloneEvent}>
            Clone Event
          </button>
          {updateInfoBtn}
        </div>
      </form>
    </div>
  )
}

import moment from 'moment'
import settings from '../settings'
import {convertToEastCoastDate, dateEquals} from "../utils/date-utils"


const BASE_SCHEDULE_URL = `${settings.STATS_API_ROOT}/api/v1/schedule`

export const fetchGamesByDate = async (date, sportId) => {
    if (!sportId) {
        throw new Error("sportId is required to fetch game schedule")
    }
    const url = date ? new URL(`${BASE_SCHEDULE_URL}?sportId=${sportId}&date=${moment(date)
        .format('YYYY-MM-DD')}&hydrate=team(sport)`) : new URL(BASE_SCHEDULE_URL)
    let schedule = await fetchSchedule(url)

    // This logic is to transform Winter League sport ids to LIDOM, since LIDOM is not a sport id this is needed
    return schedule.filter(game =>
        game?.teams?.away?.team?.sport?.id === 17 ?
            game?.teams?.away?.team?.league?.id === 131 : true)
}

const fetchSchedule = async (url) => {
    console.log(`Fetching schedule from Stats API`)
    const resp = await fetch(url)
    const json = await resp.json()

    if (!resp.ok) {
        console.error(`Error Retrieving Game Info from Stats api ${url}`)
        console.error(json)
        throw new Error("Error Retrieving Game Info from Stats api")
    }

    return flattenGameDates(json?.dates)
        .sort((gx, gy) => new Date(gx.gameDate).getTime()
            - new Date(gy.gameDate).getTime())
}

// Flattens dates array into an array of games
const flattenGameDates = dates => {
    return dates
        ? dates?.reduce((games, date) => games.concat(date?.games || []), [])
        : []
}

export const fetchLeagues = async leagues => {
    console.log(`Fetching Leagues from Stats API`)
    const url = `${settings.STATS_API_ROOT}/api/v1/sports`
    const resp = await fetch(url)
    const json = await resp.json()
    if (!resp.ok) {
        console.error(`Error Retrieving League Info from Stats api: ${url}`)
        throw new Error("Error Retrieving League Info from Stats api")
    }

    let supportedLeagues = json?.sports.filter(sport => leagues.includes(sport?.id))

    // This logic is to transform Winter League sport ids to LIDOM, since LIDOM is not a sport id this is needed
    return supportedLeagues.map(obj => {
        if (obj.id === 17) {
            return {...obj, code: 'lidom', name: 'Liga de Beisbol Dominicano', abbreviation: 'LIDOM', leagueId: 131};
        }

        return obj;
    });

}

export const fetchVenuesBySportId = async league => {
    console.log(`Fetching Venues from Stats API`)
    if (!league || league.length === 0) {
        throw new Error("League is required to fetch list of venues")
    }
    const url = `${settings.STATS_API_ROOT}/api/v1/teams?sportIds=${league}&fields=teams,venue,id,name,sport`

    const resp = await fetch(url)
    const json = await resp.json()

    if (!resp.ok) {
        console.error(`Error Retrieving Venue Info from Stats api : ${url}`)
        console.error(json)
        throw new Error("Error Retrieving Venue Info from Stats api")
    }

    const venues = json?.teams
        ?.filter(team => team?.venue)
        ?.map(team => (
            {
                "sportId": team.sport.id,
                "sportName": team.sport.name,
                "id": team.venue.id,
                "name": team.venue.name
            }))
    return venues ? venues : []
}

export const fetchGameData = async (gamePk, startDate) => {
    console.log(`Fetching Game ${gamePk} on ${startDate} from Stats API`)
    if (!gamePk) {
        throw new Error("Game Pk is required to fetch game data")
    }
    const url = `${settings.STATS_API_ROOT}/api/v1/schedule/?gamePk=${gamePk}&hydrate=team(sport)`
    const resp = await fetch(url)
    const json = await resp.json()

    const gameDateEasternTime = convertToEastCoastDate(startDate)
    if (resp.ok) {
        const gameData = json?.dates?.filter(dateGameIsPlayed =>
            dateEquals(new Date(dateGameIsPlayed?.date.replace(/(\d{4})-(\d{2})-(\d{2})/,
                "$2/$3/$1")), gameDateEasternTime))[0]?.games[0]
        if (!gameData || gameData.length === 0) {
            console.warn(`${gamePk} was not found in stats api on ${startDate}`)
        }
        return gameData
    }
    if (!resp.ok) {
        console.error(`Error Retrieving Game Info from Stats api: ${url}`)
        console.error(json)
        throw new Error("Error Retrieving Game Info from Stats api")
    }
}

export const fetchLatestGameData = async (gamePk) => {
    console.log(`Fetching Game latest ${gamePk} from Stats API`)
    if (!gamePk) {
        throw new Error("Game Pk is required to fetch game data")
    }
    const url = `${settings.STATS_API_ROOT}/api/v1/schedule/?gamePk=${gamePk}&useLatestGames=true`
    const resp = await fetch(url)
    const json = await resp.json()

    if (resp.ok) {
        const gameData = json?.dates[0]?.games[0]
        if (!gameData || gameData.length === 0) {
            console.warn(`${gamePk} was not found in stats api`)
        }
        return gameData
    }
    if (!resp.ok) {
        console.error(`Error Retrieving Game Info from Stats api: ${url}`)
        console.error(json)
        throw new Error("Error Retrieving Game Info from Stats api")
    }
}

export const fetchVenueData = async venueId => {
    console.log(`Fetching Venue ${venueId} from Stats API`)
    if (!venueId) {
        throw new Error("Venue Id is required to fetch venue data")
    }
    const url = `${settings.STATS_API_ROOT}/api/v1/venues?venueIds=${venueId}`
    const resp = await fetch(url)
    const json = await resp.json()
    if (resp.ok) {
        return json?.venues[0]
    }
    if (!resp.ok) {
        console.error(`Error Retrieving Venue Info from Stats api: ${url}`)
        console.error(json)
        throw new Error("Error Retrieving Venue Info from Stats api")
    }
}
export const fetchTeam = async teamId => {
    console.log(`Fetching Team ${teamId} from Stats API`)
    if (!teamId) {
        throw new Error("Team Id is required to fetch venue data")
    }
    const url = `${settings.STATS_API_ROOT}/api/v1/teams/${teamId}`
    const resp = await fetch(url)
    const json = await resp.json()
    if (resp.ok) {
        return json?.teams[0]
    }
    if (!resp.ok) {
        console.error(`Error Retrieving Team Info from Stats api: ${url}`)
        console.error(json)
        throw new Error("Error Retrieving Team Info from Stats api")
    }
}

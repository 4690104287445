const EPOCH_STRING = new Date(0).toISOString();
export const isEpochBeginning = (date) => {
    return new Date(date).toISOString() === EPOCH_STRING
}

export function convertToEastCoastDate(utcDate) {
    const localDate = new Date(utcDate)
    const newYorkTimeString = localDate.toLocaleString("en-US", {timeZone: "America/New_York"})
    return new Date(newYorkTimeString)
}

export function dateEquals(date, otherDate) {
    return date.getDate() === otherDate.getDate()
        && date.getMonth() === otherDate.getMonth()
        && date.getFullYear() === otherDate.getFullYear()
}

export function timeEquals(date, otherDate) {
    return date.getHours === otherDate.getHours
        && date.getMinutes === otherDate.getMinutes
        && date.getSeconds === otherDate.getSeconds
}

export function dateTimeEquals(date, otherDate) {
    return dateEquals(date, otherDate) && timeEquals(date, otherDate)
}


export function parseDateYYYYMMDD(date) {
    let parts = date.split('-');
    return new Date(parts[0], parts[1] - 1, parts[2]);
}
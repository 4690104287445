import {CerebroError} from '../errors'
import settings from '../settings'
import {withAuthHeader} from './auth'
import {addErrorAlertWithAutoRemoval, addWarnAlertWithAutoRemoval} from '../actions'
import {EVENT_STATUS} from '../utils'

// Convention: NOUN_VERB actions, verbNoun action creators
export const EVENTS_LOAD = 'EVENTS_LOAD'
export const EVENT_UPDATE = 'EVENT_UPDATE'
export const EVENT_REMOVE = 'EVENT_REMOVE'

export const loadEvents = events => ({
  type: EVENTS_LOAD,
  events
})
export const updateEvent = event => ({
  type: EVENT_UPDATE,
  event
})
export const removeEvent = event => ({
  type: EVENT_REMOVE,
  event
})

export const EventStreamAction = {
  START: 'start',
  STOP: 'stop'
}

// startDate and endDate are moments
export const fetchEvents = (startDate, endDate, types) => async dispatch => {
  console.log(`Fetching events from server ${startDate} - ${endDate}.`)
  try {
    if (!startDate || !endDate) {
      throw new Error(`startDate and endDate must be provided`)
    }

    let url = new URL(`${settings.API_ROOT}/api/v1/events`)
    const params = {
      startDate: startDate.clone().utc().format(),
      endDate: endDate.clone().utc().format(),
      eventType: types
    }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    const response = await fetch(url, await withAuthHeader())
    const json = await response.json()
    if (response.ok) {
      dispatch(loadEvents(json))
    } else {
      console.error(`Could not fetch events from backend: ${json.message}`)
      throw new Error(json.message)
    }
  } catch (err) {
    const errorMsg = `There was a problem fetching the events from the server.`
    console.error(`${errorMsg}: ${err}`)
    addErrorAlertWithAutoRemoval(errorMsg)(dispatch)
  }
}

export const fetchEvent = id => async dispatch => {
  console.log(`Fetching event ${id} from server.`)

  try {
    let url = new URL(`${settings.API_ROOT}/api/v1/events/${id}`)
    const response = await fetch(url, await withAuthHeader())
    const event = await response.json()

    if (response.status === 404) {
      addWarnAlertWithAutoRemoval(`Whoops! This event was not found.`)(dispatch)
    } else {
      dispatch(updateEvent(event))
      return event
    }
  } catch (err) {
    const errorMsg = `There was a problem fetching event ${id} from the server.`
    console.error(`${errorMsg}: ${err}`)
    addErrorAlertWithAutoRemoval(errorMsg)(dispatch)
  }
}

const saveEvent = async (method, event) => {
  let url = `${settings.API_ROOT}/api/v1/events`
  if (method === 'PUT') {
    url = `${url}/${event.id}`
  }

  try {
    const response = await fetch(url, await withAuthHeader({
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(event)
    }))

    const json = await response.json()
    if (!response.ok)
        throw new CerebroError(json.message)

    return json
  } catch (err) {
    console.error(`Failed to save event ${err}`)
    if (err instanceof CerebroError)
      throw err
    throw new CerebroError(`Could not save event ${event?.title}`)
  }
}

export const createEvent = event => async dispatch => {
  try {
    const createdEvent = await saveEvent('POST', event)
    dispatch(updateEvent(createdEvent))
    return createdEvent
  } catch (err) {
    console.error(`Failed to create event ${err}`)
    throw new CerebroError(`Failed to create event: ${err.message}`)
  }
}

export const replaceEvent = event => async dispatch => {
  try {
    const replacedEvent = await saveEvent('PUT', event)
    dispatch(updateEvent(replacedEvent))
    return replacedEvent
  } catch (err) {
    console.error(`Failed to replace event ${err}`)
    throw new CerebroError(`There was a problem updating the event. \
      Please try again.`)
  }
}

export const archiveEvent = event => async dispatch => {
  try {
    const url = settings.API_ROOT + `/api/v1/events/${event.id}`
    // Add partial content including status and required fields
    const body = {
      status: { name: EVENT_STATUS.ARCHIVED },
      type: event?.type
    }
    const response = await fetch(url, await withAuthHeader({
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    }))

    if (!response.ok) {
      const errorBody = await response.json()
      throw new CerebroError(errorBody.message)
    }
    const updatedEvent = await response.json()

    dispatch(updateEvent(updatedEvent))
    return updatedEvent
  } catch (err) {
    console.log(`Failed to archive event ${err}`)
    if (err instanceof CerebroError) {
      throw err
    }
    throw new CerebroError(`There was a problem archiving the event. \
      Please try again.`)
  }
}

export const deleteEvent = event => async dispatch => {
  try {
    const url = settings.API_ROOT + `/api/v1/events/${event.id}`
    const response = await fetch(url, await withAuthHeader({
      method: 'DELETE'
    }))

    if (!response.ok) {
      const errorBody = await response.json()
      throw new CerebroError(errorBody.message)
    }

    dispatch(removeEvent(event))
  } catch (err) {
    console.log(`Failed to delete event ${err}`)
    if (err instanceof CerebroError) {
      throw err
    }
    throw new CerebroError(`There was a problem deleting the event. \
      Please try again.`)
  }
}

export const deactivateEventSchedule = eventId => async dispatch => {
  const url = settings.API_ROOT + `/api/v1/events/${eventId}/schedule`
  try {
    const response = await fetch(url, await withAuthHeader({
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    }))

    if (!response.ok) {
      throw new CerebroError(`Could not deactivate Cerebro's automated \
        event management system`)
    }
    const updatedEvent = await response.json()

    dispatch(updateEvent(updatedEvent))
    return updatedEvent
  } catch (err) {
    console.error(`Failed to deactivate event schedule ${err}`)
    if (err instanceof CerebroError) {
      throw err
    }
    throw new CerebroError(`Could not deactivate Cerebro's automated \
      event management system`)
  }
}

export const applySlate = (event, slate) => async dispatch => {
  const { id, title } = event
  const url = `${settings.API_ROOT}/api/v1/events/${id}/slate/${slate.id}`

  try {
    const response = await fetch(url, await withAuthHeader({method: 'POST'}))

    if (!response.ok) {
      throw new CerebroError(`Cound not apply slate to event '${title}'`)
    }

    dispatch(updateEvent({...event, activeSlate: slate}))
  } catch (err) {
    console.error(`Error when applying slate to event ${id} ${err}`)
    throw err
  }
}

export const liftSlate = (event, slate) => async dispatch => {
  const { id, title } = event
  const url = `${settings.API_ROOT}/api/v1/events/${id}/slate/${slate.id}`

  try {
    const response = await fetch(url, await withAuthHeader({method: 'DELETE'}))

    if (!response.ok) {
      throw new CerebroError(`Cound not lift slate for event '${title}'`)
    }

    dispatch(updateEvent({...event, activeSlate: null}))
  } catch (err) {
    console.error(`Error when lifting slate from event ${id} ${err}`)
    throw err
  }
}

export const adjustGain = (event, gain) => async dispatch => {
  const { id } = event
  const url = `${settings.API_ROOT}/api/v1/events/${id}/channel/gain`
  const payload = { gain }

  try {
    const response = await fetch(url, await withAuthHeader({
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }))

    if (!response.ok) {
      const errorPayload = await response.json()
      throw new CerebroError(errorPayload.message)
    }

    const updatedEvent = await response.json()
    dispatch(updateEvent(updatedEvent))

    return updatedEvent
  } catch (err) {
    console.error(`Could not adjust gain to ${gain} for event ${id}`)
    throw err
  }
}

export const postEventStreamAction = async (event, action) => {
  const { id } = event
  const url = `${settings.API_ROOT}/api/v1/events/${id}/stream`
  const payload = { action: action }

  try {
    const response = await fetch(url, await withAuthHeader({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }))

    if (!response.ok) {
      const errorPayload = await response.json()
      throw new CerebroError(errorPayload.message)
    }

    return await response.json()
  } catch (err) {
    console.error(`Could not update event ${id} with action '${action}': ${err}`)
    throw err
  }
}

export const republishToForge = async event => {
  const {id} = event
  const url = `${settings.API_ROOT}/api/v1/events/${id}/republish`

  try {
    const response = await fetch(url, await withAuthHeader({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }))

    if (!response.ok) {
      const errorPayload = await response.json()
      throw new CerebroError(errorPayload.message)
    }

  } catch (err) {
    console.error(`Could not republish event ${id} to forge': ${err}`)
    throw err
  }
}

export const updateStatus = (event, status) => async dispatch => {
  const { id } = event
  const url = `${settings.API_ROOT}/api/v1/events/${id}`

  try {
    const response = await fetch(url, await withAuthHeader({
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        type: event.type,
       status: status
      })
    }))

    if (!response.ok) {
      const errorPayload = await response.json()
      throw new CerebroError(errorPayload.message)
    }

    dispatch(updateEvent({...event, status: status}))
  } catch (err) {
    console.error(`Could not update event ${id} with status '${status}': ${err}`)
    throw err
  }
}


export const fetchPlaybackUrl = async event => {
  const { id } = event
  const url = `${settings.API_ROOT}/api/v1/events/${id}/playback-url`

  try {
    const response = await fetch(url, await withAuthHeader())
    const body = await response.json()

    if (!response.ok) {
      throw new CerebroError(`Could not fetch playback url for event '${id}'`)
    }

    return body.url
  } catch (err) {
    console.error(`Couldn't fetch playback url for event ${id}: ${err}`)
    throw err
  }
}

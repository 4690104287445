import {combineReducers} from 'redux'
import {
  ADD_ALERT,
  FETCH_ADHOC_PROFILES,
  REMOVE_ALERT,
  SELECTED_PROFILE_ID,
  SET_END_TIME,
  SET_EVENT_STATUSES,
  SET_START_TIME,
  SET_STATUS_FILTER
} from '../actions'
import {
  EVENT_TYPE_NOT_SELECTED_SET,
  INVALID_CHANNEL_PARAMS_SET,
  INVALID_METADATA_SET,
  INVALID_PGM_TIME_SET,
  INVALID_STREAM_TIME_SET,
  NODE_NOT_SELECTED_SET,
  PROFILE_NOT_SELECTED_SET
} from '../actions/validation'
import {TAGS_LOAD} from '../actions/tags'
import {EVENT_REMOVE, EVENT_UPDATE, EVENTS_LOAD} from '../actions/events'
import {
  GAME_ASSET_UPDATE,
  GAME_ASSET_UPDATE_PRIMARY,
  GAME_ASSETS_LOAD,
  GAME_REMOVE,
  GAME_UPDATE,
  LEAGUES_LOAD,
  PROVISION_EVENT_UPDATE,
  PROVISION_EVENTS_LOAD,
  VENUES_LOAD
} from '../actions/games'

import {EVENT_TYPE_ID_SELECT, EVENT_TYPES_LOAD} from '../actions/event-types'
import {FETCHED_NODES, SET_SELECTED_NODE_ID} from '../actions/CreateEvent'
import {FETCH_SLATE_CATEGORIES, FETCH_SLATES, FETCH_YODA_SLATES} from '../actions/slates'
import {EVENT_STATUS, gameEquals} from "../utils"


export const events = (state = [], action) => {
  switch (action.type) {
    case EVENTS_LOAD:
      return action.events
    case EVENT_UPDATE:
      return state.find(event => event.id === action.event.id)
        ? state.map(event =>
          event.id === action.event.id ? action.event : event)
        : [...state, action.event]
    case EVENT_REMOVE:
      return state.filter(event => event.id !== action.event.id)
    default:
      return state
  }
}

export const games = (state = [], action) => {
	switch (action.type) {
		case GAME_UPDATE:
			return state.find(game => gameEquals(game, action.game))
				? state.map(game =>
					gameEquals(game, action.game) ? {...game, ...action.game} : game)
				: [...state, action.game]
        case GAME_REMOVE:
            return state.filter(game => !gameEquals(game, action.game))
		default:
			return state
	}
}

export const provisionEvents = (state = [], action) => {
  switch (action.type) {
    case PROVISION_EVENTS_LOAD:
      return action.provisionEvents
    case PROVISION_EVENT_UPDATE:
      return state.find(provisionEvent => provisionEvent.gamePk === action.provisionEvent.gamePk
          && provisionEvent.feedType === action.provisionEvent.feedType)
          ? state.map(provisionEvent =>
              provisionEvent.gamePk === action.provisionEvent.gamePk
              && provisionEvent.feedType === action.provisionEvent.feedType ? action.provisionEvent : provisionEvent)
          : [...state, action.provisionEvent]
    default:
      return state
  }
}

export const gameAssets = (state = [], action) => {
  switch (action.type) {
    case GAME_ASSETS_LOAD:
      return action.gameAssets
    case GAME_ASSET_UPDATE:
      return state.find(gameAsset => gameAsset.id === action.gameAsset.id)
        ? state.map(gameAsset =>
          gameAsset.id === action.gameAsset.id ? action.gameAsset : gameAsset)
        : [...state, action.gameAsset]
    case GAME_ASSET_UPDATE_PRIMARY:
        // TODO: remove case once SSE updates this
        return state.map(gameAsset => {
            const primaryPlayback = gameAsset.gameEventPipeline.gamePk === action.gamePk
                && gameAsset.feedType.code === action.feedTypeCode
                && gameAsset.assetType.code === action.assetTypeCode
            return { ...gameAsset, primaryPlayback }
        })
    default:
      return state
  }
}

export const statusFilter = (state = EVENT_STATUS.ALL, action) => {
  switch (action.type) {
    case SET_STATUS_FILTER:
      return action.statusFilter
    default:
      return state
  }
}

export const selectedProfileId = (state = null, action) => {
  return action.type === SELECTED_PROFILE_ID ? action.selectedProfileId : state
}

export const profiles = (state = [], action) => {
  return action.type === FETCH_ADHOC_PROFILES ? action.profiles : state
}

export const yodaSlates = (state = [], action) => {
  switch (action.type) {
    case FETCH_YODA_SLATES:
      return action.yodaSlates
    default:
      return state
  }
}

export const slates = (state = [], action) => {
  switch (action.type) {
    case FETCH_SLATES:
      return action.slates
    default:
      return state
  }
}

export const slateCategories = (state = [], action) => {
  switch (action.type) {
    case FETCH_SLATE_CATEGORIES:
      return action.slateCategories
    default:
      return state
  }
}

export const availableTags = (state = [], action) => {
  switch (action.type) {
    case TAGS_LOAD:
      return action.tags
    default:
      return state
  }
}

export const alerts = (state = [], action) => {
  const { id, message } = action

  switch (action.type) {
    case ADD_ALERT:
      return state.some(a => a.id === id)
        ? state
        : [...state, { id, type: action.alertType, message }]
    case REMOVE_ALERT:
      return state.filter(a => a.id !== id)
    default:
      return state
  }
}

export const invalidStreamTime = (state = false, action) => {
  const { invalidStreamTime } = action

  switch (action.type) {
    case INVALID_STREAM_TIME_SET:
      return invalidStreamTime
    default:
      return state
  }
}

export const invalidProgramTime = (state = false, action) => {
  const { invalidProgramTime } = action

  switch (action.type) {
    case INVALID_PGM_TIME_SET:
      return invalidProgramTime
    default:
      return state
  }
}

export const eventTypes = (state = [], action) => {
  return action.type === EVENT_TYPES_LOAD ? action.eventTypes : state
}

export const selectedEventTypeId = (state = null, action) => {
  return action.type === EVENT_TYPE_ID_SELECT
    ? action.selectedEventTypeId
    : state
}

export const eventTypeNotSelected = (state = false, action) => {
  const { eventTypeNotSelected } = action

  switch (action.type) {
    case EVENT_TYPE_NOT_SELECTED_SET:
      return eventTypeNotSelected
    default:
      return state
  }
}

export const profileNotSelected = (state = false, action) => {
  const { profileNotSelected } = action

  switch (action.type) {
    case PROFILE_NOT_SELECTED_SET:
      return profileNotSelected
    default:
      return state
  }
}

export const nodeNotSelected = (state = false, action) => {
  const { nodeNotSelected } = action

  switch (action.type) {
    case NODE_NOT_SELECTED_SET:
      return nodeNotSelected
    default:
      return state
  }
}

export const nodes = (state = [], action) => {
  return action.type === FETCHED_NODES ? action.nodes : state
}

export const selectedNodeId = (state = null, action) => {
  return action.type === SET_SELECTED_NODE_ID ? action.selectedNodeId : state
}

export const invalidChannelParams = (state = {}, action) => {
  switch (action.type) {
    case INVALID_CHANNEL_PARAMS_SET:
      return action.invalidChannelParams
    default:
      return state
  }
}

export const invalidMetadata = (state = {}, action) => {
  switch (action.type) {
    case INVALID_METADATA_SET:
      return action.invalidMetadata
    default:
      return state
  }
}

export const venues = (state = [], action) => {
  switch (action.type) {
    case VENUES_LOAD:
      return action.venues
    default:
      return state
  }
}

export const leagues = (state = [], action) => {
  switch (action.type) {
    case LEAGUES_LOAD:
      return action.leagues
    default:
      return state
  }
}

export const eventStatuses = (state = [], action) => {
  return action.type === SET_EVENT_STATUSES ? action.eventStatuses : state
}

export const startTime = (state = null, action) => {
  return action.type === SET_START_TIME ? action.startTime : state
}

export const endTime = (state = null, action) => {
  return action.type === SET_END_TIME ? action.endTime : state
}

export default combineReducers(
  {
    events,
    games,
    provisionEvents,
    gameAssets,
    statusFilter,
    slates,
    slateCategories,
    availableTags,
    alerts,
    eventTypes,
    selectedEventTypeId,
    nodes,
    selectedNodeId,
    profiles,
    selectedProfileId,
    formValidationError: combineReducers({
      invalidStreamTime,
      invalidProgramTime,
      eventTypeNotSelected,
      profileNotSelected,
      nodeNotSelected,
      invalidChannelParams,
      invalidMetadata
    }),
    eventStatuses,
    startTime,
    endTime,
    venues,
    leagues,
    yodaSlates
  }
)


import React, {useCallback, useEffect, useRef, useState} from 'react'
import 'react-dropdown/style.css'
import NavBar from '../components/NavBar'
import styles from '../stylesheets/CreateGameForm.module.css'
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material"
import {DesktopDatePicker, LoadingButton, LocalizationProvider} from '@mui/lab'
import LeagueDropdown from "./LeagueDropdown"
import VenueDropdown from "./VenueDropdown"
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {ALERT_TYPE, GAME_STATUS, PROVISION_TYPE} from '../utils'
import {fetchYodaSlates} from '../actions/game-slates'
import Slate from "./Slate"
import {useDispatch, useSelector} from 'react-redux'
import {FormControl, FormHelperText, TextField} from "@material-ui/core"
import {createGamePipeline} from "../actions/games"
import {useHistory} from "react-router-dom"
import FeedTypeDropDown from "./FeedTypeDropDown"
import NextGenGameDropdown from "./NextGenGameDropdown"
import {CerebroError} from "../errors"
import {addAlert, addErrorAlertWithAutoRemoval, addInfoAlertWithAutoRemoval, removeAlert} from '../actions'
import {fetchGameData} from "../actions/stats"
import settings from '../settings'
import {fetchPipelines} from '../actions/pipelines'
import {formatQueryDate} from './Events'
import Cookies from "universal-cookie"

const cookie = new Cookies()
const PIPELINE_WARNING_MESSAGE = 'There are currently no pipelines available, please wait for one to free up.'
const PIPELINE_WARNING_ID = 'create-game-pipeline-warning'

// gets the Provision Event for this page from redux
const selectProvisionEventByGamePkAndFeedType = (state, gamePk, feedType) => {
    return state.provisionEvents.find(provisionEvent =>
        provisionEvent.gamePk === gamePk && provisionEvent.feedType === feedType?.code &&
        provisionEvent.type === PROVISION_TYPE.PROVISION
    )
}

const hasAvailablePipeline = (pipelines) => {
    return pipelines
        .filter(pipeline => pipeline.computedStatus === GAME_STATUS.STANDBY)
        .length > 0
}

const milbSupportedSportIds = settings.SUPPORTED_SPORT_IDS.filter(l => l.type === 'milb').map(league => league.id)

export default function CreateGameForm() {
    const slates = useSelector(state => state.yodaSlates)
    const [startDate, setStartDate] = useState(new Date())
    const [venue, setVenue] = useState(null)
    const [feedType, setFeedType] = useState(null)
    const [enableCaption, setEnableCaption] = useState(false)
    const [enablePartnerDistribution, setEnablePartnerDistribution] = useState(false)
    const [startSlate, setStartSlate] = useState(null)
    const leagues = useSelector(state => state.leagues)
    const [sportIds, setSportIds] = useState(cookie.get("createGameSportIds")?.filter(l =>
        milbSupportedSportIds.includes(l.id)) || leagues.filter(l => milbSupportedSportIds.includes(l.id)).map(l => l.id))

    const [gamePk, setGamePk] = useState(null)
    const [overrideVenue, setOverrideVenue] = useState(false)
    const [isScheduling, setIsScheduling] = useState(false)
    const [leagueError, setLeagueError] = useState(false)
    const [feedTypeError, setFeedTypeError] = useState(false)
    const [gameError, setGameError] = useState(false)
    const [venueError, setVenueError] = useState(false)
    const [gameDateError, setGameDateError] = useState(false)
    const [feedTypeSportId, setFeedTypeSportId] = useState(null)
    const [pipelineAvailable, setPipelineAvailable] = useState(true)
    const [gameFromStats, setGameFromStats] = useState(null)

    let form = useRef()
    const dispatch = useDispatch()
    let history = useHistory()

    const provisionEvent = useSelector(state => selectProvisionEventByGamePkAndFeedType(state, gamePk, feedType))

    const populateFromClonedGamePipeline = useCallback(gamePipeline => {
        if (!gamePipeline)
            return
        setStartDate(gamePipeline.gameDateTime)
        setSportIds([gamePipeline.feedType.sportId])
        setGamePk(gamePipeline.gamePk)
        setVenue(gamePipeline.venueId)
        setFeedType(gamePipeline.feedType)
        setFeedTypeSportId(gamePipeline.feedType?.sportId)
        setEnableCaption(gamePipeline.captionEnabled)
        setEnablePartnerDistribution(gamePipeline.partnerDistEnabled)
    }, [])

    const updateAvailablePipelines = useCallback(async () => {
        setPipelineAvailable(hasAvailablePipeline(await fetchPipelines()))
    }, [])

    useEffect(() => {
        setEnableCaption(settings.MILB_CC_ENABLED)
        setEnablePartnerDistribution(settings.MILB_DISTRIBUTION_ENABLED)
        updateAvailablePipelines()
    }, [updateAvailablePipelines])

    useEffect(() => {
        const interval = setInterval(updateAvailablePipelines, 5000)

        return () => {
            clearInterval(interval)
            dispatch(removeAlert(PIPELINE_WARNING_ID))
        }
    }, [dispatch, updateAvailablePipelines])

    useEffect(() => {
        pipelineAvailable || isScheduling ?
            dispatch(removeAlert(PIPELINE_WARNING_ID))
            : dispatch(addAlert(PIPELINE_WARNING_ID, ALERT_TYPE.WARN, PIPELINE_WARNING_MESSAGE))
    }, [dispatch, pipelineAvailable, isScheduling])

    useEffect(() => {
        if(!leagues)
            return
        setSportIds(cookie.get("createGameSportIds") ||
            leagues?.filter(l => milbSupportedSportIds.includes(l.id)).map(l => l.id))
    }, [leagues])

    useEffect(() => {
        fetchYodaSlates('milb', gameFromStats ? gameFromStats.teams?.home?.team?.id : 0, ['PRE'])(dispatch)
        populateFromClonedGamePipeline(history.location?.state?.gamePipeline)
    }, [dispatch, populateFromClonedGamePipeline, history.location?.state?.gamePipeline, gameFromStats])

    useEffect(() => {
        const provisionedGamePk = provisionEvent?.gamePk
        if ((provisionedGamePk !== gamePk) || !isScheduling) return

        if (provisionEvent.success) {
            addInfoAlertWithAutoRemoval("The event was created successfully.")(dispatch)
            const queryDate = formatQueryDate(startDate)
            history.push(`/?start=${queryDate}&end=${queryDate}&type=MILB`)
        } else {
            setIsScheduling(false)
            addErrorAlertWithAutoRemoval(provisionEvent.error)(dispatch)
        }

    }, [isScheduling, provisionEvent, dispatch, gamePk, history, startDate])
    useEffect(() => {
        async function fetchGameFromStats() {
            const gameData = await fetchGameData(gamePk, startDate)
            setGameFromStats(gameData)
        }

        if (gamePk) {
            fetchGameFromStats();
        }

    }, [gamePk, startDate])



    const validate = () => {
        setGameError(gamePk == null)
        setGameDateError(startDate == null)
        setVenueError(venue == null)
        setFeedTypeError(feedType == null)
        setLeagueError(sportIds == null || sportIds.length === 0)
        setVenueError(venue == null)
        if (gameError || gameDateError || venueError || feedTypeError || leagueError) {
            console.log("ERROR: Required fields are not populated.")
            setIsScheduling(false)
            throw new CerebroError("Required fields are not populated.")
        }
    }

    const handleSubmit = async (_, clickEvt) => {
        clickEvt.preventDefault()
        setIsScheduling(true)
        try {
            validate()
            const gameDate = gameFromStats.gameDate
            const resumedFrom = gameFromStats.resumedFrom ? gameFromStats.resumedFrom : null
            const gameNumber = gameFromStats.doubleHeader === 'Y' || gameFromStats.doubleHeader === 'S' ? gameFromStats.gameNumber : 1

            let reqGameEventPipeline = {
                gamePk,
                "sportId": feedType.sportId,
                gameDate,
                resumedFrom,
                gameNumber,
                "homeTeamId": gameFromStats?.teams?.home?.team?.id,
                "awayTeamId": gameFromStats?.teams?.away?.team?.id,
                "venueId": venue,
                enableCaption,
                enablePartnerDistribution,
                "activeSlate": startSlate?.uri
            }
            await createGamePipeline(reqGameEventPipeline, feedType.code)

        } catch (err) {
            console.error(`Create event failed with error: ${err}`)
            setIsScheduling(false)
            if (err instanceof CerebroError) {
                addErrorAlertWithAutoRemoval(err.message)(dispatch)
            } else {
                addErrorAlertWithAutoRemoval("Whoops! There was a problem creating the event.")(dispatch)
            }
        }
    }

    const clearGameInputs = () => {
        setGamePk(null)
        setGameError(false)
        setFeedTypeSportId(null)
        setFeedType(null)
        setVenue(null)
        setOverrideVenue(false)
        setVenueError(false)
        setStartSlate(null)
    }

    const handleStartDateChange = newStartDate => {
        setStartDate(newStartDate)
        clearGameInputs()
        setGameDateError(false)
    }
    const handleGameChange = (_, event) => {
        if (event === null) {
            clearGameInputs()
        } else {
            setGamePk(event.value)
            setVenue(event.game?.venue?.id)
            setFeedTypeSportId(event?.sport?.id)
        }
    }

    const handleLeagueChange = (e) => {
        const checked = e.target.value
        let newSportIds
        if (checked.includes('Select All')) {
            newSportIds = milbSupportedSportIds
        } else if (checked.includes('Deselect All')) {
            newSportIds = []
        } else {
            newSportIds = checked
        }
        cookie.set("createGameSportIds", newSportIds)
        setSportIds(newSportIds)
        clearGameInputs()
        setLeagueError(false)
    }

    const handleFeedTypeChange = e => {
        setFeedType(feedTypeSportId !== null ? e.target.value : null)
        setFeedTypeError(false)
    }

    const handleVenueChange = (_, newVenue) => {
        setVenue(newVenue?.value)
        setVenueError(false)
    }


    const handleOverrideVenueChange = (_, actionType) => {
        setOverrideVenue(actionType)
    }
    return (
        <div>
            <NavBar/>
            <form ref={form}>
                <div className={styles.row}>
                    <div className={styles.meta}>
                        <FormGroup className={`${styles.input} ${styles.halfWidth}`}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <FormControl fullWidth error={leagueError}>
                                    <DesktopDatePicker
                                        error={"error"}
                                        helperText={"hello"}
                                        label="Day Of Game"
                                        value={startDate}
                                        onChange={(newDate) => {
                                            handleStartDateChange(newDate)
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} helperText={params?.inputProps?.placeholder}/>
                                        )}
                                    />
                                    {gameDateError && <FormHelperText><span
                                        style={{float: "right", color: "red"}}>Required</span></FormHelperText>}
                                </FormControl>
                            </LocalizationProvider>
                        </FormGroup>
                        <FormGroup className={styles.input}>
                            <LeagueDropdown
                                onChange={handleLeagueChange}
                                supportedSportIds={milbSupportedSportIds}
                                sportIdsSelected={sportIds}
                                error={leagueError}
                            />
                        </FormGroup>
                        <FormGroup className={styles.input}>
                            <NextGenGameDropdown
                                onChange={handleGameChange}
                                gamePk={gamePk}
                                sportIds={sportIds}
                                startDate={startDate}
                                error={gameError}
                            />
                        </FormGroup>
                        <FormGroup className={styles.input}>
                            <FeedTypeDropDown
                                onChange={handleFeedTypeChange}
                                feedType={feedType}
                                sportId={feedTypeSportId}
                                error={feedTypeError}
                            />
                        </FormGroup>
                        <FormGroup className={styles.input}>
                            <VenueDropdown
                                onChange={handleVenueChange}
                                venue={venue}
                                override={overrideVenue}
                                error={venueError}
                            />
                            <div className={styles.inputGroupCheckbox} style={{justifyContent: "flex-end"}}>
                                <FormControlLabel
                                    label="Override Venue"
                                    sx={{mr: 0}}
                                    control={
                                        <Checkbox
                                            checked={overrideVenue}
                                            onChange={handleOverrideVenueChange}
                                        />
                                    }
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className={styles.input}>
                            <div className={styles.inputGroupCheckbox}>
                                <FormControlLabel
                                    label="Captions"
                                    control={
                                        <Checkbox checked={enableCaption}
                                                  onChange={() => setEnableCaption(!enableCaption)}
                                        />
                                    }
                                />

                            </div>
                        </FormGroup>
                        <div className={styles.slateContainer} style={{paddingBottom: '15px'}}>
                            {slates
                                .map(slate => <Slate key={slate.id} slate={slate}
                                                     active={startSlate && startSlate.id === slate.id}
                                                     handleClick={() => setStartSlate(slate)}/>
                                )}
                        </div>
                    </div>
                </div>
                <div className={styles.row}>
                    <LoadingButton loading={isScheduling} style={{width: "auto", whiteSpace: 'nowrap'}}
                                   color={"primary"} variant={"contained"}
                                   size={"large"}
                                   disabled={!pipelineAvailable}
                                   onClick={(e) => handleSubmit(GAME_STATUS.PROVISIONED, e)}
                    >Schedule It</LoadingButton>
                </div>
            </form>
        </div>
    )

}

export class CerebroError extends Error {
  constructor(message) {
    super(message)
    this.name = 'CerebroError'
  }
}

export class MediaLoadingError extends CerebroError {
  constructor(message) {
    super(message)
    this.name = 'MediaLoadingError'
  }
}

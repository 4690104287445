import settings from '../settings'
import {withAuthHeader} from './auth'
import {addErrorAlertWithAutoRemoval} from '../actions'

// Convention: NOUN_VERB actions, verbNoun action creators
export const EVENT_TYPES_LOAD = 'EVENT_TYPES_LOAD'
export const EVENT_TYPE_ID_SELECT = 'EVENT_TYPE_ID_SELECT'

export const loadEventTypes = eventTypes => ({
  type: EVENT_TYPES_LOAD,
  eventTypes
})

export const setSelectedEventTypeId = selectedEventTypeId => ({
  type: EVENT_TYPE_ID_SELECT,
  selectedEventTypeId
})

export const fetchEventTypes = () => async dispatch => {
  try {
    const url = new URL(`${settings.API_ROOT}/api/v1/event-types`)
    const response = await fetch(url, await withAuthHeader())
    const json = await response.json()

    if (response.ok) {
      dispatch(loadEventTypes(json))
    } else {
      console.error(`Could not fetch events from backend: ${json.message}`)
      throw new Error(json.message)
    }
  } catch (err) {
    const errorMsg = "There was a problem fetching event types from the server"
    console.error(`${errorMsg}: ${err}`)
    addErrorAlertWithAutoRemoval(errorMsg)(dispatch)
  }
}

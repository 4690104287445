import React, {useEffect, useState} from 'react'
import {Autocomplete, TextField} from "@mui/material"
import {FormHelperText} from "@material-ui/core"
import {useSelector} from "react-redux"

export const venueToOption = venue => ({
    value: venue?.id,
    name: venue.name,
    label: venue.name + " (" + venue.id + ")",
    sportId: venue.sportId,
    sportName: venue.sportName

})

export default function VenueDropdown({venue, onChange, override, error}) {
    const venues = useSelector(state => state.venues.map(venueToOption), (a, b) => a.length === b.length)
    const [selectedVenue, setSelectedVenue] = useState(null)

    useEffect(() => {
        const selected = venues?.find(o => o.value === venue)
        setSelectedVenue(selected ? selected : null)
    }, [venue,venues])

    return (
        <div>
            <Autocomplete
                disablePortal
                options={venues?.sort((a, b) => {
                    if(a.sportName === b.sportName){
                        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1}
                    else{
                    return a.sportName > b.sportName ? 1 : -1
                    }
                })
                }
                id="venue"
                value={selectedVenue}
                onChange={onChange}
                disabled={!override}
                groupBy={(option) => option.sportName}
                renderInput={(params) => <TextField {...params}
                                                    error={error}
                                                    variant="standard"
                                                    fullWidth
                                                    label="Venue"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: {
                                                            paddingBottom: 12
                                                        }
                                                    }}
                                                    />}
                isOptionEqualToValue={(option, value) => {
                    return option.value === value?.value}}
                noOptionsText={"No Available Venues"}
            />
            {error && <FormHelperText><span style={{float: "right", color: "red"}}>Required</span></FormHelperText>}
        </div>
    )
}

import {validateMetadata} from '../utils'
import {validateChannelParams} from '../components/ChannelParam'
import {addErrorAlertWithAutoRemoval} from '../actions'

export const INVALID_STREAM_TIME_SET = 'INVALID_STREAM_TIME_SET'
export const INVALID_PGM_TIME_SET = 'INVALID_PGM_TIME_SET'
export const INVALID_METADATA_SET = 'INVALID_METADATA_SET'
export const INVALID_CHANNEL_PARAMS_SET = 'INVALID_CHANNEL_PARAMS_SET'
export const EVENT_TYPE_NOT_SELECTED_SET = 'EVENT_TYPE_NOT_SELECTED_SET'
export const NODE_NOT_SELECTED_SET = 'NODE_NOT_SELECTED_SET'
export const PROFILE_NOT_SELECTED_SET = 'PROFILE_NOT_SELECTED_SET'

export const setInvalidStreamTime = invalidStreamTime => ({
  type: INVALID_STREAM_TIME_SET,
  invalidStreamTime
})

export const setInvalidProgramTime = invalidProgramTime => ({
  type: INVALID_PGM_TIME_SET,
  invalidProgramTime
})

export const setInvalidMetadata = invalidMetadata => ({
  type: INVALID_METADATA_SET,
  invalidMetadata
})

export const setInvalidChannelParams = invalidChannelParams => ({
  type: INVALID_CHANNEL_PARAMS_SET,
  invalidChannelParams
})

export const setEventTypeNotSelected = eventTypeNotSelected => ({
  type: EVENT_TYPE_NOT_SELECTED_SET,
  eventTypeNotSelected
})

export const setNodeNotSelected = nodeNotSelected => ({
  type: NODE_NOT_SELECTED_SET,
  nodeNotSelected
})

export const setProfileNotSelected = profileNotSelected => ({
  type: PROFILE_NOT_SELECTED_SET,
  profileNotSelected
})

export const resetValidation = () => dispatch => {
  dispatch(setEventTypeNotSelected(false))
  dispatch(setNodeNotSelected(false))
  dispatch(setProfileNotSelected(false))
  dispatch(setInvalidChannelParams({}))
  dispatch(setInvalidMetadata({}))
  dispatch(setInvalidStreamTime(false))
  dispatch(setInvalidProgramTime(false))
}

export const validate = (event, status) => dispatch => {
  const {
    blurb, title, description, startTime, endTime,
    programStartTime, programEndTime, type,
    nodeId, profile, channelParams
  } = event
  return validateMeta(blurb, title, description)(dispatch)
    && validateStreamTime(startTime, endTime)(dispatch)
    && validateProgramTime(startTime, endTime,
                           programStartTime, programEndTime)(dispatch)
    && validateEventType(type)(dispatch)
    && validateNode(nodeId)(dispatch)
    && validateProfile(profile)(dispatch)
    && validateParams(channelParams, status)(dispatch)
}

export const validateMeta = (blurb, title, description) => dispatch => {
  const {valid, invalidMetadata} = validateMetadata(blurb, title, description)
  dispatch(setInvalidMetadata(invalidMetadata))

  if (!valid)
    addErrorAlertWithAutoRemoval("Please fix the invalid Forge metadata.")(dispatch)
  return valid
}

export const validateStreamTime = (startTime, endTime) => dispatch => {
  if (!startTime || !endTime || (endTime - startTime) < 1) {
    dispatch(setInvalidStreamTime(true))
    addErrorAlertWithAutoRemoval(`Please correct the stream start and end time. \
      Make sure the start time is before the end time.`)(dispatch)
    return false
  }
  dispatch(setInvalidStreamTime(false))
  return true
}

export const validateProgramTime = (streamStart, streamEnd,
                                    pgmStart, pgmEnd) => dispatch => {
  if ((pgmEnd - pgmStart) < 1) {
    dispatch(setInvalidProgramTime(true))
    addErrorAlertWithAutoRemoval(`Please correct the program start and end time. \
      The start time must occur before the end time.`)(dispatch)
    return false
  } else if (!(pgmStart >= streamStart && pgmStart <= streamEnd && pgmEnd <= streamEnd)) {
    dispatch(setInvalidProgramTime(true))
    addErrorAlertWithAutoRemoval(`The program start and end date must be \
      within the stream time range`)(dispatch)
    return false
  }
  dispatch(setInvalidProgramTime(false))
  return true
}

export const validateEventType = type => dispatch => {
  if (!type) {
    dispatch(setEventTypeNotSelected(true))
    addErrorAlertWithAutoRemoval('Please select a type.')(dispatch)
    return false
  }
  return true
}

export const validateNode = (nodeId) => dispatch => {
  if (!nodeId) {
    dispatch(setNodeNotSelected(true))
    addErrorAlertWithAutoRemoval('Please select an AWS Elemental encoder node.')(dispatch)
    return false
  }
  return true
}

export const validateProfile = (profile) => dispatch => {
  if (!profile) {
    dispatch(setProfileNotSelected(true))
    addErrorAlertWithAutoRemoval('Please select a profile and fill out the'
      + ' required channel parameters.')(dispatch)
    return false
  }
  return true
}

export const validateParams = (channelParams, status) => dispatch => {
  const {valid, invalidChannelParams} = validateChannelParams(channelParams, status)

  console.log(`Valid channel params: ${valid} ${JSON.stringify(invalidChannelParams)}`)
  dispatch(setInvalidChannelParams(invalidChannelParams))

  if (!valid)
    addErrorAlertWithAutoRemoval("Please fix the invalid channel parameters.")(dispatch)
  return valid
}

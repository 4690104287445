import styles from "../../stylesheets/AssetDetail.module.css"
import ReactFlow, {Background, ReactFlowProvider} from "react-flow-renderer"
import React, {useCallback} from "react"
import {gameStatusToColor, GAME_STATUS, PIPELINE_PROCESSOR_TYPE} from "../../utils"
import {Link} from "react-router-dom"
import {deriveProcessorUri, processorTypeToLabel} from "../Pipelines/utils"

function generateProcessorElement(id, processor, posX, posY, labelPreFix) {
    let label = processorTypeToLabel(processor?.type, labelPreFix)
    const uris = [deriveProcessorUri(processor?.resourceId, processor?.type)]
    if (processor.type === PIPELINE_PROCESSOR_TYPE.CLOSED_CAPTION)
        uris.push(deriveProcessorUri(processor?.metadata?.lexiInstanceUrl, processor?.type))

    return generateElement(id, label, processor?.status, uris, posX, posY)
}

function generateElement(id, label, status, uris, posX, posY) {
    return {
        id,
        data: {label: <div>{label}</div>, uris: uris},
        style: {
            fontWeight: 'bold',
            border: `5px solid ${gameStatusToColor(status)}`,
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            width: '200px'
        },
        position: {x: posX, y: posY}
    }
}

function generateLink(source, target) {
    return {id: `e${source}-${target}`, source, target, animated: true}
}

export default function PipelineStatus({pipeline, hasCaptions, status, savedEncoder, homeTeamId, awayTeamId}) {

    const isEnded = status === GAME_STATUS.ENDED
    const getProcessor = useCallback((type, teamId) => {

        const processor = pipeline?.processors?.find(p => {
            return p.type === type && (teamId == null ? true : p?.metadata?.teamId === teamId)
        })
        if (!processor)
            return null
        return {
            ...processor,
            status: isEnded ? GAME_STATUS.ENDED : processor?.status
        }
    }, [pipeline?.processors, isEnded])


    const ballparkEncoder = (isEnded || status === GAME_STATUS.ENDING) ? savedEncoder : getProcessor(PIPELINE_PROCESSOR_TYPE.BALLPARK_ENCODER, null)
    const ingressMediaConnect = getProcessor(PIPELINE_PROCESSOR_TYPE.INGRESS_MEDIA_CONNECT, null)
    const egressMediaConnectAway = getProcessor(PIPELINE_PROCESSOR_TYPE.EGRESS_MEDIA_CONNECT, awayTeamId)
    const egressMediaConnectHome = getProcessor(PIPELINE_PROCESSOR_TYPE.EGRESS_MEDIA_CONNECT, homeTeamId)

    const preProcessEncoder = getProcessor(PIPELINE_PROCESSOR_TYPE.PRE_PROCESS_ENCODER, null)
    const postProcessEncoder = getProcessor(PIPELINE_PROCESSOR_TYPE.POST_PROCESS_ENCODER, null)
    const closedCaption = getProcessor(PIPELINE_PROCESSOR_TYPE.CLOSED_CAPTION, null)

    // fitView seems to work better when starting with negative Y values
    let yCurr = -175
    const defaultYOffset = 75
    const elements = []


    function addProcessorElement(id, processor, labelPrefix, posXOffset, posYOffset) {
        yCurr = yCurr + (posYOffset === undefined ? defaultYOffset : posYOffset)
        elements.push(generateProcessorElement(id, processor, posXOffset ? posXOffset : 0, yCurr, labelPrefix))
    }


    if (!isNaN(ballparkEncoder?.id))
        addProcessorElement('ballpark-encoder', ballparkEncoder)

    if (!isEnded) {
        addProcessorElement('ingress-mc', ingressMediaConnect)
        elements.push(generateLink('ballpark-encoder', 'ingress-mc'))

        addProcessorElement('pre-encoder', preProcessEncoder)
        elements.push(generateLink('ingress-mc', 'pre-encoder'))

        if (hasCaptions) {
            addProcessorElement('closed-captions', closedCaption)
            elements.push(generateLink('pre-encoder', 'closed-captions'))
            elements.push(generateLink('closed-captions', 'post-encoder'))
        } else {
            elements.push(generateLink('pre-encoder', 'post-encoder'))
        }

        addProcessorElement('post-encoder', postProcessEncoder)


        if(egressMediaConnectAway || egressMediaConnectHome)
            yCurr = yCurr + defaultYOffset
        egressMediaConnectAway && addProcessorElement('egress-mc-away', egressMediaConnectAway, "Away", -150, 0)
        elements.push(generateLink('post-encoder', 'egress-mc-away'))

        egressMediaConnectHome && addProcessorElement('egress-mc-home', egressMediaConnectHome, "Home", 150, 0)
        elements.push(generateLink('post-encoder', 'egress-mc-home'))


    }

    return <>
        <div className={styles.labelContainer} style={{textAlign: "center"}}>
            <label>Status for: </label><Link to={`/pipelines/${pipeline?.id}`}>{pipeline?.name}</Link>
        </div>
        <div style={{display: 'contents'}}>
            <ReactFlowProvider>
                {<ReactFlow style={{height:"85%"}} elements={elements}
                                              elementsSelectable={false}
                                              nodesConnectable={false}
                                              nodesDraggable={false}
                                              zoomOnScroll={false}
                                              zoomOnPinch={false}
                                              zoomOnDoubleClick={false}
                                              panOnScroll={false}
                                              paneMoveable={false}
                                              snapToGrid={true}
                                              defaultPosition={[0, 0]}
                                              onLoad={(instance) => instance
                                                  .fitView({maxZoom:.75},2)}
                                              onElementClick={(_, element) => {
                                                  element?.data?.uris.forEach(uri => window.open(uri, "_blank"))
                                              }}
                >
                    <Background color="transparent" gap={16}/>
                </ReactFlow>}
            </ReactFlowProvider>
        </div>
    </>
}

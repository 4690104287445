import {objectToArrayOfKeyValuePair} from '../utils'
import {CerebroError} from '../errors'
import settings from '../settings'
import {withAuthHeader} from './auth'

const buildChannelFromEvent = event => ({
  name: event.title,
  node_id: event.nodeId,
  profile_id: event.profile.conductorId,
  status: 'idle',
  channel_params: objectToArrayOfKeyValuePair(event.channelParams),
  permalink_name: createPermalinkName(event.title)
})

const createPermalinkName = title => title.toLowerCase()
  .replace(/ /g, '_')
  .replace(/-/g, '_')

export const updateChannel = async event => {
  const url = `${settings.API_ROOT}/api/v1/channels/${event.channelId}`
  const channel = buildChannelFromEvent(event)

  try {
    const response = await fetch(url, await withAuthHeader({
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(channel)
    }))

    if (!response.ok)
      throw new CerebroError(`There was a problem updating the Conductor Channel`)
  } catch (err) {
    console.error(`Failed to update Conductor Channel ${err}`)
    if (err instanceof CerebroError)
      throw err
    throw new CerebroError(`There was a problem updating the Conductor Channel`)
  }
}

import styles from "../../stylesheets/AssetDetail.module.css"
import Select from "react-select"
import Slate from "../Slate"
import React, {useEffect, useState} from "react"
import {fetchYodaSlates, yodaSlateCategories} from "../../actions/game-slates"
import {useDispatch, useSelector} from "react-redux";
import {FETCH_YODA_SLATES} from "../../actions/slates";

// gets the GameEventPipeline for this page from redux
export const selectGameByGamePk = (state, gamePk) =>
    state.games.find(game => game.gamePk === gamePk)


export default function YodaSlates({gamePk, updateSlate, liftSlate, activeSlate, isLive, isOver}) {
    const [selectedCategory, setSelectedCategory] = useState(null)
    const game = useSelector(state => selectGameByGamePk(state, gamePk))
    const dispatch = useDispatch()
    const yodaSlates = useSelector(state => state.yodaSlates)

    useEffect(() => {
        fetchYodaSlates('milb', game ? game.teams?.home?.team?.id : 0, yodaSlateCategories
            .map(slate => slate.value))(dispatch)
        return () => {
            dispatch({
                type: FETCH_YODA_SLATES,
                yodaSlates: []
            })
        }
    }, [game, dispatch])

    const getSlateCategoryOptions = () => {
        if (isLive) {
            return yodaSlateCategories.filter(c => c.value !== 'PRE')
        } else if (!isOver) {
            return yodaSlateCategories.filter(c => c.value === 'PRE')
        }
        return yodaSlateCategories
    }

    useEffect(() => {
        // Update the default dropdown when categories or event state changes
        if (!isLive && !isOver)
            setSelectedCategory(yodaSlateCategories.find(c => c.value === 'PRE'))
        else
            setSelectedCategory(yodaSlateCategories.find(c => c.value === 'MID'))
    }, [isLive, isOver])


    return <>
        <Select
            options={getSlateCategoryOptions()}
            value={selectedCategory}
            getOptionValue={o => o.value}
            getOptionLabel={o => o.label}
            placeholder="Select a Slate Category" isClearable={false}
            onChange={option => setSelectedCategory(option || null)}
        />
        <div className={styles.slateContainer} style={{paddingBottom: '15px'}}>
            {
                yodaSlates?.filter(slate => slate?.category === selectedCategory?.value)
                    .map(slate =>
                        <Slate
                            key={slate.id}
                            slate={slate}
                            active={activeSlate === slate.uri}
                            disabled={isOver}
                            popup={isLive}
                            handleClick={(newSlate) => {
                                activeSlate === slate.uri ?
                                    liftSlate(newSlate.uri) :
                                    updateSlate(newSlate.uri)
                            }}/>)
            }
        </div>
    </>
}
import settings from "../settings"
import {withAuthHeader} from "./auth"
import {FETCH_YODA_SLATES} from "./slates"

export const yodaSlateCategories = [{label: 'Starting', value: "PRE"},
    {label: 'Intermediate', value: "MID"},
    {label: 'Ending', value: "POST"}]

export const fetchYodaSlates = (league, teamId, categories) => async dispatch => {
    console.log("Fetching slates from yoda")
    try {
        const categoryList = categories.map(c => `categories=${c}`).join('&')
        const response = await fetch(settings.YODA_ROOT + `/api/v1/slates/${league}/${teamId}?${categoryList}`, await withAuthHeader())
        const res = await response.json()
        const yodaSlates = res.map(slate =>
            ({
                "thumbnail": slate.thumbnailUrl,
                "id": slate.name,
                "category": slate.category,
                "uri": slate.uri
            }))
        dispatch({
            type: FETCH_YODA_SLATES,
            yodaSlates: yodaSlates
        })
    } catch (err) {
        console.log(`Could not fetch available slates from yoda ${err}`)
    }
}
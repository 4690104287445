import dateFormat from 'dateformat'
import moment from 'moment'
import React, {useEffect, useMemo, useState} from 'react'
import {DateRangePicker} from 'react-dates'
import {useSelector} from 'react-redux'
import { useHistory } from 'react-router-dom'
import EventStatusFilter from '../containers/EventStatusFilter'
import GamePipelineStatusFilter from "../containers/GamePipelineStatusFilter"
import { useQuery } from '../libs/hooks'
import styles from '../stylesheets/Events.module.css'
import { MODE } from '../utils'
import EventCards from "./EventCards"
import GameCards from './GameCards/GameCards'
import NavBar from './NavBar'
import Dropdown from "react-dropdown"
import { getCookie, updateCookie } from '../actions/cookies'

const DEFAULT_PARAMS = {
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
    mode: getCookie("mode") || MODE.ADHOC
}

// convert mode value => key for more readable URL query param
function getModeKey(value) {
    return Object.keys(MODE).filter(key => MODE[key] === value)
}

function daysEqual(a, b) {
    return a?.isSame(b, 'day')
}

// `?` check helps avoids browser back duplicates
function hasSearchChanged(search) {
    return !(search === window.location.search || (search === '?' && window.location.search === ''))
}

export default function Events() {
    const query = useQuery()
    const history = useHistory()

    const paramStart = useMemo(() => {
        let date = moment(query?.get("start"))
        if (!date.isValid()) {
            // fallback to end (if it exists)
            date = moment(query?.get("end"))
        }

        return date.isValid() ?  date.startOf("day") : DEFAULT_PARAMS.startDate
    }, [query])

    const paramEnd = useMemo(() => {
        const date = moment(query?.get("end"))
        return date.isValid() && date.isSameOrAfter(paramStart) ?  date.endOf("day") : paramStart.clone().endOf("day")
    }, [query, paramStart])

    const paramMode = useMemo(() => {
        return MODE[query.get("type")] || DEFAULT_PARAMS.mode
    }, [query])
    
    const [startDate, setStartDate] = useState(paramStart)
    const [endDate, setEndDate] = useState(paramEnd)
    const [focusedInput, setFocusedInput] = useState(null)
    const [modeFilter, setModeFilter] = useState(paramMode)
    const statusFilter = useSelector(state => state.statusFilter)

    useEffect(() => {
        setStartDate(paramStart)
    }, [paramStart])

    useEffect(() => {
        setEndDate(paramEnd)
    }, [paramEnd])

    useEffect(() => {
        // only add query params if value !== default value
        const newStart = daysEqual(startDate, DEFAULT_PARAMS.startDate) ? "" : `start=${formatQueryDate(startDate)}`
        const newEnd = daysEqual(endDate, DEFAULT_PARAMS.endDate) ? "" : `end=${formatQueryDate(endDate)}`
        const newType = modeFilter === DEFAULT_PARAMS.mode ? "" : `type=${getModeKey(modeFilter)}`
        const search = "?" + new URLSearchParams(`&${newStart}&${newEnd}&${newType}`)

        if (hasSearchChanged(search)) {
            history.push({
                pathname: window.location.pathname,
                search
            })
        }
    }, [history, startDate, endDate, modeFilter])

    const handleRangeChange = ({startDate: start, endDate: end}) => {
        if (end === null) end = start
        setStartDate(start?.clone().startOf("day"))
        setEndDate(end?.clone().endOf('day'))
    }

    const handleModeChange = (e) => {
        updateCookie("mode", e.value)
        setModeFilter(e.value)
    }

    const filter = (
        <div key="filter" className={styles.statusFilterContainer}>
            <div className={styles.statusFilterLabel}>Filter</div>
            {modeFilter === 'MiLB' ?
                <GamePipelineStatusFilter className={styles.statusFilter} menuClassName={styles.statusFilterMenu} /> :
                <EventStatusFilter className={styles.statusFilter} menuClassName={styles.statusFilterMenu} />}
        </div>
    )

    const mode = (
        <div key="mode" className={styles.statusFilterContainer}>
            <div className={styles.statusFilterLabel}>Mode</div>
            <Dropdown
                className={styles.statusFilter} 
                options={[MODE.ADHOC, MODE.MLB, MODE.MILB]} 
                value={modeFilter} 
                onChange={handleModeChange}
                placeholder="Select an option"
            />
        </div>
    )

    return (
        <div id="event-summary">
            <NavBar
                left={[filter, mode]}
                center={
                    <DateRangePicker
                        startDate={startDate}
                        startDateId="event-summary-start"
                        startDatePlaceholderText="Start"
                        endDate={endDate}
                        endDateId="event-summary-end"
                        endDatePlaceholderText="End"
                        readOnly
                        noBorder
                        block
                        small
                        hideKeyboardShortcutsPanel
                        displayFormat="MMM DD"
                        numberOfMonths={1}
                        minimumNights={0}
                        isOutsideRange={day => false}
                        onDatesChange={handleRangeChange}
                        focusedInput={focusedInput}
                        onFocusChange={setFocusedInput}
                    />
                }
            />
            {modeFilter !== "MiLB" ? (
                <EventCards startDate={startDate} endDate={endDate} statusFilter={statusFilter}
                            modeFilter={modeFilter}/>
            ) : (
                <GameCards startDate={startDate} endDate={endDate} statusFilter={statusFilter}/>
            )}
        </div>
    )
}

export const formatStartTime = date => dateFormat(date, "mmm dd hh:MM TT (Z)")
export const formatStartDate = date => dateFormat(date, "mmm dd")
export const formatQueryDate = date => dateFormat(date, "yyyy-mm-dd")

export const formatDuration = durationInMinutes => {
    const hours = Math.floor(durationInMinutes / 60)
    const minutes = durationInMinutes % 60

    const hText = hours
        ? hours > 1 ? `${hours} hours` : `${hours} hour`
        : ''
    const mText = minutes
        ? minutes > 1 ? `${minutes} minutes` : `${minutes} minute`
        : ''
    return <span>{hText || mText ? `${hText} ${mText}` : 'N/A'}</span>
}

export const getDurationInMinutes = (d1, d2) =>
    formatDuration(Math.ceil((Date.parse(d2) - Date.parse(d1)) / 1000 / 60))

import React from 'react'
import CerebroPopup from './CerebroPopup'
import styles from '../stylesheets/Slate.module.css'

const Slate = (props) => {
  const { slate, active, handleClick, disabled, popup } = props
  const { name, thumbnail } = slate
  const title = active
    ? "Deactivate Slate"
    : "Activate Slate"
  const message = active
    ? "Are you sure you want to remove the following slate from this event?"
    : "Are you sure you want to apply the following slate to this event?"
  const slateStyle = active
    ? `${styles.slateImg} ${styles.activeSlate}`
    : styles.slateImg
  let slateImg

  if (disabled) {
    slateImg = (
      <div className={styles.disabledContainer}>
        <div className={styles.overlay}/>
        <div className={slateStyle}>
          <img src={thumbnail} alt={name} width="100%" height="100%" />
        </div>
      </div>
    )
  } else {
    slateImg = (
      <div className={slateStyle}>
        <img src={thumbnail} alt={name} width="100%" height="100%" />
      </div>
    )
  }

  const trigger = (
    <div
      className={styles.slateContainer}
      onClick={() => {
        if (!popup)
          handleClick(slate, active)
      }} >
      {slateImg}
      <div className={styles.slateCaption}>{name}</div>
    </div>
  )

  return (
    disabled || !popup
      ? trigger
      : <CerebroPopup trigger={trigger} title={title} body={message}
          img={thumbnail} handleContinue={() => handleClick(slate, active)} />
  )
}

export default Slate

import { Container } from "@mui/material";
import { useRouteMatch } from "react-router-dom";
import { CerebroRoute } from "../commons/routing/CerebroRoute";
import NavBar from "../NavBar";
import AllPipelines from "./all/AllPipelines";
import PipelineDetail from "./detail/PipelineDetail";

export default function Pipelines() {
    const { path } = useRouteMatch()

    return (
        <div id="pipelines">
            <NavBar />
            <Container sx={{ marginTop: "2.0rem" }}>
               <CerebroRoute exact path={path} component={AllPipelines}/>
                <CerebroRoute path={`${path}/:id`} component={PipelineDetail}/>
            </Container>
        </div>
    )
}
import styles from "../../stylesheets/Events.module.css"
import {formatStartTime, getDurationInMinutes} from "../Events"
import {GameStatus} from "../GameStatus"
import {useSelector} from "react-redux";
import {isEpochBeginning} from "../../utils/date-utils"

export default function GameEventPipeline({gameEventPipeline}) {
    const {id, gamePk, feedType, status, startedTime, endedTime, pipeline} = gameEventPipeline
    const venue = useSelector(state => state.venues.find(v => v.id === gameEventPipeline.venueId))
    const containerClass = `${styles.cardContent} ${styles.selectable}`
    
    return (
        <a className={containerClass}
            href={`/asset-detail/${gamePk}?feedType=${feedType.code}&gamePipelineId=${id}`}
        >
            <div className={styles.cardItemContainer}>
                <div className={styles.cardItem}>
                    <h1>Feed Type</h1>
                    <p>{feedType.name}</p>
                </div>
            </div>
            <div className={styles.cardItemContainer}>
                <div className={styles.cardItem}>
                    <h1>Pipeline</h1>
                    <p>{pipeline.name}</p>
                </div>
            </div>
            <div className={styles.cardItemContainer}>
                <div className={styles.cardItem}>
                    <h1>Status</h1>
                    <b><GameStatus className={styles.statusText} status={status}/></b>
                </div>
            </div>
            <div className={styles.cardItemContainer}>
                <div className={styles.cardItem}>
                    <h1>Venue</h1>
                    <p>{venue?.name}</p>
                    <div className={styles.cardItemSubscript}>MILB</div>
                </div>
            </div>
            <div className={styles.cardItemContainer} style={{flexGrow: 2}}>
                <div className={styles.eventTimeContainer}>
                    <div className={styles.cardItem}>
                        <h1>Stream Start Time</h1>
                        <p>{isEpochBeginning(startedTime) ? 'N/A' : formatStartTime(startedTime)}</p>
                    </div>
                </div>
            </div>
            <div className={styles.cardItemContainer}>
                <div className={styles.cardItem}>
                    <h1>Stream Duration</h1>
                    <p>{getDurationInMinutes(startedTime, endedTime)}</p>
                </div>
            </div>
        </a>
    )
}
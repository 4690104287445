import React, {useState} from 'react'
import Collapse from '@material-ui/core/Collapse'
import styles from '../stylesheets/Collapsible.module.css'

const Collapsible = ({label, defaultOpen, ...props}) => {
  const [isOpen, setIsOpen] = useState(!!defaultOpen)

  return (
    <div>
      <div className={styles.label}
        onClick={() => setIsOpen(curIsOpen => !curIsOpen)}>
        {label} <span>{isOpen ? '-' : '+'}</span>
      </div>
      <Collapse in={isOpen} timeout='auto'>
        {props.children}
      </Collapse>
    </div>
  )
}

export default Collapsible

import {
    uploadImage
} from '../actions'
import settings from '../settings'
import {withAuthHeader} from './auth'

export const uploadThumbnail = image => async dispatch => await uploadImage(image)(dispatch)

export const FETCHED_NODES = 'FETCHED_NODES'
export const fetchedNodes = nodes => (
    {
        type: FETCHED_NODES,
        nodes
    }
)
export const fetchNodes = () => async dispatch => {
  console.log(`Fetching nodes...`)
  const response = await fetch(settings.API_ROOT + `/api/v1/nodes`, await withAuthHeader())
  const nodes = await response.json()
  const sorted = nodes.sort((a, b) => {
    let n1 = a.hostname.match(/\d+$/)
    let n2 = b.hostname.match(/\d+$/)
    return n1 - n2
  })
  return dispatch(fetchedNodes(sorted))
}

export const SET_SELECTED_NODE_ID = 'SET_SELECTED_NODE_ID'

export const setSelectedNodeId = selectedNodeId => {
    return {type: SET_SELECTED_NODE_ID, selectedNodeId}
}

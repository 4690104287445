import CerebroPopup from "../CerebroPopup"
import React from "react"

export const ButtonWithPopup = (props) => {
    const {btnText, handleClick, className, popupTitle, popupMsg, previewImg, disabled} = props
    const trigger = (<button type='button' disabled={disabled} className={className}>{btnText}</button>)

    return (
        <CerebroPopup
            trigger={trigger}
            title={popupTitle ? popupTitle : btnText}
            body={popupMsg}
            img={previewImg}
            handleContinue={handleClick}
        />
    )
}
import React from 'react'
import PropTypes from 'prop-types'
import logo from '../mlb-logo.svg'
import AccountIcon from '../user.svg'
import { Link, NavLink } from 'react-router-dom'
import styles from '../stylesheets/NavBar.module.css'
import Alerts from '../containers/Alerts'
import { useOktaAuth } from '@okta/okta-react'
import settings from '../settings'

export default function NavBar(props) {
  const { left, center, right } = props

  const { authService, authState } = useOktaAuth()

  const login = async () => {
    await authService.login('/')
  }

  const logout = async () => {
    authService.clearAuthState()
    window.location.href = settings.OKTA_ROOT
  }

  return (
    <div className={styles.navContainer}>
      <div className={styles.navContent}>
        <Link to="/"
              className={styles.logo}>
          <img src={logo} alt="mlb logo"/>
        </Link>
        <div className={styles.createEventContainer}>
          <NavLink to="/create"
            className={styles.createEventLink}
            activeClassName={styles.createEventLinkSelected}>
            Schedule Adhoc
          </NavLink>
        </div>
        <div className={styles.createEventContainer}>
          <NavLink to="/create-game"
                   className={styles.createEventLink}
                   activeClassName={styles.createEventLinkSelected}>
            Schedule Game
          </NavLink>
        </div>
        <div className={styles.createEventContainer}>
          <NavLink to="/pipelines"
            className={styles.createEventLink}
            activeClassName={styles.createEventLinkSelected}>
            View All Pipelines
          </NavLink>
        </div>
        <div className={styles.elemContainer}>
          <div className={styles.left}>{left}</div>
          <div className={styles.center}>{center}</div>
          <div className={styles.right}>{right}</div>
        </div>
        <div className={styles.accountContainer}>
            <div className={styles.account}
              onClick={authState.isAuthenticated ? logout : login}>
              <img src={AccountIcon} alt="user account icon"/>
              {authState.isAuthenticated ? 'Logout' : 'Login'}
            </div>
        </div>
      </div>
      <Alerts />
    </div>
  )
}

NavBar.propTypes = {
  left: PropTypes.array,
  center: PropTypes.element,
  right: PropTypes.element
}

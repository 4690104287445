import React from 'react'
import styles from '../../stylesheets/inputs/LabeledInput.module.css'

const LabeledInput = ({
    name, label, description, placeholder, pattern, minLength, maxLength,
    altText, defaultValue, required, disabled, textarea, labelClass,
    descriptionClass, altTextClass, inputClass, textareaClass, requiredClass, onChange
  }) => {
  let input

  if (textarea) {
    input = (
      <textarea
        id={name}
        name={name}
        className={textareaClass}
        type="text"
        placeholder={placeholder}
        minLength={minLength}
        maxLength={maxLength}
        defaultValue={defaultValue}
        disabled={disabled}
        pattern={pattern}
        rows="3"
        cols="60"
      />
    )
  } else {
    input = (
      <input
        id={name}
        name={name}
        className={inputClass}
        type="text"
        placeholder={placeholder}
        minLength={minLength}
        maxLength={maxLength}
        defaultValue={defaultValue}
        disabled={disabled}
        pattern={pattern}
        onChange={onChange}
      />
    )
  }

  return (
    <div className={styles.container}>
      <label htmlFor={name} className={labelClass}>
        {label}{required && <span className={requiredClass}>*</span>}{altText && <span className={altTextClass}>{altText}</span>}
      </label>
      {input}
      {description && <p className={descriptionClass}>{description}</p>}
    </div>
  )
}

export default LabeledInput

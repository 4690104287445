import {connect} from 'react-redux'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import {setStatusFilter} from '../actions'
import {EVENT_STATUS} from '../utils'

const options = [
  EVENT_STATUS.ALL,
  EVENT_STATUS.PENDING,
  EVENT_STATUS.SCHEDULED,
  EVENT_STATUS.LIVE,
  EVENT_STATUS.ENDED,
  //EVENT_STATUS.ERROR,
  EVENT_STATUS.ARCHIVED
]

const mapStateToProps = state => ({
  options,
  value: state.statusFilter,
  placeholder: "Select an option"
})

const mapDispatchToProps = dispatch => ({
  onChange: e => dispatch(setStatusFilter(e.value))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown)

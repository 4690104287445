import settings from '../settings'
import {withAuthHeader} from '../actions/auth'

export const TAGS_LOAD = 'TAGS_LOAD'

export const loadTags = tags => ({
  type: TAGS_LOAD,
  tags
})

export const fetchTags = () => async dispatch => {
  try {
    const uri = `${settings.API_ROOT}/api/v1/tags`
    const response = await fetch(uri, await withAuthHeader())
    const tags = await response.json()

    dispatch(loadTags(tags))
    return tags
  } catch (err) {
    console.log(`Could not fetch available tags ${err}`)
  }
}

import React from 'react'
import EntryInput from './inputs/EntryInput'
import EditableInput from './inputs/EditableInput'
import uuid from 'uuid/v4'
import {
  EVENT_STATUS, stripSpecialChars,
  getTwoDigitMonth, getTwoDigitDate
} from '../utils'

export const CHANNEL_PARAM_NAMES = {
  outputDiamondNfs: 'DIAMONDLive - Local',
  cloudOutputDiamond: 'DIAMONDLive - Cloud',
  cloudOutputHls: 'hlsCloud',
  slateUrl: 'Slate URL',
  callBackUrl: 'Cerebro Callback URL',
  audioSlatePath: 'Audio Feed Slate Path',
  audioPID: 'Audio PID',
  postProcessingScript: 'Post Processing Script',
  ssm: 'SSM',
  ssmAtt: 'SSM-ATT',
  ssmLumen: 'SSM-Lumen'
}

export const CHANNEL_PARAM_DEFAULTS = new Map([[CHANNEL_PARAM_NAMES.audioSlatePath,
  '/data/mnt/nas01-client/resources/thumbs/alpha/generic/blankAlpha.png']])

const OPTIONAL_CHANNEL_PARAMS = [
  CHANNEL_PARAM_NAMES.ssm,
  CHANNEL_PARAM_NAMES.postProcessingScript,
  CHANNEL_PARAM_NAMES.audioPID,
  CHANNEL_PARAM_NAMES.callBackUrl,
  CHANNEL_PARAM_NAMES.audioSlatePath,
  CHANNEL_PARAM_NAMES.ssmAtt,
  CHANNEL_PARAM_NAMES.ssmLumen
]
//Hidden param names and their default values
export const HIDDEN_CHANNEL_PARAMS = new Map([[CHANNEL_PARAM_NAMES.callBackUrl, ""]])

export const COLLAPSIBLE_CHANNEL_PARAMS = new Set([
  CHANNEL_PARAM_NAMES.audioSlatePath,
  CHANNEL_PARAM_NAMES.audioPID
])

const STREAM_KEY_SUBSTRING = 'Stream Key'
const isStreamKey = paramName => paramName.indexOf(STREAM_KEY_SUBSTRING) > -1
const isChannelParamRequired = (paramName, status) => {
  //Stream keys are not required when the status is PENDING
  if (status === EVENT_STATUS.PENDING && isStreamKey(paramName))
    return false
  else
    return OPTIONAL_CHANNEL_PARAMS.indexOf(paramName) === -1
}

// TODO as of now, the validation has become generic for the buckets
// at some point, if needed, we can make a more robust validation engine
// with references stored in our backend. At some point, validation can
// vary based on event type, profile, and channel param. So this might need
// to be considered in the future.
const PARAM_NAME_TO_PATTERN = new Map()
PARAM_NAME_TO_PATTERN.set(CHANNEL_PARAM_NAMES.outputDiamondNfs, '^/mnt/nas01-client/ELEM-(LIVE|DEV)/\\d{4}/\\d{4}-\\d{2}/\\d{2}/.+')
PARAM_NAME_TO_PATTERN.set(CHANNEL_PARAM_NAMES.cloudOutputDiamond, '^s3://(?<bucket>[^/]+)/(?<key>.+)$')
PARAM_NAME_TO_PATTERN.set(CHANNEL_PARAM_NAMES.cloudOutputHls, '^s3://(?<bucket>[^/]+)/(?<key>.+)$')
const PARAM_NAME_TO_REGEX = new Map()
PARAM_NAME_TO_REGEX.set(CHANNEL_PARAM_NAMES.outputDiamondNfs, new RegExp(PARAM_NAME_TO_PATTERN.get(CHANNEL_PARAM_NAMES.outputDiamondNfs)))
PARAM_NAME_TO_REGEX.set(CHANNEL_PARAM_NAMES.cloudOutputDiamond, new RegExp(PARAM_NAME_TO_PATTERN.get(CHANNEL_PARAM_NAMES.cloudOutputDiamond)))
PARAM_NAME_TO_REGEX.set(CHANNEL_PARAM_NAMES.cloudOutputHls, new RegExp(PARAM_NAME_TO_PATTERN.get(CHANNEL_PARAM_NAMES.cloudOutputHls)))

const isValidChannelParam = (name, value, status) => {
  if (isChannelParamRequired(name, status) && !value) {
    return {valid: false, reason: "This value cannot be blank"}
  } else if (PARAM_NAME_TO_REGEX.has(name) && !value.match(PARAM_NAME_TO_REGEX.get(name))) {
    return {valid: false, reason: "This value does not match the expected pattern"}
  }

  return {valid: true}
}

export const validateChannelParams = (params, status) => {
  let response = {valid: true, invalidChannelParams: {}}

  if (!params || Object.keys(params).length === 0) {
    response.valid = false
  }

  for (const [key, value] of Object.entries(params)) {
    const {valid, reason} = isValidChannelParam(key, value, status)

    if (!valid) {
      response.invalidChannelParams[key] = reason
      response.valid = false
    }
  }

  return response
}

// Gets a unique key for parameter fields that are auto populated.
// Ultimately, auto populated fields will be controlled components while
// other fields will behave as uncontrolled components.
export const getKey = (paramName, event = {}) => {
  const { type: { name, channelParamTemplates } = {}, title, startTime } = event
  return name && paramName in channelParamTemplates
    ? paramName + name + startTime + title
    : paramName
}

// TODO these need to be made into controlled components.
export const ChannelParamEntry = ({name, value, defaultValue, disabled, invalidReason}) => {
  const id = "param-" + name

  return (
    <EntryInput id={id} label={name} description={invalidReason} name={name} defaultValue={defaultValue}
                placeholder={value} disabled={disabled} invalid={!!invalidReason}
                required={isChannelParamRequired(name)}/>
  )
}

export const ChannelParamEditable = ({name, value, disabled, invalidReason}) => {
  const id = "param-" + name

  return (
    <EditableInput id={id} label={name} description={invalidReason} name={name}
                   defaultValue={value} required={isChannelParamRequired(name)}
                   disabled={disabled} invalid={!!invalidReason}/>
  )
}

export const skipAutoDisplay = paramName =>
  paramName === CHANNEL_PARAM_NAMES.slateUrl || HIDDEN_CHANNEL_PARAMS.get(paramName) !== undefined


let templateReplacements = {
  '{UUID}': () => uuid(),
  '{TITLE}': e => stripSpecialChars(e?.title),
  '{S_YYYY}': e => e?.startTime?.getFullYear(),
  '{S_MM}': e => getTwoDigitMonth(e?.startTime),
  '{S_DD}': e => getTwoDigitDate(e?.startTime)
}

export const generateFromTemplate = (event, template) => {
  if (!event?.title || !template)
    return null

  for (const param in templateReplacements) {
    template = template.replaceAll(param, templateReplacements[param](event))
  }
  return template;
}

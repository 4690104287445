import { AuthService } from '@okta/okta-react'
import settings from '../settings'

export const authService = new AuthService({
  issuer: `${settings.OKTA_ROOT}/oauth2/${settings.OKTA_AUTHORIZATION_SERVER}`,
  client_id: settings.OKTA_CLIENT_ID,
  redirect_uri: `${window.location.origin}/implicit/callback`
})

export const fetchToken = async () => {
  const authState = authService.getAuthState()

  if(!authState || authState.isPending) {
    console.log("User authorization is not initialized")
    return null
  }

  if(!authState.isAuthenticated) {
    console.log("User is not authenticated")
    return null
  }

  return authState.accessToken
}

export const withAuthHeader = async request => {
  const accessToken = await fetchToken()

  if (accessToken == null)
    return request;

  console.log("Including Bearer token as Authentication header")
  const authHeader = {
    Authorization: `Bearer ${accessToken}`
  }

  const updatedHeaders = Object.assign(request && request.headers ? request.headers : {}, authHeader)
  return Object.assign(request || {}, {headers: updatedHeaders})
}

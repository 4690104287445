import React from 'react'
import Select from '@mui/material/Select'
import styles from '../stylesheets/GameDropdown.module.css'
import {Checkbox, FormControl, InputLabel, MenuItem} from "@mui/material"
import {FormHelperText, ListItemIcon, ListItemText} from "@material-ui/core"
import {useSelector} from "react-redux"

const MILB_URL = `https://images.ctfassets.net/iiozhi00a8lc/7eeTdW5zGYe0sW2ZlIle7E/d846a455b02beef2f139dddd2e9eb6f3/milb-august.svg`
const MLB_URL = `/static/media/mlb-logo.19063c66.svg`
const LIDOM_URL = `https://www.mlbstatic.com/team-logos/league-on-light/131.svg`
const LeagueIconImg = ({league, ...props}) => {
   let url
   switch(league) {
       case 'MLB':
           url = MLB_URL;
           break;
       case 'LIDOM':
           url = LIDOM_URL;
           break;
       default:
           url = MILB_URL
   }

    return <img width={'20%'} src={url} alt="" {...props} />
}
export const leagueToOption = league => ({
    value: league?.id,
    label: `${league?.name} (${league?.abbreviation})`,
    league: league,
    abbreviation: league?.abbreviation
})

export default function LeagueDropdown({sportIdsSelected, supportedSportIds, onChange, disabled, error}) {
    sportIdsSelected = sportIdsSelected || []
    const leagues = useSelector(state =>
        state.leagues?.filter(league => supportedSportIds
            .includes(league.id))
            .map(leagueToOption))

    const renderLeague = (selected) => {
        if (leagues.length === selected.length)
            return "All"
        return leagues.filter(league =>
            selected.includes(league.value))
            .map(l => l.abbreviation).join(', ')
    }

    return (
        <div className={styles.root}>
            <FormControl fullWidth error={error}>
                <InputLabel variant="standard" style={{minHeight: "90px"}} id="league-dropdown" disabled={disabled}>League</InputLabel>
                <Select
                    id="league"
                    labelId="league-label"
                    value={sportIdsSelected}
                    label="League"
                    onChange={onChange}
                    variant="standard"
                    renderValue={renderLeague}
                    disabled={disabled}
                    multiple
                >
                    {leagues.length > 0 && <MenuItem
                        key={leagues?.length > 0 && leagues?.length === sportIdsSelected?.length ? "Deselect All" : "Select All"}
                        value={leagues?.length > 0 && leagues?.length === sportIdsSelected?.length ? "Deselect All" : "Select All"}
                    >
                        <ListItemIcon>
                            <Checkbox
                            checked={leagues?.length > 0 && leagues?.length === sportIdsSelected?.length}
                            indeterminate={sportIdsSelected?.length > 0 && sportIdsSelected?.length < leagues?.length}
                            />
                        </ListItemIcon>
                        <ListItemText primary={leagues?.length === sportIdsSelected?.length ? "Deselect All" : "Select All"}
                                      />
                    </MenuItem>}
                    {leagues.length > 0 && leagues.map(option => <MenuItem key={option.value}
                                                                           value={option.value}
                    >
                        <Checkbox checked={sportIdsSelected.includes(option.value)}/>
                        <ListItemText primary={
                            <div style={{alignItems: "center", display: "flex"}}>
                                <LeagueIconImg league={option.abbreviation}
                                               style={{
                                                   height: "30px",
                                                   marginRight: "10px"
                                               }}/>{option.label}</div>}/>
                    </MenuItem>)}
                    {(leagues == null || leagues.length === 0) && <MenuItem
                        value=''
                    >No Available Leagues</MenuItem>}
                </Select>
                {error && <FormHelperText><span style={{float: "right", color: "red"}}>Required</span></FormHelperText>}
            </FormControl>
        </div>
    )
}

import { CerebroError } from "../errors"
import settings from "../settings"
import { withAuthHeader } from "./auth"
import {fetchTeam} from "./stats"

/**
 * Attempts an authorized fetch of the given Yoda url,
 * throwing a `CerebroError` on error or non-200 response.
 * @param {string} url - The url to fetch.
 * @param {string} errorMsg - The error message to display, which will have the url and caught error appended.
 */
async function fetchOrThrowError(url, errorMsg) {
	try {
		const response = await fetch(url, await withAuthHeader())

		if (!response.ok) {
			const errorRes = await response.json()
			console.error(errorRes.message)
			throw new CerebroError(errorRes.message)
		}

		return await response.json()
	} catch (err) {
		const msg = `${errorMsg} with url ${url}: ${err}`
		console.error(msg)
		throw new CerebroError(msg)
	}
}

export const fetchPipelines = async () => {
	const url = `${settings.YODA_ROOT}/api/v1/pipelines`
	return fetchOrThrowError(url, `There was a problem fetching pipelines`)
}

export const fetchPipeline = async id => {
	const url = `${settings.YODA_ROOT}/api/v1/pipelines/${id}`
	return fetchOrThrowError(url, `There was a problem fetching pipeline ${id}`)
}

export const fetchProcessorLogs = async id => {
	const url = `${settings.YODA_ROOT}/api/v1/processors/${id}/logs`
	return fetchOrThrowError(url, `There was a problem fetching logs for processor ${id}`)
}

export const fetchPipelineWithRecentLogs = async id => {
	const pipeline = await fetchPipeline(id)
	await Promise.all(
		pipeline.processors.map(async processor => {
			processor.logs = await fetchProcessorLogs(processor.id)
			processor.team = processor.metadata.teamId !== undefined ? await fetchTeam(processor?.metadata?.teamId) : null
            processor.logs.sort((a, b) => b.id - a.id)
		}),
	)

	return pipeline
}

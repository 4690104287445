import styles from "../../stylesheets/AssetDetail.module.css"
import {GameStatus} from "../GameStatus"
import React from "react"
import BeatLoader from "react-spinners/BeatLoader"
import {ButtonWithPopup} from "./ButtonWithPopup"
import {GAME_STATUS} from "../../utils"


const StopButton = ({handleStopStream, isEventActionUpdating}) => (
    <ButtonWithPopup
        className={styles.stopStreamButton}
        handleClick={handleStopStream}
        disabled={isEventActionUpdating}
        btnText={
            isEventActionUpdating
                ? <BeatLoader size={8} color={"#FFFFFF"} loading={true}/>
                : 'Stop Stream'
        }
        popupMsg="You are about to stop the stream for this event. Would you like to proceed?"
    />
)
const StartButton = ({handleStartStream, isEventActionUpdating}) => (
    <ButtonWithPopup
        className={styles.startStreamButton}
        handleClick={handleStartStream}
        disabled={isEventActionUpdating}
        btnText={
            isEventActionUpdating
                ? <BeatLoader size={8} color={"#FFFFFF"} loading={true}/>
                : 'Start Stream'
        }
        popupMsg="You are about to start the stream for this event. Would you like to proceed?"/>
)

export const StatusContent = ({
                                  status,
                                  isOver,
                                  inProgress,
                                  isEventActionUpdating,
                                  handleStartStream,
                                  handleStopStream
                              }) => {
    if (!status)
        return <></>

    let streamActionBtn
    if (!isOver && inProgress) {
        streamActionBtn =
            <StopButton isEventActionUpdating={isEventActionUpdating} handleStopStream={handleStopStream}/>
    } else if (!isOver && !(status === GAME_STATUS.STANDBY)) {
        streamActionBtn =
            <StartButton isEventActionUpdating={isEventActionUpdating} handleStartStream={handleStartStream}/>
    } else {
        streamActionBtn = null
    }

    return (
        <div className={`${styles.statusContainer} ${styles.labelContainer}`}>
            <div className={styles.status}>
                Current status: <GameStatus className={styles.statusText} status={status}/>
            </div>

            <div className={styles.statusActionsContainer}>
                {streamActionBtn}
            </div>
        </div>
    )
}
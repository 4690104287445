import React, {useCallback, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useOktaAuth} from '@okta/okta-react'
import {removeEvent, updateEvent} from '../actions/events'
import {EVENT_STATUS} from '../utils'
import settings from '../settings'
import {
    fetchPlaybackUrl,
    loadLeagues,
    loadVenues,
    updateGamePipeline,
    updateGamePipelineFromProvisionedEvent,
    updateProvisionEvent
} from '../actions/games'
import {fetchLeagues, fetchVenuesBySportId} from "../actions/stats"

export default function DataProvider() {
    const {authState} = useOktaAuth()
    const dispatch = useDispatch()

    const handleGameEventPipelineEvent = useCallback(async (rawEvent) => {
        const pipeline = JSON.parse(rawEvent)
        console.log('Received pipeline data: %o', pipeline)

        // add/update redux gameEventPipeline
        await dispatch(updateGamePipeline(pipeline))
    }, [dispatch])

    const handleGameAssetEvent = useCallback(async (rawEvent) => {
        // update playback urls whenever the event changes˝
        const gameAsset = JSON.parse(rawEvent)
        console.log('Received game asset data: %o', gameAsset)

        // add/update redux gameAssets
        await fetchPlaybackUrl(gameAsset)(dispatch)

    }, [dispatch])

    const handleRealTimeProvisionStatus = useCallback(async (rawEvent) => {
      // update playback urls whenever the event changes˝
      const provisionEventReceived = JSON.parse(rawEvent)
      console.log('Received provisioned game pipeline: %o', provisionEventReceived)

      // add/update redux gameAssets
      dispatch(updateProvisionEvent(
          {
              "success": provisionEventReceived.success,
              "gamePk": provisionEventReceived.request.gamePk,
              "feedType": provisionEventReceived.request.feedTypeCode,
              "error": provisionEventReceived.error,
              "type": provisionEventReceived?.request?.type
          }
      ))
      await dispatch(updateGamePipelineFromProvisionedEvent(provisionEventReceived))
    }, [dispatch])

    useEffect(() => {
        // Skip this effect if the auth state is still pending
        if (authState.isPending)
            return

        const handleEventUpdate = rawEvent => {
            const event = JSON.parse(rawEvent)

            if (event.status.name === EVENT_STATUS.DELETED) {
                dispatch(removeEvent(event))
            } else {
                dispatch(updateEvent(event))
            }
        }
        const url = `${settings.API_ROOT}/api/v1/sse/data?access_token=${authState.accessToken}`
        const dataSource = new EventSource(url)
        dataSource.addEventListener('event-update', e => handleEventUpdate(e.data))

        return function cleanup() {
            dataSource.close()
        }
    }, [authState, dispatch])

    // Yoda SSE
    // TODO ?: merge this w/ Cerebro SSE once Yoda auth is setup
    useEffect(() => {
        const url = `${settings.YODA_ROOT}/api/v1/sse/data`
        console.log(`SSE listening to ${url}`)
        //TODO: will need to change creds to the client credential flow
        const sse = new EventSource(url)

        const sseGameEventPipelineListener = e => handleGameEventPipelineEvent(e.data)
        const sseGameAssetListener = e => handleGameAssetEvent(e.data)
        const sseProvisionStatusListener = e => handleRealTimeProvisionStatus(e.data)

        sse.addEventListener('GAME_EVENT_PIPELINE', sseGameEventPipelineListener, true)
        sse.addEventListener('GAME_ASSET', sseGameAssetListener, true)
        sse.addEventListener('PROVISION', sseProvisionStatusListener, true)

        sse.onmessage = (msg) => {
            console.log("Received Message: %o", msg)
        }
        sse.onerror = (e) => {
            console.error("Got an error on sse: ", e)
        }
        return () => {
            console.log("Closing sse connection.")
            sse.removeEventListener('GAME_EVENT_PIPELINE', sseGameEventPipelineListener, true)
            sse.removeEventListener('GAME_ASSET', sseGameAssetListener, true)
            sse.removeEventListener('PROVISION', sseProvisionStatusListener, true)
            sse.close()
        }
    }, [handleGameAssetEvent, handleGameEventPipelineEvent, handleRealTimeProvisionStatus])


    useEffect(() => {
        const fetchVenues = async () => {
            let fetchedVenues = await fetchVenuesBySportId(settings.SUPPORTED_SPORT_IDS.map(leagues => leagues.id))
            fetchedVenues = uniqByKeepFirst(fetchedVenues, venue => venue.id)
            dispatch(loadVenues(fetchedVenues))
        }
        fetchVenues()
    }, [dispatch])

    useEffect(() => {
        const fetchLeaguesAsync = async () => {
            const fetchedLeagues = await fetchLeagues(settings.SUPPORTED_SPORT_IDS.map(leagues => leagues.id))
            dispatch(loadLeagues(fetchedLeagues))
        }
        fetchLeaguesAsync()
    }, [dispatch])
    return <div/>
}
function uniqByKeepFirst(a, key) {
    let seen = new Set()
    return a.filter(item => {
        let k = key(item)
        return seen.has(k) ? false : seen.add(k)
    })
}
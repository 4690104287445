const config = {
  default: {
    API_ROOT: 'http://localhost:8081',
    YODA_ROOT: 'http://localhost:8080',
    OKTA_ROOT: 'https://mlb.oktapreview.com',
    OKTA_CLIENT_ID: '0oanwoh2g1VU1rn0l0h7',
    OKTA_AUTHORIZATION_SERVER: 'ausnxeyfwcSOtUUA70h7',
    OKTA_ENABLED: true,
    TRACTOR_BEAM_API_ROOT: 'http://localhost:8082',
    STATS_API_ROOT: 'https://qa-statsapi.mlb.com',
    SUPPORTED_SPORT_IDS: [
      {id: 11, type: "milb"},
      {id: 12, type: "milb"},
      {id: 13, type: "milb"},
      {id: 14, type: "milb"},
      {id: 1, type: "adhoc"},
      {id: 17, type: "adhoc"}],
    MILB_CC_ENABLED: false,
    MILB_DISTRIBUTION_ENABLED: false,
    OKTA_CDN_HOSTS: [
      'diamond-hls-live.mlb.com',
      'tv-pre.milb.com',
      'diamond-hls-npd.milb.com',
      'dai.svod-fst-npd.mlbinfra.com'
    ],
    MEDIA_LIVE_ROOT:'https://us-east-1.console.aws.amazon.com/medialive/home?region=us-east-1#!/channels/',
    MEDIA_CONNECT_ROOT:'https://us-east-1.console.aws.amazon.com/mediaconnect/home?region=us-east-1#/flows/',
    DEFAULT_MILB_AD_BREAK_DURATION_IN_SECONDS: 90,
    DEFAULT_ADHOC_AD_BREAK_DURATION_IN_SECONDS: 90
  },

  dev: {
    API_ROOT: 'http://cerebro-api-dev.mlbinfra.com',
    OKTA_ENABLED: true
  },

  qa: {
    API_ROOT: 'https://cerebro-api-qa.mlbinfra.com',
    YODA_ROOT: 'https://yoda-qa.mlbinfra.com',
    OKTA_ENABLED: true,
    TRACTOR_BEAM_API_ROOT: 'https://vaporator-qa.mlbinfra.com',
  },

  "central-qa": {
    API_ROOT: 'https://cerebro.us-central1.entmedia-gcp-npd.mlbinfra.net',
    YODA_ROOT: 'https://yoda-qa.mlbinfra.com',
    OKTA_ENABLED: true,
    TRACTOR_BEAM_API_ROOT: 'https://vaporator-qa.mlbinfra.com',
  },

  prod: {
    API_ROOT: 'https://cerebro-api.mlb.com',
    YODA_ROOT: 'https://yoda.mlbinfra.com',
    OKTA_ROOT: 'https://mlb.okta.com',
    OKTA_CLIENT_ID: '0oanb45u1uvvzR6vu0x7',
    OKTA_AUTHORIZATION_SERVER: 'ausnb46z8nO9pZLU50x7',
    OKTA_ENABLED: true,
    STATS_API_ROOT: 'https://statsapi.mlb.com',
    MILB_CC_ENABLED: true,
    TRACTOR_BEAM_API_ROOT: 'https://vaporator.mlbinfra.com',
  }
}

const settings = Object.assign(config.default, config[window.env.name])

export default settings

import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import ReactPlayer from 'react-player'
import ClipLoader from 'react-spinners/ClipLoader'
import {addWarnAlertWithAutoRemoval} from '../actions'
import {fetchRetry} from '../utils'
import {MediaLoadingError} from '../errors'
import settings from "../settings"

const styles = {
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  player: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

const HlsPlayer = ({url, playing, retryLimit, accessToken, reference, handleOnStart, forceLive}) => {
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useDispatch()


    let forceLiveOptions = forceLive ? {
        liveSyncDuration: 3,
        liveMaxLatencyDuration: 5,
        backBufferLength: 60,
    } : {}


    useEffect(() => {
    const abortController = new AbortController()
    const pollMedia = async () => {
      try {
        let options = {signal: abortController.signal}
        const useOktaToken = settings.OKTA_CDN_HOSTS.some(host => url?.indexOf(host) > -1)
        if (useOktaToken) {
          options['headers'] = {
            'Authorization': `Bearer ${accessToken}`
          }
        }

        const response = await fetchRetry(url, options, retryLimit)

        if (!response.ok) {
          throw new MediaLoadingError(`Retries exhausted for media playlist`)
        }

        setIsLoading(false)
      } catch (err) {
        if (err.name !== 'AbortError') {
          console.error(`Could not load media for HLS player ${err}`)
          addWarnAlertWithAutoRemoval(`There was a problem loading the HLS player \
            please try refreshing the page.`)(dispatch)
        }
      }
    }

    pollMedia()

    return function cleanup() {
      abortController.abort()
    }
  }, [dispatch, url, retryLimit, accessToken])

  return (
    <div style={styles.container}>
      {isLoading
        ? <ClipLoader size={60} color='#FFFFFF'/>
        : <ReactPlayer
            style={styles.player}
            url={url}
            playing={playing}
            controls={true}
            muted
            width='100%'
            height='480px'
            ref={reference}
            onStart={handleOnStart}
            config={{
              file: {
                hlsOptions: {
                  ...forceLiveOptions,
                  forceHLS: true,
                  debug: false,
                  xhrSetup: (xhr, videoUrl) => {
                    const useOktaToken = settings.OKTA_CDN_HOSTS.some(host => videoUrl.indexOf(host) > -1)
                    if (useOktaToken) {
                      xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`)
                    }
                  },
                },
              },
            }}
          />
      }
    </div>
  )
}

export default HlsPlayer

import settings from '../settings'
import {withAuthHeader} from "./auth"
import {CerebroError} from "../errors"

export const fetchFeedTypes = async sportId => {
    console.log(`Fetching Feed Types for sport id ${sportId}`)
    const url = settings.YODA_ROOT + `/api/v1/feed-types/${sportId}`
    try {
        const response = await fetch(url
            , await withAuthHeader())

        return await response.json()

    } catch (err) {
        const errorMsg = `There was a problem fetch feed types with sport id ${sportId} url ${url}: ${err}`
        console.error(errorMsg)
        throw new CerebroError(errorMsg)
    }
}

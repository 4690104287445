import {connect} from 'react-redux'
import {removeAlert} from "../actions"
import Alerts from "../components/Alerts"

const mapStateToProps = state => ({
  alerts: state.alerts
})

const mapDispatchToProps = dispatch => ({
  closeAlert: id => dispatch(removeAlert(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Alerts)

import styles from "../stylesheets/AssetDetail.module.css"
import { MenuItem, Select } from "@material-ui/core"
import { updateCookie } from "../actions/cookies"
import React, {useState } from "react"
import Timer from "react-timer-wrapper"
import Timecode from "react-timecode"

export const SCTE_35_CUE_OUT = "SCTE_35_CUE_OUT"
export const SCTE_35_CUE_IN = "SCTE_35_CUE_IN"

export default function AdBreakButtons({
                                           adBreakActive,
                                           startAdClicked,
                                           adBreakDurationInSeconds,
                                           setAdBreakDurationInSeconds,
                                           handleAdBreak,
                                           stopAdClicked,
                                           timeLeftAdInMillis,
                                           timeLapsedInSeconds,
                                           setHideTimer,
                                           hideTimer,
                                       }) {

    const [timeUpdated, setTimeUpdated] = useState()
    const [durationUpdated, setDurationUpdated] = useState()

    function onTimerUpdate({ time, duration }) {
        setTimeUpdated(time)
        setDurationUpdated(duration)
        if ((duration) && time > duration) {
            setHideTimer(true)
        }
        else {
            setHideTimer(false)
        }
    }
    return <div className={styles.adBreakSection}>
        <div className={styles.adBreakButtons}>
            {<Select
                style={{
                    minWidth: "60%",
                    margin: "auto",
                }}
                id="ad-break-duration"
                labelId="ad-break-duration-label"
                value={adBreakDurationInSeconds}
                label="Ad Break Duration (seconds)"
                onChange={e => {
                    const duration = e.target.value
                    updateCookie("adBreakDuration", duration)
                    setAdBreakDurationInSeconds(duration)
                }}
                variant="standard"
            >
                <MenuItem key={"untimed"} value={-1}>Untimed</MenuItem>
                {Array.from({ length: 21 }, (item, index) =>
                    (index + 1) * 10).map(seconds =>
                    <MenuItem key={seconds} value={seconds}>{seconds} Seconds</MenuItem>)}
            </Select>
            }
            {!hideTimer && (timeLeftAdInMillis === 0 && !stopAdClicked && (startAdClicked || adBreakActive)) &&
                <div className={styles.adMessage}>
                    <span>Ad Break is in progress, since:</span>
                    <Timer active duration={null} time={timeLapsedInSeconds * 1000} onTimeUpdate={onTimerUpdate}>
                        <Timecode time={timeUpdated} />
                    </Timer></div>}
            {!hideTimer && (timeLeftAdInMillis > 0 && !stopAdClicked && (startAdClicked || adBreakActive)) &&
                <div className={styles.adMessage}>Ad Break is in progress, time remaining:
                    <Timer active duration={timeLeftAdInMillis} onTimeUpdate={onTimerUpdate} />
                    <Timecode time={durationUpdated - timeUpdated} /></div>}
            {
                <button className={styles.adButton}
                        onClick={e => handleAdBreak(e, adBreakDurationInSeconds === -1 ?
                            null : adBreakDurationInSeconds, SCTE_35_CUE_OUT)}>Start Ad Break</button>}
            {
                <button className={styles.adButton}
                        onClick={e => handleAdBreak(e, null, SCTE_35_CUE_IN)}>End Ad Break</button>}
        </div>
    </div>
}

import React from 'react';
import {render} from 'react-dom';
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './stylesheets/ReactDatesOverrides.css'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker'
import {Provider} from 'react-redux'
import storeFactory from './reducers'

const saveState = () =>
  localStorage["redux-store"] = JSON.stringify(store.getState())

const store = storeFactory()

store.subscribe(saveState)

render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
)

//TODO: remove this. This is for debug only
window.React = React
window.store = store

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

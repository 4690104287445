import { gameStatusToColor } from "../utils"

export const GameStatus = ({status, className}) => {
  let style = { color: gameStatusToColor(status) }

  return (
      <span className={className} style={style}>
          {status}
      </span>
  )
}
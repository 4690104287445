import { NavigateNext } from "@material-ui/icons"
import { Breadcrumbs, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import { addErrorAlertWithAutoRemoval } from "../../../actions"
import { fetchPipelineWithRecentLogs } from "../../../actions/pipelines"
import { CerebroError } from "../../../errors"
import ProcessorsTable from "./ProcessorsTable"

// show all processors for a given pipelines
// fns like GameCards where theres a link to AWS/whatev & then expands to show a table of the pLogs for this one.
export default function PipelineDetail() {
	const id = parseInt(useParams().id)
	const [pipeline, setPipeline] = useState()
	const dispatch = useDispatch()
	let history = useHistory()

	const updatePipeline = useCallback(async () => {
		if (!id) return

		try {
			const resp = await fetchPipelineWithRecentLogs(id)
			setPipeline(resp)
		} catch (err) {
			const msg =
				err instanceof CerebroError ? err.message : `Something went wrong, could not fetch pipeline ${id}.`

			addErrorAlertWithAutoRemoval(msg)(dispatch)
			history.push("/pipelines")
		}
	}, [id, dispatch, history])

	useEffect(() => {
		updatePipeline()

		const interval = setInterval(updatePipeline, 5000)

		return () => {
		    clearInterval(interval)
		}
	}, [updatePipeline])

	return (
		<>
			<Box display="flex" justifyContent="center" alignItems="center">
				<Breadcrumbs fontSize="large" separator={<NavigateNext fontSize="small" />}>
					<Link underline="hover" color="inherit" to={`/pipelines`}>
						Pipelines
					</Link>
					<Typography fontSize="large" color="text.primary">
						{pipeline?.name}
					</Typography>
				</Breadcrumbs>
			</Box>
			<br />

			{pipeline && <ProcessorsTable processors={pipeline?.processors} />}
		</>
	)
}

import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { Collapse, IconButton } from '@mui/material'
import dateFormat from 'dateformat'
import { useMemo, useState } from 'react'
import { useSelector } from "react-redux"
import styles from "../../stylesheets/Events.module.css"
import { parseDateYYYYMMDD } from "../../utils/date-utils"
import { formatStartDate, formatStartTime } from "../Events"
import { GameStatus } from "../GameStatus"
import GameEventPipeline from "./GameEventPipeline"

const getTeamLogo = teamId => teamId ?
    `https://www.mlbstatic.com/team-logos/${teamId}.svg`
    : `https://images.ctfassets.net/iiozhi00a8lc/7eeTdW5zGYe0sW2ZlIle7E/d846a455b02beef2f139dddd2e9eb6f3/milb-august.svg`

const formatShortDate = date => dateFormat(date, "mmm dd")

const containerClass = `${styles.card} ${styles.expandable}`
const contentClass = `${styles.cardContent} ${styles.expandable}`

export default function GameCard({game}) {
    const [showPipelines, setShowPipelines] = useState(false)
    const updatedGame = useSelector(state => state.games.find(g => g.gamePk === game.gamePk && g.gameDate === game.gameDate))
    const {gamePk, gameStatus, teams, resumedFromDate, pipelines} = updatedGame

    const formattedResumedDate = useMemo(() => {
        if (resumedFromDate)
            return formatShortDate(parseDateYYYYMMDD(resumedFromDate))
    }, [resumedFromDate])

    return (
        <div className={containerClass} id="game-row">
            <div className={contentClass} style={{minHeight: '125px'}} onClick={e => setShowPipelines(!showPipelines)}>
                <div className={styles.cardItemContainer} style={{flexGrow: 0}}>
                    <div className={styles.cardItem}>
                        <IconButton aria-label="expand row" size="small">
                            {showPipelines ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    </div>
                </div>
                <div className={styles.cardItemContainer} style={{flexGrow: 0}}>
                    <div className={styles.cardItem}>
                        <p>{gamePk}</p>
                    </div>
                </div>
                <div className={styles.gameContainer}>
                    <span>{teams?.away?.team?.name}</span>
                    <img src={getTeamLogo(teams?.away?.team?.id)}
                         alt="event thumbnail"
                         className={styles.teamThumbnail}/>
                    <span style={{"fontSize": "20pt", "width": "4%"}}><b>@</b></span>
                    <img src={getTeamLogo(teams?.home?.team?.id)}
                         alt="event thumbnail"
                         className={styles.teamThumbnail}/>
                    <span>{teams?.home?.team?.name}</span>
                </div>
                <div className={styles.cardItemContainer}>
                    <div className={styles.cardItem}>
                        <h1>Status</h1>
                        <b><GameStatus className={styles.statusText} status={gameStatus}/></b>
                    </div>
                </div>
                <div className={styles.cardItemContainer} style={{flexGrow: 1, justifyContent: "left"}}>
                    <div className={styles.eventTimeContainer}>
                        <div className={styles.cardItem}>
                            <h1>Game Time</h1>
                            <p>{updatedGame?.status?.startTimeTBD ? formatStartDate(updatedGame.gameDate) + ' TBD' : formatStartTime(updatedGame.gameDate)}</p>
                            {formattedResumedDate && <div className={styles.cardItemSubscript}>
                                {`* Resumed from ${formattedResumedDate}`}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

            <Collapse in={showPipelines} timeout="auto">
                {pipelines.map(pipeline => <GameEventPipeline key={pipeline?.id} gameEventPipeline={pipeline}/>)}
            </Collapse>
        </div>
    )
}
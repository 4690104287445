import React from 'react'
import Select, {components} from 'react-select'
import styles from '../stylesheets/TagsDropdown.module.css'

const MIN_TAGS_DEFAULT = 0
const MAX_TAGS_DEFAULT = 100

const tagSelectStyles = {
  container: (provided, state) => ({
    ...provided,
    fontSize: '18px',
    fontWeight: 'normal',
  })
}

const tagSelectErrorStyles = {
  container: (provided, state) => ({
    ...provided,
    fontSize: '18px',
    fontWeight: 'normal',
    borderColor: 'rgba(0,0,0,.0)',
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: 'rgba(0,0,0,.0)',
    boxShadow: '0px 0px 5px rgba(255,65,54,.80) inset',
  })
}

export default function TagsDropdown({
    minTagsRequired = MIN_TAGS_DEFAULT, maxTagsAllowed = MAX_TAGS_DEFAULT,
    labelClassName, showError, ...props
  }) {

  const TagMenu = props => {
    const selectedOptionsLength = props?.getValue()?.length || 0
    return (
      <components.Menu {...props}>
        {selectedOptionsLength < maxTagsAllowed
          ? props.children
          : <div style={{margin: 15, textAlign: 'center'}}>Maximum tag limit reached</div>
        }
      </components.Menu>
    )
  }

  let tagsAltText
  if (minTagsRequired === maxTagsAllowed && minTagsRequired > 0) {
    tagsAltText = `Please select ${minTagsRequired} tag${minTagsRequired > 1 ? "s" : ''}`
  } else if (minTagsRequired) {
    tagsAltText = `Please select at least ${minTagsRequired} tag${minTagsRequired > 1 ? "s" : ''}`
  }

  return (
    <label className={styles.labelContainer}>
      <div className={`${styles.label} ${labelClassName || ''}`}>
        Tags<span className={styles.altText}>{tagsAltText}</span>
      </div>
      <Select components={{ Menu: TagMenu }}
        styles={showError ? tagSelectErrorStyles : tagSelectStyles}
        {...props}
      />
    </label>
  )
}
